import { DollarSign } from 'lucide-react';
import { CalculatorInfo } from '../types/CalculatorInfo';

export const tipCalculator: CalculatorInfo = {
    title: "Tip Calculator - Gratuity & Bill Splitting Calculator",
    description: "Free online tip calculator for restaurants, delivery, salons, and rideshares. Features include bill splitting, tax calculation, service-specific suggestions, and customizable tip percentages. Perfect for dining out, personal services, and group payments.",
    category: "Finance & Dining",
    icon: DollarSign,
    
    keywords: [
        "tip calculator",
        "gratuity calculator",
        "bill splitter",
        "restaurant tip calculator",
        "delivery tip calculator",
        "salon tip calculator",
        "rideshare tip calculator",
        "group bill calculator",
        "bill splitting calculator",
        "service gratuity calculator",
        "percentage calculator",
        "dining calculator"
    ],

    schema: {
        "@type": "SoftwareApplication",
        "name": "Tip Calculator",
        "applicationCategory": "FinanceApplication",
        "operatingSystem": "Any",
        "offers": {
            "@type": "Offer",
            "price": "0",
            "priceCurrency": "USD"
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.9",
            "ratingCount": "2150"
        },
        "featureList": [
            "Multiple Service Type Presets",
            "Bill Splitting Functionality",
            "Tax Calculation",
            "Custom Tip Percentages",
            "Per-Person Breakdown",
            "Quick Tip Suggestions",
            "Service Quality Ratings",
            "Export Functionality"
        ]
    },

    features: [
        "Calculate tips for various service types (restaurant, delivery, salon, rideshare)",
        "Split bills among multiple people",
        "Include or exclude tax in calculations",
        "Customize tip percentages with quick presets",
        "View per-person breakdown of costs",
        "Get service-specific tip suggestions",
        "Round up totals for convenience",
        "Export detailed calculation summaries",
        "Support for different service quality levels",
        "Real-time calculation updates"
    ],

    mathFormulas: [
        {
            name: "Basic Tip Calculation",
            formula: "Tip Amount = Bill Amount × (Tip Percentage / 100)",
            explanation: "Calculates the tip amount based on the bill total and chosen percentage"
        },
        {
            name: "Total with Tax",
            formula: "Subtotal = Bill Amount × (1 + Tax Rate / 100)",
            explanation: "Calculates the subtotal including tax before tip"
        },
        {
            name: "Per Person Split",
            formula: "Amount Per Person = (Subtotal + Tip) ÷ Number of People",
            explanation: "Divides the total bill including tip among the group"
        },
        {
            name: "Tip Quality Adjustment",
            formula: "Adjusted Tip = Base Tip × Service Quality Factor",
            explanation: "Modifies tip based on service quality (e.g., 1.0 for standard, 1.2 for excellent)"
        }
    ],

    faqs: [
        {
            question: "What are standard tip percentages for different services?",
            answer: "Standard tip percentages vary by service type: Restaurants: 15-20%, Delivery: 10-15%, Salon/Spa: 15-25%, Rideshare/Taxi: 10-20%. These ranges can be adjusted based on service quality and local customs."
        },
        {
            question: "Should I calculate tip before or after tax?",
            answer: "This is a personal preference. Our calculator allows you to choose whether to calculate the tip based on the pre-tax or post-tax amount. In most cases, it's common to calculate the tip based on the pre-tax amount."
        },
        {
            question: "How does the bill splitting feature work?",
            answer: "Enter the total bill amount, select the tip percentage, and specify the number of people. The calculator will divide both the bill and tip evenly, showing each person's share of the subtotal, tip, and total amount."
        },
        {
            question: "What is the round up feature?",
            answer: "The round up feature adjusts the total amount to the next whole dollar amount for convenience. The additional amount is added to the tip, making it easier to pay and slightly more generous."
        },
        {
            question: "How are the service-specific suggestions determined?",
            answer: "Service suggestions are based on industry standards and common practices. Each service type (restaurant, delivery, salon, rideshare) has preset ranges based on typical service expectations and local customs."
        }
    ],

    references: [
        {
            title: "Tipping Etiquette Guide",
            author: "Emily Post Institute",
            link: "https://emilypost.com/advice/general-tipping-guide",
            description: "Comprehensive guide to tipping customs and etiquette"
        },
        {
            title: "Service Industry Standards",
            author: "Bureau of Labor Statistics",
            link: "https://www.bls.gov/ooh/food-preparation-and-serving/",
            description: "Official statistics on service industry wages and tipping"
        },
        {
            title: "International Tipping Guide",
            author: "World Tourism Organization",
            link: "https://www.unwto.org",
            description: "Global perspectives on tipping practices"
        },
        {
            title: "Digital Payment Tipping Study",
            author: "National Restaurant Association",
            link: "https://restaurant.org/research",
            description: "Research on modern tipping trends and digital payments"
        }
    ],

    itemListElement: [
        {
            "@type": "ListItem",
            "position": 1,
            "name": "Financial Calculators",
            "item": {
                "@id": "/calculators/finance"
            }
        },
        {
            "@type": "ListItem",
            "position": 2,
            "name": "Tip Calculator",
            "item": {
                "@id": "/calculators/tip"
            }
        }
    ]
};
