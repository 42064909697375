import { Calculator } from 'lucide-react';
import { CalculatorInfo } from './types';

export const matrixCalculator: CalculatorInfo = {
  id: 'matrix-calculator',
  title: 'Matrix Calculator | Online Matrix Operations & Linear Algebra Tool',
  description: 'Free online matrix calculator for addition, subtraction, multiplication, determinant, inverse, and more. Supports step-by-step solutions and handles matrices of any size. Perfect for linear algebra calculations.',
  icon: Calculator,
  category: 'Linear Algebra',
  schema: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Matrix Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Linear Algebra",
    "operatingSystem": "Any",
    "description": "Comprehensive matrix calculator supporting all basic operations, determinants, inverses, and eigenvalues. Features step-by-step solutions and handles matrices of any size.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "3150",
      "reviewCount": "2680",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Matrix Addition and Subtraction",
      "Matrix Multiplication",
      "Determinant Calculation",
      "Matrix Inverse",
      "Eigenvalue Computation",
      "Row Operations",
      "Step-by-step Solutions",
      "Any Size Matrix Support",
      "Mobile Friendly Interface",
      "Free to Use",
      "Export Results"
    ]
  },
  keywords: [
    'matrix calculator',
    'matrix operations',
    'determinant calculator',
    'matrix inverse',
    'eigenvalue calculator',
    'linear algebra calculator',
    'matrix multiplication',
    'row operations calculator',
    'free matrix calculator',
    'online matrix calculator',
    'matrix math solver',
    'matrix rank calculator',
    'matrix transpose calculator',
    'system of equations solver',
    'linear transformation calculator'
  ],
  formulas: [
    {
      expression: '|A| = ad - bc',
      explanation: 'Determinant of a 2x2 matrix [[a,b],[c,d]]',
      variables: [
        { name: 'a,b,c,d', description: 'Elements of the 2x2 matrix' }
      ]
    },
    {
      expression: 'C[i,j] = Σ(A[i,k] × B[k,j])',
      explanation: 'Matrix multiplication (C = A × B)',
      variables: [
        { name: 'A[i,k]', description: 'Element of matrix A' },
        { name: 'B[k,j]', description: 'Element of matrix B' },
        { name: 'C[i,j]', description: 'Resulting element' }
      ]
    },
    {
      expression: 'A⁻¹ = adj(A)/|A|',
      explanation: 'Inverse of a matrix using adjugate method',
      variables: [
        { name: 'A', description: 'Input matrix' },
        { name: 'adj(A)', description: 'Adjugate of matrix A' },
        { name: '|A|', description: 'Determinant of matrix A' }
      ]
    }
  ],
  features: [
    'Matrix addition and subtraction',
    'Matrix multiplication',
    'Determinant calculation',
    'Matrix inverse computation',
    'Eigenvalue and eigenvector calculation',
    'Row operations and row echelon form',
    'Step-by-step solution breakdown',
    'Support for matrices of any size',
    'Multiple output formats',
    'Mobile-friendly interface',
    'Export functionality'
  ],
  faqs: [
    {
      "@type": "Question",
      "name": "What operations can I perform with this calculator?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can perform a wide range of matrix operations including addition, subtraction, multiplication, finding determinants, calculating inverses, computing eigenvalues and eigenvectors, and performing row operations. Each operation comes with step-by-step solutions."
      }
    },
    {
      "@type": "Question",
      "name": "How do you multiply matrices?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Matrix multiplication (A × B) is performed by taking the dot product of each row in the first matrix with each column in the second matrix. The number of columns in matrix A must equal the number of rows in matrix B. Our calculator shows the step-by-step process."
      }
    },
    {
      "@type": "Question",
      "name": "What is a determinant and when is it used?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The determinant is a scalar value calculated from a square matrix that provides important information about the matrix's properties. It tells us if the matrix is invertible (non-zero determinant) and helps in solving systems of linear equations."
      }
    },
    {
      "@type": "Question",
      "name": "Is this calculator free to use?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, our matrix calculator is completely free to use. You can perform unlimited calculations including complex operations like finding inverses and eigenvalues, all with detailed step-by-step solutions."
      }
    },
    {
      "@type": "Question",
      "name": "Does it work on mobile devices?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, the calculator is fully responsive and works perfectly on smartphones and tablets. The interface automatically adjusts to your screen size for optimal usability."
      }
    }
  ],
  references: [
    {
      title: 'Matrix Operations',
      url: 'https://en.wikipedia.org/wiki/Matrix_(mathematics)#Basic_operations',
      description: 'Comprehensive guide to basic matrix operations'
    },
    {
      title: 'Determinants',
      url: 'https://en.wikipedia.org/wiki/Determinant',
      description: 'Detailed explanation of matrix determinants'
    },
    {
      title: 'Matrix Inverse',
      url: 'https://en.wikipedia.org/wiki/Invertible_matrix',
      description: 'Understanding matrix inverses and their properties'
    },
    {
      title: 'Eigenvalues and Eigenvectors',
      url: 'https://en.wikipedia.org/wiki/Eigenvalues_and_eigenvectors',
      description: 'In-depth coverage of eigenvalues and eigenvectors'
    }
  ]
};
