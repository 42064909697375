import { Building2 } from 'lucide-react';
import { CalculatorInfo } from '../types/CalculatorInfo';

export const concreteCalculator: CalculatorInfo = {
    title: "Concrete Calculator - Volume, Materials & Cost Estimator",
    description: "Free concrete calculator for construction projects. Calculate concrete volume, required materials (cement, sand, gravel, water), cost estimation, and reinforcement needs for slabs, footings, walls, and columns.",
    category: "Advanced",
    icon: Building2,
    
    keywords: [
        "concrete calculator",
        "concrete volume calculator",
        "concrete materials estimator",
        "concrete cost calculator",
        "concrete mix ratio",
        "concrete reinforcement calculator",
        "construction calculator",
        "slab calculator",
        "footing calculator",
        "concrete wall calculator",
        "concrete column calculator",
        "building materials calculator"
    ],

    schema: {
        "@type": "SoftwareApplication",
        "name": "Concrete Calculator",
        "applicationCategory": "ConstructionApplication",
        "operatingSystem": "Any",
        "offers": {
            "@type": "Offer",
            "price": "0",
            "priceCurrency": "USD"
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.8",
            "ratingCount": "1850"
        },
        "featureList": [
            "Multiple Shape Calculations",
            "Material Quantity Estimation",
            "Cost Analysis",
            "Reinforcement Planning",
            "PDF Report Generation",
            "Imperial/Metric Units",
            "Project Presets",
            "Interactive Visualizations"
        ]
    },

    features: [
        "Calculate concrete volume for different shapes (slabs, footings, walls, columns)",
        "Estimate required materials (cement, sand, gravel, water)",
        "Calculate total project costs including materials, labor, and delivery",
        "Plan reinforcement with rebar and wire mesh options",
        "Generate detailed PDF reports",
        "Support for both imperial and metric units",
        "Built-in project presets for common applications",
        "Waste factor consideration",
        "Interactive cost breakdowns",
        "Material ratio visualization"
    ],

    mathFormulas: [
        {
            name: "Concrete Volume (Slab)",
            formula: "Volume = Length × Width × Thickness",
            explanation: "Calculate cubic yards of concrete needed for a rectangular slab"
        },
        {
            name: "Concrete Volume (Column)",
            formula: "Volume = π × (Diameter/2)² × Height",
            explanation: "Calculate concrete volume for circular columns"
        },
        {
            name: "Materials Ratio",
            formula: "1:2.1:3:0.5 (Cement:Sand:Gravel:Water)",
            explanation: "Standard concrete mix ratio for general construction"
        },
        {
            name: "Reinforcement Spacing",
            formula: "Rebar spacing = 1.5 feet (typical)",
            explanation: "Standard spacing for concrete reinforcement bars"
        },
        {
            name: "Total Cost",
            formula: "Cost = Materials + Labor + Delivery + Reinforcement",
            explanation: "Complete project cost calculation including all components"
        }
    ],

    faqs: [
        {
            question: "How much concrete do I need?",
            answer: "Enter your project dimensions (length, width, thickness) and the calculator will determine the volume in cubic yards, cubic feet, and cubic meters. Don't forget to include the waste factor (typically 10%) to account for potential spillage and variations in the ground."
        },
        {
            question: "What are the standard concrete mix ratios?",
            answer: "The standard concrete mix ratio is 1:2.1:3 (cement:sand:gravel) with a water ratio of 0.5. This means for every part of cement, you need 2.1 parts of sand and 3 parts of gravel. The water ratio may vary based on desired workability and strength requirements."
        },
        {
            question: "How do I calculate reinforcement needs?",
            answer: "The calculator estimates reinforcement based on your project type and dimensions. For slabs, wire mesh is typically used with 6-inch spacing. For footings and walls, rebar is recommended with 18-inch spacing. The calculator will provide both material quantities and costs."
        },
        {
            question: "What affects the total cost?",
            answer: "Total concrete project costs include materials (concrete mix components), labor hours, delivery charges, and reinforcement materials. Additional factors include project complexity, location, and whether you need special concrete types or finishes."
        },
        {
            question: "Should I include a waste factor?",
            answer: "Yes, it's recommended to include a waste factor of 10% to account for spillage, uneven ground, and other variables that might require additional concrete. This ensures you won't run short during the pour."
        }
    ],

    references: [
        {
            title: "ACI Concrete Mix Design Guide",
            author: "American Concrete Institute",
            link: "https://www.concrete.org",
            description: "Official guidelines for concrete mix design and specifications"
        },
        {
            title: "ASTM Concrete Standards",
            author: "ASTM International",
            link: "https://www.astm.org",
            description: "International standards for concrete testing and materials"
        },
        {
            title: "Concrete Reinforcement Guide",
            author: "Concrete Reinforcing Steel Institute",
            link: "https://www.crsi.org",
            description: "Standards for concrete reinforcement design and installation"
        },
        {
            title: "Construction Estimating Guide",
            author: "RSMeans",
            link: "https://www.rsmeans.com",
            description: "Industry standard construction cost data and estimating guidelines"
        }
    ],

    itemListElement: [
        {
            "@type": "ListItem",
            "position": 1,
            "name": "Advanced Calculators",
            "item": {
                "@type": "Thing",
                "id": "https://calculato.co/advanced-calculators"
            }
        },
        {
            "@type": "ListItem",
            "position": 2,
            "name": "Graphing Calculator",
            "item": {
                "@type": "Thing",
                "id": "https://www.calculato.co/en/graphing-calculator"
            }
        },
        {
            "@type": "ListItem",
            "position": 3,
            "name": "Concrete Calculator",
            "item": {
                "@type": "Thing",
                "id": "https://calculato.co/concrete-calculator"
            }
        }
    ],
};
