import { Percent } from 'lucide-react';
import { CalculatorInfo } from '../types/CalculatorInfo';

export const percentageCalculator: CalculatorInfo = {
    id: "percentage-calcuulator",
    title: "Percentage Calculator - Complete Percentage & Ratio Tool",
    description: "Free online percentage calculator for basic percentage calculations, increases/decreases, markups, discounts, and fraction conversions. Features interactive visualization, calculation history, and comprehensive percentage analysis tools.",
    category: "Mathematics & Finance",
    icon: Percent,
    
    schema: {
        "@type": "SoftwareApplication",
        "name": "Percentage Calculator",
        "applicationCategory": "CalculatorApplication",
        "operatingSystem": "Any",
        "offers": {
            "@type": "Offer",
            "price": "0",
            "priceCurrency": "USD"
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.9",
            "ratingCount": "2150"
        },
        "featureList": [
            "Basic Percentage Calculations",
            "Percentage Increase/Decrease",
            "Markup and Discount Calculations",
            "Fraction to Percentage Conversion",
            "Percentage Difference Analysis",
            "Visual Result Representation",
            "Calculation History",
            "Interactive Interface"
        ]
    },

    keywords: [
        "percentage calculator",
        "percent calculator",
        "percentage increase calculator",
        "percentage decrease calculator",
        "markup calculator",
        "discount calculator",
        "percentage difference calculator",
        "fraction to percentage",
        "percentage conversion",
        "ratio calculator",
        "percentage change",
        "percentage point calculator"
    ],

    features: [
        "Calculate basic percentages (X% of Y)",
        "Compute percentage increases and decreases",
        "Calculate markup percentages for pricing",
        "Determine discount amounts and final prices",
        "Convert fractions to percentages",
        "Find percentage differences between values",
        "Reverse percentage calculations",
        "Interactive data visualization",
        "Calculation history tracking",
        "Multiple calculation modes"
    ],

    mathFormulas: [
        {
            name: "Basic Percentage",
            formula: "Result = (Percentage ÷ 100) × Number",
            explanation: "Calculates a percentage of a given number"
        },
        {
            name: "Percentage Increase",
            formula: "Increase % = ((New Value - Original Value) ÷ Original Value) × 100",
            explanation: "Calculates the percentage increase from an original value to a new value"
        },
        {
            name: "Percentage Decrease",
            formula: "Decrease % = ((Original Value - New Value) ÷ Original Value) × 100",
            explanation: "Calculates the percentage decrease from an original value to a new value"
        },
        {
            name: "Percentage Difference",
            formula: "Difference % = (|Value1 - Value2| ÷ ((Value1 + Value2) ÷ 2)) × 100",
            explanation: "Calculates the percentage difference between two values"
        },
        {
            name: "Markup Percentage",
            formula: "Markup % = ((Selling Price - Cost) ÷ Cost) × 100",
            explanation: "Calculates the markup percentage based on cost and selling price"
        },
        {
            name: "Discount Calculation",
            formula: "Final Price = Original Price × (1 - (Discount% ÷ 100))",
            explanation: "Calculates the final price after applying a percentage discount"
        }
    ],

    faqs: [
        {
            question: "How do I calculate a percentage of a number?",
            answer: "To calculate X% of Y, multiply Y by (X÷100). For example, to find 15% of 200, calculate: 200 × (15÷100) = 30."
        },
        {
            question: "What's the difference between percentage increase and markup?",
            answer: "While both use similar formulas, markup is specifically used in pricing contexts where the increase is calculated from the cost price. Percentage increase is a more general term that can be used in any context where a value has grown."
        },
        {
            question: "How do I calculate a percentage increase or decrease?",
            answer: "For increase: ((new value - original value) ÷ original value) × 100. For decrease: ((original value - new value) ÷ original value) × 100. The calculator handles these calculations automatically."
        },
        {
            question: "How do I convert a fraction to a percentage?",
            answer: "Divide the numerator by the denominator and multiply by 100. For example, to convert 3/4 to a percentage: (3 ÷ 4) × 100 = 75%."
        },
        {
            question: "What is percentage difference and when should I use it?",
            answer: "Percentage difference measures the relative difference between two values as a percentage of their average. It's useful when comparing values where neither is clearly the starting or ending value."
        }
    ],

    references: [
        {
            title: "Percentage Calculations in Mathematics",
            author: "Khan Academy",
            link: "https://www.khanacademy.org/math/arithmetic-home/arithmetic-percents",
            description: "Comprehensive guide to percentage calculations"
        },
        {
            title: "Business Mathematics - Markup Calculations",
            author: "Small Business Administration",
            link: "https://www.sba.gov/business-guide/manage-your-business/calculate-pricing-profit-margins",
            description: "Guide to business markup calculations"
        },
        {
            title: "Statistical Percentage Differences",
            author: "National Institute of Standards and Technology",
            link: "https://www.nist.gov/statistics",
            description: "Statistical methods for percentage calculations"
        },
        {
            title: "Retail Mathematics",
            author: "National Retail Federation",
            link: "https://nrf.com/resources/retail-library",
            description: "Retail industry standards for markup and discount calculations"
        }
    ],

    itemListElement: [
        {
            "@type": "ListItem",
            "position": 1,
            "name": "Mathematical Tools",
            "item": {
                "@id": "/calculators/math"
            }
        },
        {
            "@type": "ListItem",
            "position": 2,
            "name": "Percentage Calculator",
            "item": {
                "@id": "/calculators/percentage"
            }
        }
    ]
};
