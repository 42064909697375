import { Dumbbell } from 'lucide-react';
import { CalculatorInfo } from './types';

export const tdeeCalculator: CalculatorInfo = {
  id: 'tdee-calculator',
  title: 'TDEE Calculator | Free Total Daily Energy Expenditure Calculator',
  description: 'Free TDEE (Total Daily Energy Expenditure) calculator that accurately determines your daily calorie burn. Calculate maintenance calories, BMR, and activity factor using our science-based calculator. Perfect for weight management and fitness planning.',
  icon: Dumbbell,
  category: 'Health',
  schema: {
    "@type": "SoftwareApplication",
    "name": "TDEE Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "HealthAndFitness",
    "operatingSystem": "Any",
    "description": "Calculate your Total Daily Energy Expenditure (TDEE) with our advanced calculator. Features BMR calculation, activity level adjustment, and personalized energy expenditure analysis.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/tdee-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "2150",
      "reviewCount": "1580",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "BMR Calculation",
      "Activity Level Adjustment",
      "Exercise Calorie Tracking",
      "Weekly TDEE Analysis",
      "Personalized Recommendations",
      "Mobile Friendly",
      "Free Forever"
    ]
  },
  keywords: [
    'tdee calculator',
    'total daily energy expenditure',
    'calorie burn calculator',
    'bmr calculator',
    'maintenance calories',
    'activity factor calculator',
    'daily calorie needs',
    'energy expenditure calculator',
    'fitness calculator',
    'weight management calculator'
  ],
  formulas: [
    {
      expression: 'BMR = 10 × weight + 6.25 × height - 5 × age + s',
      explanation: 'Mifflin-St Jeor Equation for Basal Metabolic Rate (BMR) where s is +5 for males and -161 for females',
      variables: [
        { name: 'weight', description: 'Weight in kilograms' },
        { name: 'height', description: 'Height in centimeters' },
        { name: 'age', description: 'Age in years' },
        { name: 's', description: 'Sex constant (+5 for males, -161 for females)' }
      ]
    },
    {
      expression: 'TDEE = BMR × Activity Factor',
      explanation: 'Total Daily Energy Expenditure calculation using BMR and activity multiplier',
      variables: [
        { name: 'BMR', description: 'Basal Metabolic Rate' },
        { name: 'Activity Factor', description: 'Multiplier based on activity level (1.2-1.9)' }
      ]
    },
    {
      expression: 'TEF = TDEE × 0.1',
      explanation: 'Thermic Effect of Food (TEF) calculation',
      variables: [
        { name: 'TDEE', description: 'Total Daily Energy Expenditure' },
        { name: '0.1', description: 'Approximate percentage of calories burned during digestion (10%)' }
      ]
    },
    {
      expression: 'NEAT = TDEE × Activity Factor Adjustment',
      explanation: 'Non-Exercise Activity Thermogenesis calculation',
      variables: [
        { name: 'TDEE', description: 'Total Daily Energy Expenditure' },
        { name: 'Activity Factor Adjustment', description: 'Varies based on daily non-exercise activities (0.1-0.3)' }
      ]
    }
  ],
  faqs: [
    {
      question: 'What is TDEE?',
      answer: 'TDEE (Total Daily Energy Expenditure) is the total number of calories your body burns in a day, including basic life functions (BMR), physical activities, exercise, digestion (TEF), and non-exercise movements (NEAT). It\'s essential for understanding your maintenance calories and managing weight goals.'
    },
    {
      question: 'How does activity level affect TDEE?',
      answer: 'Activity level significantly impacts TDEE through these multipliers: Sedentary (1.2, desk job, minimal exercise), Light Activity (1.375, light exercise 1-3 days/week), Moderate Activity (1.55, moderate exercise 3-5 days/week), Very Active (1.725, hard exercise 6-7 days/week), Extra Active (1.9, athletes, very physical jobs).'
    },
    {
      question: 'How can I use TDEE for weight management?',
      answer: 'To maintain weight, consume calories equal to your TDEE. For weight loss, create a deficit of 500-1000 calories below TDEE (1-2 pounds per week). For weight gain, add 300-500 calories above TDEE. Always ensure a minimum intake of 1200 calories for women and 1500 for men.'
    },
    {
      question: 'Why does my TDEE change over time?',
      answer: 'TDEE can change due to variations in weight, muscle mass, activity level, age, hormones, sleep quality, and stress levels. Regular recalculation is recommended, especially during significant lifestyle changes or weight fluctuations.'
    },
    {
      question: 'How accurate is the TDEE calculator?',
      answer: 'The TDEE calculator provides a scientific estimate based on the Mifflin-St Jeor equation and activity factors. While it\'s generally accurate for most people, individual variations in metabolism, muscle mass, and daily activities can affect actual energy expenditure. Monitor and adjust based on your results.'
    }
  ],
  references: [
    'Mifflin-St Jeor Equation Research Paper',
    'American Council on Exercise (ACE) Activity Factor Guidelines',
    'Journal of Sports Science & Medicine TDEE Studies',
    'International Journal of Obesity Energy Expenditure Guidelines',
    'Medicine & Science in Sports & Exercise Research',
    'American College of Sports Medicine Position Stand',
    'Exercise Physiology: Energy, Nutrition, and Human Performance'
  ],
  features: [
    'Accurate BMR calculation',
    'Activity level multiplier',
    'Exercise calorie tracking',
    'Daily energy breakdown',
    'Weekly TDEE analysis',
    'Personalized recommendations',
    'Mobile-friendly interface',
    'No registration required',
    'Free to use'
  ],
  itemListElement: [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Health Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/health-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Fitness Tools",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/fitness-tools"
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "TDEE Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/tdee-calculator"
      }
    }
  ]
};
