import { Calculator } from 'lucide-react';
import { CalculatorInfo } from './types';

export const algebraCalculator: CalculatorInfo = {
  id: 'algebra-calculator',
  title: 'Algebra Calculator | Equation Solver & Expression Simplifier | Calculato.co',
  description: 'Free advanced algebra calculator with step-by-step solutions for equations, polynomial factoring, and expression simplification. Features include variable manipulation, detailed explanations, and calculation history.',
  icon: Calculator,
  category: 'Academic',
  schema: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Algebra Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Mathematics",
    "operatingSystem": "Any",
    "description": "Comprehensive algebra calculator for solving equations, factoring polynomials, and simplifying expressions. Provides step-by-step solutions with detailed explanations and supports multiple algebraic operations.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/algebra-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "2850",
      "reviewCount": "2340",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Equation Solving",
      "Polynomial Factoring",
      "Expression Simplification",
      "Step-by-Step Solutions",
      "Variable Manipulation",
      "LaTeX Equation Display",
      "Calculation History",
      "Expression Validation",
      "Result Export",
      "Multiple Operation Types"
    ]
  },
  formulas: [
    {
      expression: 'ax + b = c',
      explanation: 'Solve linear equations by isolating the variable',
      variables: [
        { name: 'a', description: 'Coefficient of x' },
        { name: 'b', description: 'Constant term' },
        { name: 'c', description: 'Right-hand side constant' },
        { name: 'x', description: 'Variable to solve for' }
      ]
    },
    {
      expression: 'ax² + bx + c',
      explanation: 'Factor quadratic expressions into (px + q)(rx + s)',
      variables: [
        { name: 'a', description: 'Coefficient of x²' },
        { name: 'b', description: 'Coefficient of x' },
        { name: 'c', description: 'Constant term' }
      ]
    },
    {
      expression: '(ax + b)(cx + d)',
      explanation: 'Expand and simplify polynomial expressions',
      variables: [
        { name: 'a,c', description: 'Coefficients of x terms' },
        { name: 'b,d', description: 'Constant terms' }
      ]
    },
    {
      expression: 'ax^n + bx^m',
      explanation: 'Combine like terms in polynomial expressions',
      variables: [
        { name: 'a,b', description: 'Coefficients' },
        { name: 'n,m', description: 'Exponents' }
      ]
    },
    {
      expression: '(x + a)^n',
      explanation: 'Expand binomial expressions using binomial theorem',
      variables: [
        { name: 'x', description: 'Variable' },
        { name: 'a', description: 'Constant term' },
        { name: 'n', description: 'Power' }
      ]
    }
  ],
  faqs: [
    {
      question: 'How do I solve equations step by step?',
      answer: 'To solve equations: 1) Enter the equation with an equals sign (e.g., 2x + 3 = 7), 2) Select "solve" operation, 3) Choose the variable to solve for (default is x), 4) Click calculate to see step-by-step solution including isolating the variable and performing arithmetic operations.'
    },
    {
      question: 'How do I factor polynomials?',
      answer: 'For polynomial factoring: 1) Enter the expression (e.g., x² + 5x + 6), 2) Select "factor" operation, 3) Click calculate to see the factored form. The calculator shows steps including finding common factors, grouping terms, and identifying binomial factors.'
    },
    {
      question: 'How do I simplify algebraic expressions?',
      answer: 'To simplify expressions: 1) Enter the expression (e.g., 3x + 2x - 4), 2) Select "simplify" operation, 3) Click calculate. The calculator combines like terms, applies algebraic properties, and reduces fractions to show the simplest form.'
    },
    {
      question: 'Can I save or export my calculations?',
      answer: 'Yes! The calculator provides multiple options: 1) View calculation history in the interface, 2) Export history as a text file using the download button, 3) Each entry includes the original equation, solution steps, and final result.'
    },
    {
      question: 'What types of equations can I solve?',
      answer: 'The calculator handles various equation types: 1) Linear equations (ax + b = c), 2) Quadratic equations (ax² + bx + c = 0), 3) Polynomial equations, 4) Systems of equations. Each solution includes step-by-step explanations.'
    }
  ],
  references: [
    'Algebraic Properties and Operations',
    'Polynomial Factoring Methods',
    'Equation Solving Techniques',
    'Expression Simplification Rules',
    'Mathematical Notation Standards',
    'Variable Manipulation Guidelines',
    'Algebraic Problem-Solving Strategies',
    'Educational Mathematics Standards'
  ],
  keywords: [
    'algebra calculator',
    'equation solver',
    'polynomial factoring calculator',
    'expression simplifier',
    'math problem solver',
    'step by step algebra',
    'variable calculator',
    'algebraic expression calculator',
    'math equation solver',
    'free algebra calculator'
  ],
  features: [
    'Equation solving with steps',
    'Polynomial factoring',
    'Expression simplification',
    'Step-by-step solutions',
    'Variable manipulation',
    'LaTeX equation display',
    'Calculation history',
    'Result export',
    'Multiple operation modes',
    'Educational explanations'
  ],
  itemListElement: [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Academic Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/academic-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Algebra Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/algebra-calculator"
      }
    }
  ]
};
