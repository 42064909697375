import { Calculator } from 'lucide-react';
import { CalculatorInfo } from './types';

export const fractionCalculator: CalculatorInfo = {
  id: 'fraction-calculator',
  title: 'Fraction Calculator | Add, Subtract, Multiply & Divide Fractions',
  description: 'Free online fraction calculator for adding, subtracting, multiplying, and dividing fractions. Features step-by-step solutions, fraction simplification, and conversion to decimals.',
  icon: Calculator,
  category: 'Arithmetic',
  schema: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Fraction Calculator",
    "applicationCategory": "EducationalApplication",
    "applicationSubCategory": "Arithmetic",
    "operatingSystem": ["Windows", "macOS", "Linux", "Chrome OS", "Android", "iOS"],
    "description": "Advanced fraction calculator for arithmetic operations, simplification, and decimal conversions. Features step-by-step solutions and detailed explanations.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "priceSpecification": {
        "@type": "UnitPriceSpecification",
        "priceCurrency": "USD",
        "price": "0"
      },
      "availability": "https://schema.org/InStock",
      "seller": {
        "@type": "Organization",
        "name": "Your Company Name"
      }
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "1950",
      "reviewCount": "1580",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Fraction Addition",
      "Fraction Subtraction",
      "Fraction Multiplication",
      "Fraction Division",
      "Fraction Simplification",
      "Decimal Conversion",
      "Step-by-step Solutions",
      "Mixed Numbers Support",
      "Mobile Friendly Interface",
      "Free to Use"
    ]
  },
  keywords: [
    'fraction calculator',
    'add fractions',
    'subtract fractions',
    'multiply fractions',
    'divide fractions',
    'simplify fractions',
    'fraction to decimal',
    'mixed number calculator',
    'fraction simplifier',
    'online fraction calculator',
    'free fraction calculator',
    'improper fraction calculator',
    'proper fraction calculator',
    'fraction math solver',
    'fraction operations'
  ],
  formulas: [
    {
      expression: 'a/b ± c/d = (ad ± bc)/bd',
      explanation: 'Addition and subtraction of fractions',
      variables: [
        { name: 'a,c', description: 'Numerators' },
        { name: 'b,d', description: 'Denominators' }
      ]
    },
    {
      expression: '(a/b) × (c/d) = ac/bd',
      explanation: 'Multiplication of fractions',
      variables: [
        { name: 'a,c', description: 'Numerators' },
        { name: 'b,d', description: 'Denominators' }
      ]
    },
    {
      expression: '(a/b) ÷ (c/d) = ad/bc',
      explanation: 'Division of fractions',
      variables: [
        { name: 'a,c', description: 'Numerators' },
        { name: 'b,d', description: 'Denominators' }
      ]
    }
  ],
  features: [
    'Addition and subtraction of fractions',
    'Multiplication and division of fractions',
    'Fraction simplification',
    'Mixed number calculations',
    'Decimal and percentage conversion',
    'Step-by-step solution display',
    'Support for improper fractions',
    'Mobile-friendly interface',
    'Export functionality',
    'Detailed explanations'
  ],
  faqs: [
    {
      question: 'How do you add and subtract fractions?',
      answer: 'To add or subtract fractions, you need to: 1) Find a common denominator (usually the least common multiple of the denominators), 2) Convert each fraction to equivalent fractions with this common denominator, 3) Add or subtract the numerators while keeping the same denominator, 4) Simplify the result if possible.'
    },
    {
      question: 'How do you multiply fractions?',
      answer: 'To multiply fractions, multiply the numerators together and multiply the denominators together. For example, (a/b) × (c/d) = (a×c)/(b×d). After multiplying, simplify the result if possible by finding common factors in the numerator and denominator.'
    },
    {
      question: 'How do you divide fractions?',
      answer: 'To divide fractions, multiply the first fraction by the reciprocal (flip) of the second fraction. For example, (a/b) ÷ (c/d) = (a/b) × (d/c) = (a×d)/(b×c). Then simplify the result if possible.'
    },
    {
      question: 'How do you simplify fractions?',
      answer: 'To simplify a fraction, find the greatest common divisor (GCD) of the numerator and denominator, then divide both by this number. For example, 8/12 can be simplified by dividing both numbers by 4 to get 2/3.'
    },
    {
      question: 'What is a mixed number and how do you convert it?',
      answer: 'A mixed number is a whole number plus a proper fraction, like 3½. To convert a mixed number to an improper fraction, multiply the whole number by the denominator and add the numerator, then put this over the original denominator. For example, 3½ = (3×2 + 1)/2 = 7/2.'
    }
  ],
  references: [
    {
      title: 'Fraction Operations',
      url: 'https://en.wikipedia.org/wiki/Fraction_(mathematics)#Operations',
      description: 'Comprehensive guide to fraction operations'
    },
    {
      title: 'Mixed Numbers',
      url: 'https://en.wikipedia.org/wiki/Mixed_number',
      description: 'Understanding mixed numbers and improper fractions'
    },
    {
      title: 'Fraction Simplification',
      url: 'https://en.wikipedia.org/wiki/Simplification_of_fractions',
      description: 'Methods for simplifying fractions'
    },
    {
      title: 'Common Denominators',
      url: 'https://en.wikipedia.org/wiki/Least_common_multiple#Fractions',
      description: 'Finding common denominators using LCM'
    }
  ]
};
