import { Calendar } from 'lucide-react';
import { CalculatorInfo } from './types';

export const ageCalculator: CalculatorInfo = {
  id: 'age-calculator',
  title: 'Age Calculator - Free Online Birthday & Life Milestone Calculator',
  description: 'Calculate exact age in years, months, and days with our free age calculator. Features include life milestones, dog age conversion, Korean age, biological age estimation, and birthday countdown. Perfect for personal planning and life event tracking.',
  icon: Calendar,
  category: 'Lifestyle & Utility',
  
  schema: {
    "@type": "SoftwareApplication",
    "name": "Age Calculator",
    "applicationCategory": "UtilityApplication",
    "operatingSystem": "Any",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/age-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "1850"
    },
    "featureList": [
      "Precise Age Calculation",
      "Life Milestone Tracking",
      "Dog Age Conversion",
      "Korean Age Calculation",
      "Biological Age Estimation",
      "Birthday Countdown",
      "Interactive Timeline",
      "Age Statistics"
    ]
  },

  keywords: [
    "age calculator",
    "birthday calculator",
    "date difference calculator",
    "life milestone calculator",
    "dog age calculator",
    "korean age calculator",
    "biological age calculator",
    "age in years calculator",
    "exact age calculator",
    "birthday countdown",
    "life event planning",
    "age statistics"
  ],

  features: [
    "Calculate exact age in years, months, and days",
    "Track important life milestones (driving age, legal adult, retirement, etc.)",
    "Convert human age to dog age with breed size consideration",
    "Calculate Korean age system",
    "Estimate biological age based on lifestyle factors",
    "Interactive timeline visualization",
    "Birthday countdown calculator",
    "Export calculations in formatted text",
    "Multiple date format support",
    "Automatic leap year handling"
  ],

  formulas: [
    {
      name: "Basic Age Calculation",
      expression: 'Age = Current Date - Birth Date',
      explanation: 'Calculates the difference between current date and birth date, accounting for leap years and varying month lengths',
      variables: [
        { name: 'Current Date', description: 'Today\'s date or end date' },
        { name: 'Birth Date', description: 'Starting date or birth date' }
      ]
    },
    {
      name: "Dog Age Calculation",
      expression: 'Dog Age = 21 + (Human Age - 2) × Breed Factor',
      explanation: 'Converts human age to dog age considering breed size',
      variables: [
        { name: 'Human Age', description: 'Age in human years' },
        { name: 'Breed Factor', description: 'Size-based multiplier (4-5.5)' }
      ]
    },
    {
      name: "Korean Age Calculation",
      expression: 'Korean Age = Current Year - Birth Year + 1',
      explanation: 'Calculates age according to the Korean age system',
      variables: [
        { name: 'Current Year', description: 'Present year' },
        { name: 'Birth Year', description: 'Year of birth' }
      ]
    },
    {
      name: "Biological Age Estimation",
      expression: 'Biological Age = Chronological Age + (Lifestyle Factor - 5) × 0.5',
      explanation: 'Estimates biological age based on lifestyle factors',
      variables: [
        { name: 'Chronological Age', description: 'Actual age in years' },
        { name: 'Lifestyle Factor', description: 'Scale of 0-10 representing health habits' }
      ]
    }
  ],

  faqs: [
    {
      question: 'How are leap years handled?',
      answer: 'The calculator automatically accounts for leap years when calculating age, ensuring accurate results even across leap year boundaries.'
    },
    {
      question: 'What additional information is provided?',
      answer: 'Besides your exact age, you can see your age in months, weeks, days, hours, next birthday countdown, life milestones, and various age conversions (dog age, Korean age, biological age).'
    },
    {
      question: 'How accurate is the dog age calculator?',
      answer: 'The dog age calculator uses a sophisticated formula that considers breed size, providing more accurate results than the simple "multiply by 7" rule. It accounts for faster aging in the first two years and different aging rates based on dog size.'
    },
    {
      question: 'What is Korean age and how is it calculated?',
      answer: 'Korean age counts babies as 1 year old at birth and adds a year every New Year\'s Day. It\'s calculated by taking the current year minus birth year plus 1, regardless of the actual birth date.'
    },
    {
      question: 'How is biological age estimated?',
      answer: 'Biological age estimation considers lifestyle factors on a scale of 0-10, including exercise habits, diet, sleep, and stress levels. This provides a rough estimate of how your body age compares to your chronological age.'
    }
  ],

  references: [
    {
      title: "International Standards for Age Calculation",
      author: "International Organization for Standardization",
      link: "https://www.iso.org/standard/date-time.html",
      description: "Standards for date and time calculations"
    },
    {
      title: "Dog Age Studies",
      author: "American Veterinary Medical Association",
      link: "https://www.avma.org/resources/pet-owners/dogcare",
      description: "Research on dog aging patterns"
    },
    {
      title: "Korean Age System",
      author: "National Folk Museum of Korea",
      link: "https://www.nfm.go.kr",
      description: "Traditional Korean age calculation system"
    },
    {
      title: "Biological Age Research",
      author: "National Institute on Aging",
      link: "https://www.nia.nih.gov",
      description: "Studies on biological vs chronological aging"
    }
  ],

  itemListElement: [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Lifestyle Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/lifestyle-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Age Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/age-calculator"
      }
    }
  ]
};
