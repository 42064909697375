import { DollarSign } from 'lucide-react';
import { CalculatorInfo } from './types';

export const loanCalculator: CalculatorInfo = {
  id: 'loan-calculator',
  title: 'Loan Calculator | Free Loan Payment & Amortization Calculator',
  description: 'Free online loan calculator with amortization schedule. Calculate monthly payments, total interest, loan terms, and view detailed payment breakdowns. Perfect for mortgages, auto loans, personal loans, and business financing.',
  icon: DollarSign,
  category: 'Financial',
  schema: {
    "@type": "SoftwareApplication",
    "name": "Loan Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Finance",
    "operatingSystem": "Any",
    "description": "Professional loan calculator with amortization schedule, payment frequency options, and visual payment breakdowns. Essential for loan planning and financial decisions.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/loan-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "2150",
      "reviewCount": "1280",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Monthly Payment Calculation",
      "Detailed Amortization Schedule",
      "Multiple Payment Frequencies",
      "Visual Payment Breakdowns",
      "Total Interest Calculation",
      "Loan Term Analysis",
      "Interactive Charts",
      "Export Capabilities",
      "Mobile Friendly Design",
      "Free Forever"
    ]
  },
  formulas: [
    {
      expression: 'PMT = P × (r × (1 + r)^n) / ((1 + r)^n - 1)',
      explanation: 'Monthly payment calculation where PMT is the payment amount, P is the principal, r is the monthly interest rate, and n is the number of payments',
      variables: [
        { name: 'P', description: 'Principal loan amount' },
        { name: 'r', description: 'Monthly interest rate (annual rate / 12)' },
        { name: 'n', description: 'Total number of payments' }
      ]
    },
    {
      expression: 'Total Interest = (Monthly Payment × Number of Payments) - Principal',
      explanation: 'Calculate the total interest paid over the life of the loan',
      variables: [
        { name: 'Monthly Payment', description: 'Calculated monthly payment amount' },
        { name: 'Number of Payments', description: 'Total number of payments' },
        { name: 'Principal', description: 'Original loan amount' }
      ]
    }
  ],
  faqs: [
    {
      question: 'What types of loans can I calculate?',
      answer: 'You can calculate payments for any type of loan including mortgages, auto loans, personal loans, business loans, and student loans.'
    },
    {
      question: 'What payment frequencies are supported?',
      answer: 'The calculator supports multiple payment frequencies including monthly, bi-weekly, and weekly payments.'
    },
    {
      question: "Can I see how much interest I'll pay?",
      answer: 'Yes, the calculator shows total interest paid, monthly payment breakdowns, and a detailed amortization schedule.'
    },
    {
      question: 'Is the amortization schedule downloadable?',
      answer: 'Yes, you can export the complete amortization schedule for your records or analysis.'
    },
    {
      question: 'How accurate are the calculations?',
      answer: 'All calculations use industry-standard financial formulas with high precision for accurate results.'
    }
  ],
  references: [
    'Time Value of Money Principles',
    'Loan Amortization Methods',
    'Financial Mathematics',
    'Banking Industry Standards',
    'Consumer Financial Protection Guidelines'
  ],
  keywords: [
    'loan calculator',
    'mortgage calculator',
    'amortization calculator',
    'payment calculator',
    'auto loan calculator',
    'personal loan calculator',
    'loan amortization schedule',
    'loan payment calculator',
    'financial calculator',
    'free loan calculator'
  ],
  features: [
    'Monthly payment calculation',
    'Amortization schedule generation',
    'Multiple payment frequency options',
    'Visual payment breakdowns',
    'Interactive charts and graphs',
    'Principal and interest analysis',
    'Loan term comparison',
    'Payment schedule export',
    'Mobile-responsive design',
    'High-precision calculations'
  ],
  itemListElement: [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Financial Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/financial-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Auto Loan Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://www.calculato.co/en/auto-loan-calculator"
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Mortgage Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://www.calculato.co/en/mortgage-calculator"
      }
    }
  ]
};
