import { Calculator } from 'lucide-react';
import { CalculatorInfo } from './types';

export const scientificCalculator: CalculatorInfo = {
  id: 'scientific-calculator',
  title: 'Free Scientific Calculator Online| Advanced Math & Engineering Calculator',
  description: 'Professional scientific calculator with advanced mathematical functions including trigonometry, logarithms, exponentials, and complex calculations. Features include scientific notation, memory functions, and calculation history.',
  icon: Calculator,
  category: 'Mathematics',
  schema: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Scientific Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Mathematics",
    "operatingSystem": "Any",
    "description": "Advanced scientific calculator with comprehensive mathematical functions including trigonometry, logarithms, exponentials, and complex calculations. Features scientific notation, memory functions, and calculation history tracking.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/scientific-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "3250",
      "reviewCount": "2890",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Advanced Mathematical Operations",
      "Trigonometric Functions",
      "Logarithmic Calculations",
      "Scientific Notation",
      "Memory Functions",
      "Calculation History",
      "Keyboard Support",
      "High Precision Results",
      "Degree/Radian Mode",
      "Inverse Functions"
    ]
  },
  formulas: [
    {
      expression: 'sin(θ)',
      explanation: 'Calculates the sine of an angle in current mode (degrees/radians)',
      variables: [
        { name: 'θ', description: 'Angle in degrees or radians' }
      ]
    },
    {
      expression: 'cos(θ)',
      explanation: 'Calculates the cosine of an angle in current mode (degrees/radians)',
      variables: [
        { name: 'θ', description: 'Angle in degrees or radians' }
      ]
    },
    {
      expression: 'tan(θ)',
      explanation: 'Calculates the tangent of an angle in current mode (degrees/radians)',
      variables: [
        { name: 'θ', description: 'Angle in degrees or radians' }
      ]
    },
    {
      expression: 'log₁₀(x)',
      explanation: 'Calculates the base-10 logarithm of a number',
      variables: [
        { name: 'x', description: 'Positive real number' }
      ]
    },
    {
      expression: 'ln(x)',
      explanation: 'Calculates the natural logarithm (base e) of a number',
      variables: [
        { name: 'x', description: 'Positive real number' }
      ]
    },
    {
      expression: 'x²',
      explanation: 'Calculates the square of a number',
      variables: [
        { name: 'x', description: 'Any real number' }
      ]
    },
    {
      expression: '√x',
      explanation: 'Calculates the square root of a number',
      variables: [
        { name: 'x', description: 'Non-negative real number' }
      ]
    },
    {
      expression: 'xⁿ',
      explanation: 'Raises x to the power of n',
      variables: [
        { name: 'x', description: 'Base number' },
        { name: 'n', description: 'Exponent' }
      ]
    }
  ],
  faqs: [
    {
      question: 'How do I use scientific notation in this calculator?',
      answer: 'To use scientific notation: 1) Enter the coefficient (e.g., 1.23), 2) Press the EXP or E key, 3) Enter the exponent. For example, 1.23E6 represents 1.23 × 10⁶. The calculator automatically displays very large or small numbers in scientific notation.'
    },
    {
      question: 'How do I switch between degrees and radians?',
      answer: 'Use the DEG/RAD toggle button to switch between degree and radian modes. The current mode is displayed on screen and affects all trigonometric calculations (sin, cos, tan). The mode setting persists until changed.'
    },
    {
      question: 'What memory functions are available?',
      answer: 'The calculator includes four memory functions: 1) M+ adds the displayed value to memory, 2) M- subtracts from memory, 3) MR recalls the stored value, 4) MC clears the memory. The memory value is retained until cleared or the calculator is reset.'
    },
    {
      question: 'How do I use inverse trigonometric functions?',
      answer: 'For inverse functions (arcsin, arccos, arctan): 1) Press the INV button to enable inverse mode, 2) Press the desired trigonometric function button (sin, cos, or tan). Results are provided in the current angle mode (degrees/radians).'
    },
    {
      question: 'Can I perform complex calculations with parentheses?',
      answer: 'Yes, use parentheses to group operations and control calculation order. Press ( to open and ) to close parentheses. The calculator follows standard order of operations (PEMDAS) and supports nested parentheses for complex expressions.'
    }
  ],
  references: [
    'Trigonometric Functions and Identities',
    'Logarithmic Properties and Rules',
    'Scientific Notation Standards',
    'Complex Number Operations',
    'Mathematical Constants (π, e)',
    'Exponential Functions',
    'Inverse Trigonometric Functions',
    'Engineering Mathematics Standards',
    'Order of Operations (PEMDAS)',
    'Numerical Analysis Methods'
  ],
  keywords: [
    'scientific calculator',
    'engineering calculator',
    'advanced calculator',
    'trigonometry calculator',
    'logarithm calculator',
    'exponential calculator',
    'complex math calculator',
    'scientific notation',
    'math functions',
    'online calculator'
  ],
  features: [
    'Comprehensive trigonometric functions (sin, cos, tan)',
    'Logarithmic and exponential calculations',
    'Scientific notation support',
    'Memory functions (M+, M-, MR, MC)',
    'Degree and radian mode switching',
    'Inverse trigonometric functions',
    'Square root and power functions',
    'Parentheses for complex expressions',
    'Constants (π, e) built-in',
    'High-precision decimal calculations'
  ]
};
