import { PiggyBank } from 'lucide-react';
import { CalculatorInfo } from './types';

export const savingsCalculator: CalculatorInfo = {
  id: 'savings-calculator',
  title: 'Savings Calculator | Free Goal-Based Savings Planner',
  description: 'Free savings calculator with goal tracking and visual projections. Plan multiple savings goals, calculate required monthly contributions, and visualize your savings growth with adjustable interest rates and inflation considerations.',
  icon: PiggyBank,
  category: 'Financial',
  schema: {
    "@type": "SoftwareApplication",
    "name": "Savings Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Finance",
    "operatingSystem": "Any",
    "description": "Professional savings planning calculator with multiple goal tracking, compound interest projections, and visual progress charts. Essential for personal financial planning and goal achievement.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/savings-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "2340",
      "reviewCount": "1450",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Multiple Savings Goals",
      "Visual Progress Tracking",
      "Interest Rate Calculations",
      "Inflation Adjustment",
      "Monthly Contribution Calculator",
      "Interactive Charts",
      "Progress Timeline",
      "Goal Achievement Projections",
      "Mobile Friendly Design",
      "Free Forever"
    ]
  },
  formulas: [
    {
      expression: 'FV = P(1 + r)^n + PMT × (((1 + r)^n - 1) / r)',
      explanation: 'Calculate future savings value where P is principal, r is monthly interest rate, n is number of months, and PMT is monthly contribution',
      variables: [
        { name: 'P', description: 'Initial savings amount' },
        { name: 'r', description: 'Monthly interest rate (annual rate / 12)' },
        { name: 'n', description: 'Number of months' },
        { name: 'PMT', description: 'Monthly contribution amount' }
      ]
    },
    {
      expression: 'PMT = (FV - P(1 + r)^n) × r / ((1 + r)^n - 1)',
      explanation: 'Calculate required monthly contribution to reach savings goal',
      variables: [
        { name: 'FV', description: 'Target savings amount' },
        { name: 'P', description: 'Initial savings amount' },
        { name: 'r', description: 'Monthly interest rate' },
        { name: 'n', description: 'Number of months until goal' }
      ]
    },
    {
      expression: 'Real Value = Nominal Value / (1 + i)^t',
      explanation: 'Calculate inflation-adjusted savings value',
      variables: [
        { name: 'i', description: 'Annual inflation rate' },
        { name: 't', description: 'Number of years' }
      ]
    }
  ],
  faqs: [
    {
      question: 'How do I calculate how much I need to save monthly?',
      answer: 'Enter your savings goal amount, target date, initial savings, and expected interest rate. The calculator will show your required monthly contribution and provide visual projections of your savings growth.'
    },
    {
      question: 'Can I track multiple savings goals?',
      answer: 'Yes, you can create and track multiple savings goals simultaneously, each with its own target amount, timeline, and contribution plan.'
    },
    {
      question: 'Does it account for interest rates?',
      answer: 'Yes, you can input expected interest rates, and the calculator will show compound interest growth in your projections.'
    },
    {
      question: 'How does inflation affect my savings goal?',
      answer: 'The calculator includes inflation adjustment options to show both nominal and real (inflation-adjusted) values of your future savings.'
    },
    {
      question: 'Can I adjust my savings plan over time?',
      answer: 'Yes, you can modify your goals, contribution amounts, and timelines at any time to see updated projections and stay on track.'
    }
  ],
  references: [
    'Personal Finance Planning Guidelines',
    'Compound Interest Calculations',
    'Inflation Rate Historical Data',
    'Goal-Based Savings Strategies',
    'Emergency Fund Guidelines'
  ],
  keywords: [
    'savings calculator',
    'goal savings calculator',
    'savings goal planner',
    'monthly savings calculator',
    'compound interest savings',
    'savings goal tracker',
    'emergency fund calculator',
    'savings projection calculator',
    'savings growth calculator',
    'free savings calculator'
  ],
  features: [
    'Multiple savings goals tracking',
    'Visual progress charts',
    'Compound interest calculations',
    'Inflation adjustment',
    'Monthly contribution calculator',
    'Goal timeline projections',
    'Progress tracking',
    'Interactive data visualization',
    'Mobile-responsive design',
    'High-precision calculations'
  ],
  itemListElement: [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Financial Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/financial-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Savings Tools",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/savings-tools"
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Savings Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/savings-calculator"
      }
    }
  ]
};
