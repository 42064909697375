import { PiggyBank } from 'lucide-react';
import { CalculatorInfo } from './types';

export const retirementCalculator: CalculatorInfo = {
  id: 'retirement-calculator',
  title: 'Retirement Calculator | Free Retirement Planning Calculator',
  description: 'Free retirement calculator with comprehensive planning tools. Calculate retirement savings needs, project investment growth, account for inflation, and analyze retirement income including Social Security. Perfect for retirement planning and financial goal setting.',
  icon: PiggyBank,
  category: 'Financial',
  schema: {
    "@type": "SoftwareApplication",
    "name": "Retirement Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Finance",
    "operatingSystem": "Any",
    "description": "Professional retirement planning calculator with investment projections, inflation adjustment, employer match calculations, and detailed retirement income analysis. Essential for long-term financial planning.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/retirement-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "2850",
      "reviewCount": "1680",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Investment Growth Projections",
      "Inflation Adjustment",
      "Employer Match Calculator",
      "Social Security Integration",
      "Retirement Income Analysis",
      "Interactive Timeline Charts",
      "PDF Report Generation",
      "Shortfall Calculator",
      "Mobile Friendly Design",
      "Free Forever"
    ]
  },
  formulas: [
    {
      expression: 'Future Value = P(1 + r)^t + PMT × (((1 + r)^t - 1) / r) × (1 + r)',
      explanation: 'Calculate future retirement savings where P is principal, r is annual return rate, t is years until retirement, and PMT is annual contribution',
      variables: [
        { name: 'P', description: 'Current retirement savings' },
        { name: 'r', description: 'Expected annual return rate' },
        { name: 't', description: 'Years until retirement' },
        { name: 'PMT', description: 'Annual contribution amount' }
      ]
    },
    {
      expression: 'Required Income = Current Income × Desired Income Percentage',
      explanation: 'Calculate required retirement income based on current income and desired replacement rate',
      variables: [
        { name: 'Current Income', description: 'Current annual income' },
        { name: 'Desired Income Percentage', description: 'Desired retirement income as percentage of current income' }
      ]
    },
    {
      expression: 'Real Return = ((1 + Nominal Return) / (1 + Inflation)) - 1',
      explanation: 'Calculate inflation-adjusted investment returns',
      variables: [
        { name: 'Nominal Return', description: 'Expected investment return rate' },
        { name: 'Inflation', description: 'Expected inflation rate' }
      ]
    }
  ],
  faqs: [
    {
      question: 'How much should I save for retirement?',
      answer: 'The general rule suggests saving 10-15% of your annual income for retirement, but the exact amount depends on your age, lifestyle, and retirement goals.'
    },
    {
      question: 'When should I start saving for retirement?',
      answer: 'The best time to start saving is as early as possible. Starting early allows your investments more time to grow through compound interest.'
    },
    {
      question: 'Can I include Social Security income?',
      answer: 'Yes, you can input expected Social Security benefits, which are included in your retirement income calculations.'
    },
    {
      question: 'What if I am not saving enough?',
      answer: 'The calculator will show any projected shortfall and suggest the additional monthly savings needed to meet your retirement goals.'
    }
  ],
  references: [
    'Retirement Planning Guidelines',
    'Investment Return Historical Data',
    'Social Security Administration Guidelines',
    'Inflation Rate Projections',
    'Life Expectancy Tables'
  ],
  keywords: [
    'retirement calculator',
    'retirement planning calculator',
    'retirement savings calculator',
    '401k calculator',
    'pension calculator',
    'retirement income calculator',
    'retirement goal calculator',
    'retirement investment calculator',
    'social security calculator',
    'free retirement calculator'
  ],
  features: [
    'Investment growth projections',
    'Inflation adjustment',
    'Employer match calculation',
    'Social Security integration',
    'Retirement income analysis',
    'Shortfall detection',
    'Interactive timeline charts',
    'PDF report generation',
    'Mobile-responsive design',
    'High-precision calculations'
  ],
  itemListElement: [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Financial Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/financial-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Retirement Tools",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/retirement-tools"
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Retirement Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/retirement-calculator"
      }
    }
  ]
};
