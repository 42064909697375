import { Calculator } from 'lucide-react';
import { CalculatorInfo } from './types';

export const integralCalculator: CalculatorInfo = {
  id: 'integral-calculator',
  title: 'Integral Calculator | Definite & Indefinite Integration | Free Math Tool',
  description: 'Free online integral calculator for both definite and indefinite integration. Solves complex integrals with step-by-step solutions, supports various integration techniques, and provides detailed explanations.',
  icon: Calculator,
  category: 'Calculus',
  schema: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Integral Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Calculus",
    "operatingSystem": "Any",
    "description": "Advanced integral calculator supporting both definite and indefinite integration with step-by-step solutions and detailed explanations.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "2850",
      "reviewCount": "2340",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Definite Integration",
      "Indefinite Integration",
      "Step-by-step Solutions",
      "Multiple Integration Techniques",
      "Complex Function Support",
      "Graphical Visualization",
      "Mobile Friendly Interface",
      "Free to Use",
      "Export Results",
      "Detailed Explanations"
    ]
  },
  keywords: [
    'integral calculator',
    'definite integral',
    'indefinite integral',
    'integration calculator',
    'antiderivative calculator',
    'calculus calculator',
    'online integral solver',
    'free integral calculator',
    'step by step integration',
    'integration by parts',
    'integration by substitution',
    'multiple integral calculator',
    'area under curve calculator',
    'volume calculator',
    'mathematical integration tool'
  ],
  formulas: [
    {
      expression: '∫f(x)dx',
      explanation: 'Indefinite integral (antiderivative) of function f(x)',
      variables: [
        { name: 'f(x)', description: 'Function to integrate' },
        { name: 'dx', description: 'Differential element' }
      ]
    },
    {
      expression: '∫[a to b]f(x)dx',
      explanation: 'Definite integral of f(x) from a to b',
      variables: [
        { name: 'f(x)', description: 'Function to integrate' },
        { name: 'a,b', description: 'Lower and upper bounds' },
        { name: 'dx', description: 'Differential element' }
      ]
    },
    {
      expression: '∫udv = uv - ∫vdu',
      explanation: 'Integration by parts formula',
      variables: [
        { name: 'u,v', description: 'Functions to integrate' },
        { name: 'du,dv', description: 'Differentials' }
      ]
    }
  ],
  features: [
    'Definite and indefinite integration',
    'Step-by-step solution display',
    'Multiple integration techniques',
    'Support for complex functions',
    'Graphical visualization of integrals',
    'Integration by parts solver',
    'Integration by substitution',
    'Area and volume calculations',
    'Mobile-friendly interface',
    'Export functionality',
    'Detailed explanations'
  ],
  faqs: [
    {
      question: 'What is integration?',
      answer: 'Integration is a fundamental concept in calculus that finds the area under a curve, total accumulation, or antiderivative of a function. It is the reverse process of differentiation and is used to solve problems involving areas, volumes, and accumulation over time.'
    },
    {
      question: 'What is the difference between definite and indefinite integrals?',
      answer: 'Definite integrals have specific upper and lower bounds and give a numerical result representing area or accumulation. Indefinite integrals (antiderivatives) find a general function whose derivative is the integrand, including a constant of integration.'
    },
    {
      question: 'What is integration by parts?',
      answer: 'Integration by parts is a technique used when integrating products of functions. It uses the formula ∫udv = uv - ∫vdu and is particularly useful for integrals involving products of polynomials, exponentials, trigonometric functions, or logarithms.'
    },
    {
      question: 'How do you evaluate definite integrals?',
      answer: 'To evaluate a definite integral: 1) Find the antiderivative (indefinite integral) of the function, 2) Evaluate the antiderivative at the upper bound, 3) Subtract the antiderivative evaluated at the lower bound. This is known as the Fundamental Theorem of Calculus.'
    },
    {
      question: 'What are the applications of integration?',
      answer: 'Integration has numerous applications including: calculating areas under curves, volumes of solids, work done by forces, center of mass, probability distributions, electric flux, and many other physical quantities that involve accumulation or totaling over an interval.'
    }
  ],
  references: [
    {
      title: 'Integration Techniques',
      url: 'https://en.wikipedia.org/wiki/Integration_by_parts',
      description: 'Comprehensive guide to various integration methods'
    },
    {
      title: 'Definite Integrals',
      url: 'https://en.wikipedia.org/wiki/Integral',
      description: 'Detailed explanation of definite integration'
    },
    {
      title: 'Fundamental Theorem of Calculus',
      url: 'https://en.wikipedia.org/wiki/Fundamental_theorem_of_calculus',
      description: 'Understanding the relationship between derivatives and integrals'
    },
    {
      title: 'Applications of Integration',
      url: 'https://en.wikipedia.org/wiki/Integral#Applications',
      description: 'Real-world applications of integration'
    }
  ]
};
