import { Calculator } from 'lucide-react';
import { CalculatorInfo } from './types';

export const standardDeviationCalculator: CalculatorInfo = {
  id: 'standard-deviation-calculator',
  title: 'Standard Deviation Calculator | Population & Sample Statistics',
  description: 'Free online standard deviation calculator for both population and sample data. Calculate mean, variance, standard deviation, and other statistical measures with step-by-step explanations.',
  icon: Calculator,
  category: 'Statistics',
  schema: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Standard Deviation Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Statistics",
    "operatingSystem": "Any",
    "description": "Calculate standard deviation, variance, mean, and other statistical measures for both population and sample data sets. Features step-by-step solutions and detailed explanations.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "2150",
      "reviewCount": "1780",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Population Standard Deviation",
      "Sample Standard Deviation",
      "Mean Calculation",
      "Variance Calculation",
      "Step-by-step Solutions",
      "Data Set Analysis",
      "Statistical Visualization",
      "Mobile Friendly Interface",
      "Free to Use",
      "Export Results"
    ],
    "mainEntity": {
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "What is standard deviation?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Standard deviation is a statistical measure that quantifies the amount of variation or dispersion in a dataset. It indicates how far a set of numbers are spread out from their average value (mean). A low standard deviation indicates that the values tend to be close to the mean, while a high standard deviation indicates that the values are spread out over a wider range."
          }
        },
        {
          "@type": "Question",
          "name": "What is the difference between population and sample standard deviation?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Population standard deviation (σ) is used when you have data for an entire population, while sample standard deviation (s) is used when you have data from only a sample of the population. The main difference in calculation is that sample standard deviation uses (n-1) in the denominator instead of N to correct for sampling bias."
          }
        },
        {
          "@type": "Question",
          "name": "When should I use standard deviation?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Standard deviation is used when you need to understand the spread of data in a dataset. It's particularly useful in: quality control, financial analysis, weather forecasting, scientific research, and any situation where you need to measure variability or consistency in data."
          }
        },
        {
          "@type": "Question",
          "name": "How do you interpret standard deviation?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "In a normal distribution: approximately 68% of data falls within one standard deviation of the mean, 95% within two standard deviations, and 99.7% within three standard deviations. A larger standard deviation indicates more spread-out data, while a smaller one indicates the data is clustered closer to the mean."
          }
        },
        {
          "@type": "Question",
          "name": "What is the relationship between variance and standard deviation?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Standard deviation is the square root of variance. While variance represents the average squared distance from the mean, standard deviation converts this back to the original units of measurement, making it more interpretable. Both measures indicate spread, but standard deviation is more commonly used because it's in the same units as the data."
          }
        }
      ]
    }
  },
  keywords: [
    'standard deviation calculator',
    'population standard deviation',
    'sample standard deviation',
    'variance calculator',
    'statistical calculator',
    'mean calculator',
    'statistics calculator',
    'data analysis tool',
    'free statistics calculator',
    'online standard deviation',
    'descriptive statistics',
    'statistical analysis',
    'data spread calculator',
    'population variance',
    'sample variance'
  ],
  formulas: [
    {
      expression: 'σ = √(Σ(x - μ)² / N)',
      explanation: 'Population standard deviation formula',
      variables: [
        { name: 'σ', description: 'Population standard deviation' },
        { name: 'x', description: 'Individual values' },
        { name: 'μ', description: 'Population mean' },
        { name: 'N', description: 'Population size' }
      ]
    },
    {
      expression: 's = √(Σ(x - x̄)² / (n-1))',
      explanation: 'Sample standard deviation formula',
      variables: [
        { name: 's', description: 'Sample standard deviation' },
        { name: 'x', description: 'Individual values' },
        { name: 'x̄', description: 'Sample mean' },
        { name: 'n', description: 'Sample size' }
      ]
    },
    {
      expression: 'μ = Σx / N',
      explanation: 'Population mean formula',
      variables: [
        { name: 'μ', description: 'Population mean' },
        { name: 'Σx', description: 'Sum of all values' },
        { name: 'N', description: 'Population size' }
      ]
    }
  ],
  features: [
    'Population standard deviation calculation',
    'Sample standard deviation calculation',
    'Mean and variance computation',
    'Step-by-step solution display',
    'Support for large datasets',
    'Statistical visualization tools',
    'Data set analysis features',
    'Mobile-friendly interface',
    'Export functionality',
    'Detailed explanations'
  ],
  faqs: [
    {
      question: 'What is standard deviation?',
      answer: 'Standard deviation is a statistical measure that quantifies the amount of variation or dispersion in a dataset. It indicates how far a set of numbers are spread out from their average value (mean). A low standard deviation indicates that the values tend to be close to the mean, while a high standard deviation indicates that the values are spread out over a wider range.'
    },
    {
      question: 'What is the difference between population and sample standard deviation?',
      answer: 'Population standard deviation (σ) is used when you have data for an entire population, while sample standard deviation (s) is used when you have data from only a sample of the population. The main difference in calculation is that sample standard deviation uses (n-1) in the denominator instead of N to correct for sampling bias.'
    },
    {
      question: 'When should I use standard deviation?',
      answer: 'Standard deviation is used when you need to understand the spread of data in a dataset. It\'s particularly useful in: quality control, financial analysis, weather forecasting, scientific research, and any situation where you need to measure variability or consistency in data.'
    },
    {
      question: 'How do you interpret standard deviation?',
      answer: 'In a normal distribution: approximately 68% of data falls within one standard deviation of the mean, 95% within two standard deviations, and 99.7% within three standard deviations. A larger standard deviation indicates more spread-out data, while a smaller one indicates the data is clustered closer to the mean.'
    },
    {
      question: 'What is the relationship between variance and standard deviation?',
      answer: 'Standard deviation is the square root of variance. While variance represents the average squared distance from the mean, standard deviation converts this back to the original units of measurement, making it more interpretable. Both measures indicate spread, but standard deviation is more commonly used because it\'s in the same units as the data.'
    }
  ],
  references: [
    {
      title: 'Standard Deviation',
      url: 'https://en.wikipedia.org/wiki/Standard_deviation',
      description: 'Comprehensive explanation of standard deviation'
    },
    {
      title: 'Statistical Dispersion',
      url: 'https://en.wikipedia.org/wiki/Statistical_dispersion',
      description: 'Understanding measures of statistical spread'
    },
    {
      title: 'Normal Distribution',
      url: 'https://en.wikipedia.org/wiki/Normal_distribution',
      description: 'Relationship between standard deviation and normal distribution'
    },
    {
      title: 'Variance',
      url: 'https://en.wikipedia.org/wiki/Variance',
      description: 'Detailed explanation of variance in statistics'
    }
  ]
};
