import { Calculator } from 'lucide-react';
import { CalculatorInfo } from '../types/CalculatorInfo';

export const graphingCalculator: CalculatorInfo = {
    id: 'graphing-calculator',
    title: "Graphing Calculator - Interactive Function Plotter & Math Visualizer",
    description: "Free online graphing calculator for plotting mathematical functions, analyzing equations, and visualizing mathematical concepts. Features multiple function types, customizable graph settings, and comprehensive mathematical tools.",
    category: "Advanced",
    icon: Calculator,
    route: '/graphing-calculator',
    
    keywords: [
        "graphing calculator",
        "function plotter",
        "math visualizer",
        "equation grapher",
        "mathematical graphing tool",
        "function visualization",
        "cartesian plotter",
        "polar graph calculator",
        "parametric equations",
        "interactive math graphs",
        "mathematical analysis",
        "educational math tool"
    ],

    schema: {
        "@type": "SoftwareApplication",
        "name": "Graphing Calculator",
        "applicationCategory": "EducationalApplication",
        "operatingSystem": "Any",
        "offers": {
            "@type": "Offer",
            "price": "0",
            "priceCurrency": "USD"
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.9",
            "ratingCount": "2250"
        },
        "featureList": [
            "Multiple Function Plotting",
            "Cartesian Coordinates",
            "Polar Coordinates",
            "Parametric Equations",
            "Customizable Graph Settings",
            "Interactive Zoom & Pan",
            "Grid & Axis Controls",
            "Function Analysis Tools",
            "Export Capabilities"
        ]
    },

    features: [
        "Plot multiple functions simultaneously",
        "Support for cartesian, polar, and parametric equations",
        "Interactive zoom and pan controls",
        "Customizable graph appearance",
        "Built-in scientific calculator",
        "Common function presets",
        "Real-time function evaluation",
        "Grid and axis customization",
        "Dark mode support",
        "Graph export functionality"
    ],

    mathFormulas: [
        {
            name: "Basic Functions",
            formula: "f(x) = x^n, sin(x), cos(x), tan(x), log(x)",
            explanation: "Standard mathematical functions for basic plotting"
        },
        {
            name: "Polynomial Functions",
            formula: "f(x) = ax^n + bx^(n-1) + ... + k",
            explanation: "Polynomial functions of any degree"
        },
        {
            name: "Trigonometric Functions",
            formula: "f(x) = A*sin(Bx + C) + D",
            explanation: "Amplitude, frequency, phase shift, and vertical shift of trigonometric functions"
        },
        {
            name: "Exponential Functions",
            formula: "f(x) = a*e^(bx) + c",
            explanation: "Exponential growth and decay functions"
        },
        {
            name: "Logarithmic Functions",
            formula: "f(x) = a*log(bx) + c",
            explanation: "Natural and common logarithmic functions"
        }
    ],

    faqs: [
        {
            question: "How do I plot a function?",
            answer: "Enter your function using standard mathematical notation (e.g., x^2 for x², sin(x) for sine). The calculator supports basic arithmetic operations (+, -, *, /), powers (^), and common mathematical functions (sin, cos, tan, log, etc.)."
        },
        {
            question: "What types of functions can I graph?",
            answer: "The calculator supports cartesian functions (y = f(x)), polar functions (r = f(θ)), parametric equations, and implicit functions. You can plot polynomials, trigonometric functions, exponentials, logarithms, and more."
        },
        {
            question: "How do I customize the graph view?",
            answer: "Use the settings panel to adjust the viewing window (xMin, xMax, yMin, yMax), toggle grid lines and axes, change colors, and modify the graph resolution. You can also zoom and pan using mouse controls or touch gestures."
        },
        {
            question: "Can I analyze function properties?",
            answer: "Yes, the calculator can help identify key features such as intercepts, maxima/minima, and inflection points. You can also trace along functions to see specific coordinate values."
        },
        {
            question: "How do I save or share my graphs?",
            answer: "Use the export button to save your graph as an image file. You can also share a link that includes your function parameters and graph settings."
        }
    ],

    references: [
        {
            title: "Mathematical Functions Reference",
            author: "Wolfram MathWorld",
            link: "https://mathworld.wolfram.com",
            description: "Comprehensive reference for mathematical functions and their properties"
        },
        {
            title: "Graphing Calculator Guide",
            author: "National Council of Teachers of Mathematics",
            link: "https://www.nctm.org",
            description: "Educational resources for graphing calculator usage"
        },
        {
            title: "Function Visualization in Mathematics",
            author: "Mathematical Association of America",
            link: "https://www.maa.org",
            description: "Best practices for mathematical visualization"
        },
        {
            title: "Advanced Graphing Techniques",
            author: "American Mathematical Society",
            link: "https://www.ams.org",
            description: "Research on mathematical visualization methods"
        }
    ],

    itemListElement: [
        {
            "@type": "ListItem",
            "position": 1,
            "name": "Scientific Calculator",
            "item": {
                "@type": "Thing",
                "id": "https://www.calculato.co/en/scientific-calculator"
            }
        },
        {
            "@type": "ListItem",
            "position": 2,
            "name": "Fraction Calculator",
            "item": {
                "@type": "Thing",
                "id": "https://www.calculato.co/en/fraction-calculator"
            }
        },
        {
            "@type": "ListItem",
            "position": 3,
            "name": "Graphing Calculator",
            "item": {
                "@type": "Thing",
                "id": "https://www.calculato.co/en/graphing-calculator"
            }
        }
    ],
};
