import { Heart } from 'lucide-react';
import { CalculatorInfo } from './types';

export const ovulationCalculator: CalculatorInfo = {
  id: 'ovulation-calculator',
  title: 'Ovulation Calculator | Free Fertility Calendar & Tracker',
  description: 'Free ovulation calculator and fertility tracker with personalized predictions. Calculate your fertile window, ovulation date, and next period using cycle length and last period date. Includes detailed cycle phase information and fertility tracking calendar.',
  icon: Heart,
  category: 'Health',
  schema: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Ovulation Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "HealthAndFitness",
    "operatingSystem": "Any",
    "description": "Track your fertility and predict ovulation with our comprehensive ovulation calculator. Features include fertile window prediction, cycle phase tracking, and personalized fertility calendar.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "featureList": [
      "Personalized ovulation date prediction",
      "Fertile window calculation",
      "Menstrual cycle phase tracking",
      "Interactive fertility calendar",
      "Period prediction"
    ]
  },
  keywords: [
    "ovulation calculator",
    "fertility calculator",
    "ovulation tracker",
    "period tracker",
    "menstrual cycle calculator",
    "fertile window calculator",
    "fertility calendar",
    "menstrual cycle phases",
    "pregnancy planning",
    "conception calculator",
    "period prediction",
    "cycle tracking",
    "fertility tracking",
    "ovulation prediction",
    "menstrual health"
  ],
  features: [
    "Calculate ovulation date based on last period and cycle length",
    "Predict fertile window for optimal conception chances",
    "Track menstrual cycle phases with detailed information",
    "View interactive fertility calendar",
    "Get personalized period predictions",
    "Learn about different cycle phases and fertility signs",
    "Export and save your fertility calendar",
    "Free and easy-to-use interface",
    "Mobile-friendly design",
    "Privacy-focused calculation"
  ],
  references: [
    "American College of Obstetricians and Gynecologists. (2021). Fertility Awareness-Based Methods of Family Planning.",
    "Mayo Clinic. (2021). Fertility awareness: Natural family planning methods.",
    "World Health Organization. (2020). Natural family planning - A guide to provision of services."
  ],
  faqs: [
    {
      question: 'How accurate is the ovulation calculator?',
      answer: 'The calculator provides estimates based on average cycle patterns. Accuracy depends on cycle regularity and accurate input of cycle length and last period date. For most women with regular cycles, predictions are within ±2-3 days. Factors affecting accuracy include: stress, illness, lifestyle changes, and hormonal variations. For highest accuracy, combine with other fertility tracking methods like basal body temperature and ovulation tests.'
    },
    {
      question: 'How is my ovulation date calculated?',
      answer: 'The ovulation date is typically calculated by counting backward 14 days from the expected start of your next period. For a 28-day cycle, ovulation usually occurs around day 14. The calculator adjusts this calculation based on your specific cycle length.'
    },
    {
      question: 'What is the fertile window?',
      answer: 'The fertile window is the period when pregnancy is possible, typically spanning 6 days: the 5 days before ovulation and the day of ovulation itself. Sperm can survive up to 5 days in the female reproductive tract, while an egg is viable for about 24 hours after ovulation.'
    }
  ]
};
