import { Calculator } from 'lucide-react';
import { CalculatorInfo } from './types';

export const quadraticCalculator: CalculatorInfo = {
  id: 'quadratic-calculator',
  title: 'Quadratic Equation Calculator | Free Online Solver',
  description: 'Free online quadratic equation calculator. Solve ax² + bx + c = 0, find roots, vertex, and graph parabolas with step-by-step solutions. Includes real and complex roots, discriminant analysis, and interactive graphing.',
  icon: Calculator,
  category: 'Academic',
  schema: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Quadratic Equation Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Math",
    "operatingSystem": "Any",
    "description": "Free online quadratic equation calculator. Solves quadratic equations, finds roots, vertex, and provides interactive graphing with step-by-step solutions.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/quadratic-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "1250",
      "reviewCount": "820",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Real and Complex Root Finding",
      "Interactive Parabola Graphing",
      "Step-by-Step Solutions",
      "Discriminant Analysis",
      "Vertex and Axis of Symmetry",
      "Mobile Friendly Design",
      "High Precision Results",
      "Free Forever",
      "No Installation Required",
      "Privacy First - No Data Collection"
    ]
  },
  formulas: [
    {
      expression: 'x = (-b ± √(b² - 4ac)) / (2a)',
      explanation: 'Quadratic formula for finding roots of ax² + bx + c = 0',
      variables: [
        { name: 'a', description: 'Coefficient of x²' },
        { name: 'b', description: 'Coefficient of x' },
        { name: 'c', description: 'Constant term' }
      ]
    },
    {
      expression: 'D = b² - 4ac',
      explanation: 'Discriminant formula to determine the nature of roots',
      variables: [
        { name: 'D', description: 'Discriminant value' },
        { name: 'b', description: 'Coefficient of x' },
        { name: 'a,c', description: 'Coefficients of x² and constant' }
      ]
    },
    {
      expression: 'x = -b/(2a)',
      explanation: 'x-coordinate of vertex and axis of symmetry',
      variables: [
        { name: 'a', description: 'Coefficient of x²' },
        { name: 'b', description: 'Coefficient of x' }
      ]
    }
  ],
  faqs: [
    {
      question: 'What is a quadratic equation?',
      answer: 'A quadratic equation is a polynomial equation of degree 2 in the form ax² + bx + c = 0, where a ≠ 0. It creates a parabola when graphed and can have 0, 1, or 2 real solutions.'
    },
    {
      question: 'How does this calculator solve quadratic equations?',
      answer: 'The calculator uses the quadratic formula (-b ± √(b² - 4ac))/(2a) to find exact solutions. It provides real and complex roots, calculates the vertex, axis of symmetry, and generates an interactive graph with step-by-step explanations.'
    },
    {
      question: 'What does the discriminant tell us?',
      answer: 'The discriminant (b² - 4ac) determines the nature of roots: if positive, there are two real roots; if zero, one repeated real root; if negative, two complex conjugate roots.'
    },
    {
      question: 'Is this calculator free to use?',
      answer: 'Yes, our quadratic equation calculator is completely free to use. You can solve unlimited equations and access all features including step-by-step solutions and graphing capabilities.'
    },
    {
      question: 'Does it work on mobile devices?',
      answer: 'Yes, the calculator is fully responsive and works perfectly on smartphones and tablets. The interface automatically adjusts to your screen size for optimal usability.'
    }
  ],
  references: [
    'Quadratic Equation Theory',
    'Quadratic Formula Derivation',
    'Complex Numbers in Algebra',
    'Parabola Properties',
    'Discriminant Analysis'
  ],
  keywords: [
    'quadratic calculator',
    'quadratic equation solver',
    'quadratic formula calculator',
    'find roots calculator',
    'parabola graphing tool',
    'complex roots calculator',
    'discriminant calculator',
    'vertex calculator',
    'axis of symmetry calculator',
    'free math calculator'
  ],
  features: [
    'Solves any quadratic equation ax² + bx + c = 0',
    'Finds real and complex roots',
    'Calculates vertex and axis of symmetry',
    'Interactive parabola graphing',
    'Step-by-step solutions',
    'Discriminant analysis',
    'Mobile-friendly interface',
    'High precision results',
    'Free to use',
    'No installation required'
  ]
};
