import { Calendar } from 'lucide-react';
import { CalculatorInfo } from '../types/CalculatorInfo';

export const dateCalculator: CalculatorInfo = {
    title: "Date Calculator - Date Difference & Duration Calculator",
    description: "Free online date calculator for computing date differences, adding/subtracting durations, calculating business days, and estimating pregnancy due dates. Features multiple time units, calendar interface, and comprehensive date analysis tools.",
    category: "Lifestyle",
    icon: Calendar,
    
    keywords: [
        "date calculator",
        "date difference calculator",
        "days between dates",
        "date duration calculator",
        "add days calculator",
        "subtract days calculator",
        "business days calculator",
        "pregnancy due date calculator",
        "date interval calculator",
        "working days calculator",
        "calendar calculator",
        "date planning tool"
    ],

    schema: {
        "@type": "SoftwareApplication",
        "name": "Date Calculator",
        "applicationCategory": "ProductivityApplication",
        "operatingSystem": "Any",
        "offers": {
            "@type": "Offer",
            "price": "0",
            "priceCurrency": "USD"
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.8",
            "ratingCount": "1680"
        },
        "featureList": [
            "Date Difference Calculation",
            "Date Addition/Subtraction",
            "Business Days Computation",
            "Pregnancy Due Date Estimation",
            "Multiple Time Units",
            "Calendar Interface",
            "Custom Date Formats",
            "Export Functionality"
        ]
    },

    features: [
        "Calculate time between two dates in multiple units",
        "Add or subtract days, weeks, months, or years",
        "Compute business days excluding weekends",
        "Estimate pregnancy due dates from LMP",
        "Interactive calendar date picker",
        "Support for multiple date formats",
        "Business day calculations",
        "Export calculations in formatted text",
        "Real-time calculation updates",
        "Comprehensive date analysis"
    ],

    mathFormulas: [
        {
            name: "Date Difference",
            formula: "Difference = End Date - Start Date",
            explanation: "Calculates the time interval between two dates in various units (days, weeks, months, years)"
        },
        {
            name: "Date Addition",
            formula: "Result Date = Start Date + Duration",
            explanation: "Adds a specified duration (in days, weeks, months, or years) to a start date"
        },
        {
            name: "Business Days",
            formula: "Business Days = Total Days - Weekend Days",
            explanation: "Calculates working days by excluding weekends from the total days"
        },
        {
            name: "Pregnancy Due Date",
            formula: "Due Date = LMP + 280 days",
            explanation: "Estimates due date by adding 280 days (40 weeks) to the Last Menstrual Period date"
        }
    ],

    faqs: [
        {
            question: "How are date differences calculated?",
            answer: "The calculator computes the exact time between two dates, providing results in multiple units (days, weeks, months, and years). It accounts for leap years and varying month lengths for accurate calculations."
        },
        {
            question: "How does the business days calculator work?",
            answer: "The business days calculator counts only Monday through Friday between two dates, excluding weekends. This is useful for project planning, delivery estimates, and work schedules."
        },
        {
            question: "How accurate is the pregnancy due date calculator?",
            answer: "The pregnancy calculator uses the standard medical calculation of adding 280 days (40 weeks) to the Last Menstrual Period (LMP) date. This provides an estimated due date, though actual delivery can vary by about two weeks."
        },
        {
            question: "How are months and years handled in calculations?",
            answer: "Month and year calculations account for varying month lengths and leap years. The calculator uses the date-fns library to ensure accurate handling of complex date arithmetic."
        },
        {
            question: "Can I calculate dates across different time zones?",
            answer: "Yes, the calculator handles dates consistently regardless of time zones. Calculations are based on calendar dates rather than specific times to avoid time zone complications."
        }
    ],

    references: [
        {
            title: "Date and Time Standards",
            author: "International Organization for Standardization",
            link: "https://www.iso.org/standard/40874.html",
            description: "ISO standards for date and time calculations"
        },
        {
            title: "Business Day Conventions",
            author: "Federal Reserve",
            link: "https://www.federalreserve.gov/holidayschedules.htm",
            description: "Standard business day definitions and holiday schedules"
        },
        {
            title: "Pregnancy Due Date Calculation",
            author: "American College of Obstetricians and Gynecologists",
            link: "https://www.acog.org",
            description: "Medical standards for pregnancy date calculations"
        },
        {
            title: "Calendar Systems",
            author: "U.S. Naval Observatory",
            link: "https://aa.usno.navy.mil/data/docs/calendars.php",
            description: "Authoritative source for calendar calculations"
        }
    ],

    itemListElement: [
        {
            "@type": "ListItem",
            "position": 1,
            "name": "Lifestyle Calculators",
            "item": {
                "@type": "Thing",
                "id": "https://calculato.co/lifestyle-calculators"
            }
        },
        {
            "@type": "ListItem",
            "position": 2,
            "name": "Time Calculator",
            "item": {
                "@type": "Thing",
                "id": "https://www.calculato.co/en/time-calculator"
            }
        },
        {
            "@type": "ListItem",
            "position": 3,
            "name": "Date Calculator",
            "item": {
                "@type": "Thing",
                "id": "https://calculato.co/date-calculator"
            }
        }
    ]
};
