import { Apple } from 'lucide-react';
import { CalculatorInfo } from './types';

export const calorieCalculator: CalculatorInfo = {
  id: 'calorie-calculator',
  title: 'Calorie Calculator | Free Daily Caloric Needs Calculator',
  description: 'Free calorie calculator that determines your daily caloric needs based on age, weight, height, gender, activity level, and goals. Get personalized recommendations for weight loss, maintenance, or muscle gain with our science-based calculator.',
  icon: Apple,
  category: 'Health',
  schema: {
    "@type": "SoftwareApplication",
    "name": "Calorie Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "HealthAndFitness",
    "operatingSystem": "Any",
    "description": "Calculate your daily caloric needs with our advanced calorie calculator. Features BMR calculation, activity level adjustment, and personalized recommendations for weight management goals.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/calorie-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "2950",
      "reviewCount": "1980",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "BMR Calculation",
      "TDEE Estimation",
      "Weight Loss Planning",
      "Muscle Gain Targets",
      "Activity Level Adjustment",
      "Macro Distribution",
      "Meal Planning Guide",
      "Exercise Calories",
      "Mobile Friendly",
      "Free Forever"
    ]
  },
  formulas: [
    {
      expression: 'BMR (Men) = 88.362 + (13.397 × weight) + (4.799 × height) - (5.677 × age)',
      explanation: 'Mifflin-St Jeor equation for Basal Metabolic Rate (men)',
      variables: [
        { name: 'weight', description: 'Weight in kilograms' },
        { name: 'height', description: 'Height in centimeters' },
        { name: 'age', description: 'Age in years' }
      ]
    },
    {
      expression: 'BMR (Women) = 447.593 + (9.247 × weight) + (3.098 × height) - (4.330 × age)',
      explanation: 'Mifflin-St Jeor equation for Basal Metabolic Rate (women)',
      variables: [
        { name: 'weight', description: 'Weight in kilograms' },
        { name: 'height', description: 'Height in centimeters' },
        { name: 'age', description: 'Age in years' }
      ]
    },
    {
      expression: 'TDEE = BMR × Activity Multiplier',
      explanation: 'Total Daily Energy Expenditure calculation',
      variables: [
        { name: 'BMR', description: 'Basal Metabolic Rate' },
        { name: 'Activity Multiplier', description: 'Factor based on activity level (1.2-1.9)' }
      ]
    },
    {
      expression: 'Weight Change Calories = TDEE ± (500-1000)',
      explanation: 'Caloric target for weight loss/gain (0.5-1kg per week)',
      variables: [
        { name: 'TDEE', description: 'Total Daily Energy Expenditure' },
        { name: '500-1000', description: 'Caloric deficit/surplus for weight change' }
      ]
    },
    {
      expression: 'Macronutrient Distribution = Calories × Macro% / Calories per gram',
      explanation: 'Calculate daily macronutrient needs',
      variables: [
        { name: 'Calories', description: 'Daily caloric target' },
        { name: 'Macro%', description: 'Percentage for each macro (P:30%, C:40%, F:30%)' },
        { name: 'Calories per gram', description: 'P:4, C:4, F:9 calories/gram' }
      ]
    }
  ],
  faqs: [
    {
      question: 'How many calories should I eat to lose weight?',
      answer: 'For safe and sustainable weight loss, create a deficit of 500-1000 calories below your TDEE. This typically results in 0.5-1kg (1-2 lbs) loss per week. Never go below 1200 calories for women or 1500 for men without medical supervision.'
    },
    {
      question: 'How does activity level affect calorie needs?',
      answer: 'Activity multipliers: Sedentary (1.2, desk job, little exercise), Light Activity (1.375, light exercise 1-3 days/week), Moderate Activity (1.55, moderate exercise 3-5 days/week), Very Active (1.725, hard exercise 6-7 days/week), Extra Active (1.9, very hard exercise & physical job).'
    },
    {
      question: 'Should I eat back exercise calories?',
      answer: 'If weight loss is your goal, it\'s generally recommended to not eat back all exercise calories. Consider eating back 50% of calories from structured exercise if you feel fatigued or very hungry. For maintenance or muscle gain, eating back exercise calories may be beneficial.'
    },
    {
      question: 'How should I distribute my macronutrients?',
      answer: 'A balanced macro distribution is typically 30% protein (4 calories/g), 40% carbohydrates (4 calories/g), and 30% fats (9 calories/g). For weight loss, consider increasing protein to 35-40%. For athletes, carbohydrates might be increased to 45-60%.'
    },
    {
      question: 'Why isn\'t the calculator showing my expected weight loss?',
      answer: 'Weight loss is influenced by many factors beyond calories, including hormones, sleep, stress, medications, and medical conditions. The calculator provides estimates based on averages, but individual results may vary. Consult a healthcare provider for personalized advice.'
    }
  ],
  references: [
    'American College of Sports Medicine Position Stand',
    'Journal of the International Society of Sports Nutrition',
    'Academy of Nutrition and Dietetics Guidelines',
    'National Institute of Health Weight Management Resources',
    'International Journal of Obesity Guidelines',
    'Sports Medicine Research Guidelines',
    'Exercise Physiology: Nutrition, Energy, and Human Performance'
  ],
  keywords: [
    'calorie calculator',
    'tdee calculator',
    'bmr calculator',
    'weight loss calculator',
    'daily calorie needs',
    'macro calculator',
    'caloric deficit calculator',
    'maintenance calories',
    'weight gain calculator',
    'free calorie calculator'
  ],
  features: [
    'Precise BMR calculation',
    'TDEE estimation with activity levels',
    'Weight loss/gain planning',
    'Macronutrient distribution',
    'Exercise calorie adjustment',
    'Multiple formula options',
    'Personalized recommendations',
    'Mobile-friendly interface',
    'No registration required',
    'Free to use'
  ],
  itemListElement: [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Health Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/health-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "BMR Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/bmr-calculator"
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Calorie Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/calorie-calculator"
      }
    }
  ]
};
