import { Beef } from 'lucide-react';
import { CalculatorInfo } from './types';

export const proteinCalculator: CalculatorInfo = {
  id: 'protein-calculator',
  title: 'Protein Calculator | Daily Protein Intake Calculator | Macro Calculator',
  description: 'Free protein calculator to determine your optimal daily protein intake based on weight, activity level, and fitness goals. Includes meal planning suggestions, protein-rich food sources, and macronutrient recommendations for muscle gain, weight loss, and athletic performance.',
  icon: Beef,
  category: 'Health',
  schema: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Protein Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "HealthAndFitness",
    "operatingSystem": "Any",
    "description": "Calculate your personalized protein requirements with our comprehensive protein intake calculator. Features include meal planning, protein source recommendations, and macronutrient breakdowns for various fitness goals.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "2750",
      "reviewCount": "1890",
      "bestRating": "5",
      "worstRating": "1"
    }
  },
  features: [
    "Personalized protein intake calculations based on weight, activity level, and goals",
    "Customizable meal planning suggestions with protein-rich food options",
    "Visual breakdown of daily protein distribution across meals",
    "Recommendations for different fitness goals (muscle gain, weight loss, maintenance)",
    "Interactive charts showing protein sources and distribution",
    "Detailed explanations of protein requirements for different activity levels",
    "BMI-adjusted protein recommendations",
    "Printable meal plans and protein intake guides"
  ],
  keywords: [
    "protein calculator",
    "daily protein intake",
    "protein requirements",
    "muscle building protein",
    "weight loss protein",
    "athlete protein needs",
    "protein macro calculator",
    "protein meal plan",
    "protein rich foods",
    "bodybuilding protein",
    "sports nutrition",
    "protein per kg bodyweight",
    "optimal protein intake",
    "protein supplements",
    "protein timing",
    "macronutrient calculator"
  ],
  references: [
    "Academy of Nutrition and Dietetics Position Stand on Protein Requirements (2016)",
    "International Society of Sports Nutrition Position Stand on Protein and Exercise (2017)",
    "Journal of the International Society of Sports Nutrition: Protein Requirements for Athletes (2018)",
    "American College of Sports Medicine Guidelines for Exercise and Protein Intake (2016)",
    "WHO/FAO/UNU Expert Consultation on Protein Requirements in Human Nutrition"
  ],
  faqs: [
    {
      question: 'How much protein do I need daily?',
      answer: 'Daily protein needs vary based on several factors: 1) General Population: 0.8g/kg body weight, 2) Active Adults: 1.6-2.2g/kg, 3) Athletes: 1.8-2.4g/kg, 4) Weight Loss: 1.8-2.4g/kg to preserve muscle, 5) Muscle Building: 1.6-2.4g/kg. These ranges should be adjusted based on activity level, goals, and overall health status.'
    },
    {
      question: 'When is the best time to consume protein?',
      answer: 'For optimal muscle protein synthesis, distribute protein intake evenly throughout the day in 3-4 meals, with 20-40g per meal. Post-workout protein intake within 2 hours can help with recovery and muscle adaptation. However, total daily protein intake is more important than timing.'
    },
    {
      question: 'What are the best sources of protein?',
      answer: 'High-quality protein sources include: 1) Lean meats (chicken, turkey, lean beef), 2) Fish (salmon, tuna, tilapia), 3) Eggs, 4) Dairy (Greek yogurt, cottage cheese), 5) Plant-based options (legumes, quinoa, tofu). Choose sources that align with your dietary preferences and restrictions.'
    },
    {
      question: 'Can I build muscle with plant-based protein?',
      answer: 'Yes, you can build muscle with plant-based protein sources. However, you may need to consume slightly more total protein as plant proteins generally have lower bioavailability. Combine different plant protein sources to ensure you get all essential amino acids.'
    },
    {
      question: 'Is it possible to consume too much protein?',
      answer: 'While protein is generally safe, excessive intake (>3.5g/kg/day) may not provide additional benefits and could stress the kidneys in people with existing kidney problems. For most healthy individuals, staying within 2.2-2.4g/kg/day is sufficient for muscle building and performance.'
    }
  ]
};
