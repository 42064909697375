import { TrendingDown } from 'lucide-react';
import { CalculatorInfo } from './types';

export const calorieDeficitCalculator: CalculatorInfo = {
  id: 'calorie-deficit-calculator',
  title: 'Calorie Deficit Calculator | Weight Loss Calculator | TDEE Calculator',
  description: 'Free calorie deficit calculator for personalized weight loss planning. Calculate your ideal caloric deficit, TDEE, BMR, and macronutrient needs. Get a customized weight loss timeline with weekly projections and nutrition recommendations.',
  icon: TrendingDown,
  category: 'Health',
  schema: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Calorie Deficit Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "HealthAndFitness",
    "operatingSystem": "Any",
    "description": "Calculate your optimal calorie deficit for healthy weight loss. Features include TDEE calculation, macronutrient planning, and personalized weight loss projections.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "featureList": [
      "Personalized calorie deficit calculation",
      "TDEE and BMR estimation",
      "Weight loss timeline projection",
      "Macronutrient recommendations",
      "Safe weight loss guidelines"
    ],
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Health Calculators",
          "item": {
            "@type": "Thing",
            "id": "https://calculato.co/health-calculators"
          }
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Calorie Calculator",
          "item": {
            "@type": "Thing",
            "id": "https://calculato.co/calorie-calculator"
          }
        }
      ]
    }
  },
  keywords: [
    "calorie deficit calculator",
    "weight loss calculator",
    "tdee calculator",
    "caloric deficit",
    "weight loss planner",
    "bmr calculator",
    "diet calculator",
    "weight loss timeline",
    "macro calculator",
    "healthy weight loss",
    "calorie calculator",
    "weight loss rate",
    "deficit planning",
    "nutrition calculator",
    "fitness goals"
  ],
  features: [
    "Calculate personalized calorie deficit based on goals",
    "Estimate TDEE (Total Daily Energy Expenditure)",
    "Calculate BMR (Basal Metabolic Rate)",
    "Generate weight loss timeline and projections",
    "Provide macronutrient recommendations",
    "Visualize weight loss progress",
    "Adjust for activity level",
    "Set realistic weight loss goals",
    "Calculate safe calorie ranges",
    "Export results and plans"
  ],
  references: [
    "American College of Sports Medicine. (2021). Guidelines for Exercise Testing and Prescription.",
    "National Institutes of Health. (2022). Healthy Weight Loss Recommendations.",
    "Academy of Nutrition and Dietetics. (2021). Position on Weight Management."
  ],
  faqs: [
    {
      question: 'What is a safe calorie deficit for weight loss?',
      answer: 'A safe calorie deficit ranges from 250-1000 calories per day, leading to weight loss of 0.25-1 kg per week. Gradual (250 cal): Slower but sustainable weight loss. Moderate (500 cal): Standard recommendation for healthy weight loss. Aggressive (750 cal): Faster results but requires careful nutrition planning. Maximum (1000 cal): Should only be attempted under medical supervision. Never go below 1200 calories for women or 1500 for men.'
    },
    {
      question: 'How is my daily calorie deficit calculated?',
      answer: 'Your daily calorie deficit is calculated by first determining your TDEE (Total Daily Energy Expenditure) using factors like weight, height, age, gender, and activity level. Then, based on your weight loss goals and timeline, a safe deficit is calculated. The calculator ensures the deficit stays within healthy limits (250-1000 calories) and maintains minimum safe daily calorie intake.'
    },
    {
      question: 'How long will it take to reach my goal weight?',
      answer: 'Weight loss timeline is calculated using the principle that 1 pound (0.45 kg) of fat equals approximately 3,500 calories. Your timeline is determined by your chosen calorie deficit and total weight loss goal. For example, a 500-calorie daily deficit typically results in 1 pound per week weight loss. The calculator provides realistic projections while ensuring safe, sustainable weight loss rates.'
    }
  ]
};
