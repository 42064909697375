import { Baby } from 'lucide-react';
import { CalculatorInfo } from './types';

export const pregnancyCalculator: CalculatorInfo = {
  id: 'pregnancy-calculator',
  title: 'Pregnancy Calculator | Due Date Calculator | Free Pregnancy Week Calculator',
  description: 'Free pregnancy calculator with multiple calculation methods (LMP, conception date, ultrasound). Get accurate due date predictions, weekly pregnancy timeline, trimester breakdowns, and important pregnancy milestones. Includes personalized pregnancy calendar and development tracking.',
  icon: Baby,
  category: 'Health',
  schema: {
    "@type": "SoftwareApplication",
    "name": "Pregnancy Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "HealthAndFitness",
    "operatingSystem": "Any",
    "description": "Calculate your pregnancy due date and track important milestones with our comprehensive pregnancy calculator. Features multiple calculation methods, weekly development tracking, and detailed pregnancy timeline.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/pregnancy-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "3250",
      "reviewCount": "2180",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Multiple Calculation Methods",
      "Weekly Development Tracking",
      "Trimester Timeline",
      "Important Milestone Alerts",
      "Pregnancy Calendar",
      "Medical Term Definitions",
      "Mobile-Friendly Interface",
      "Free Pregnancy Resources",
      "Print-Ready Reports",
      "Regular Updates"
    ]
  },
  formulas: [
    {
      expression: 'Due Date (LMP) = LMP + 280 days',
      explanation: 'Naegele\'s rule: Calculate due date by adding 280 days (40 weeks) to the first day of the last menstrual period',
      variables: [
        { name: 'LMP', description: 'First day of Last Menstrual Period' }
      ]
    },
    {
      expression: 'Due Date (Conception) = Conception Date + 266 days',
      explanation: 'Calculate due date by adding 266 days (38 weeks) to the conception date',
      variables: [
        { name: 'Conception Date', description: 'Known or estimated date of conception' }
      ]
    },
    {
      expression: 'Gestational Age = (Current Date - LMP) / 7',
      explanation: 'Calculate current pregnancy week by finding the difference between today and LMP in weeks',
      variables: [
        { name: 'Current Date', description: 'Today\'s date' },
        { name: 'LMP', description: 'First day of Last Menstrual Period' }
      ]
    },
    {
      expression: 'Adjusted Due Date (Ultrasound) = Ultrasound Date + ((40 - Measured Weeks) × 7)',
      explanation: 'Calculate due date based on ultrasound measurements, adjusting for the difference between measured and expected gestational age',
      variables: [
        { name: 'Ultrasound Date', description: 'Date of ultrasound scan' },
        { name: 'Measured Weeks', description: 'Gestational age measured by ultrasound' }
      ]
    }
  ],
  faqs: [
    {
      question: 'How accurate is the pregnancy calculator?',
      answer: 'Accuracy varies by calculation method: Ultrasound dating (most accurate, ±5-7 days in first trimester), LMP method (±10-14 days), Conception date (±10-14 days). Early ultrasound provides the most accurate due date prediction. Remember that only 5% of babies are born on their exact due date, with 90% born within two weeks before or after.'
    },
    {
      question: 'Which calculation method should I use?',
      answer: 'Choose based on available information: 1) Ultrasound dating (most accurate, especially before 20 weeks), 2) LMP method (if you have regular cycles and know your last period), 3) Conception date (if known). If multiple methods are available, ultrasound dating before 20 weeks typically takes precedence.'
    },
    {
      question: 'What are the pregnancy trimesters?',
      answer: 'Pregnancy is divided into three trimesters: First (weeks 1-12): Major organ development, highest risk period. Second (weeks 13-26): Rapid growth, gender determination, movement felt. Third (weeks 27-40): Final growth, preparation for birth, lung maturation. Each trimester has distinct developmental milestones and maternal changes.'
    },
    {
      question: 'When should I schedule prenatal appointments?',
      answer: 'Typical schedule: Weeks 8-12: First visit and dating scan. Weeks 11-13: First trimester screening. Week 20: Anatomy scan. Weeks 24-28: Glucose test. After 28 weeks: Every 2-3 weeks. After 36 weeks: Weekly visits. High-risk pregnancies may require more frequent monitoring.'
    },
    {
      question: 'What affects the accuracy of the due date?',
      answer: 'Several factors affect due date accuracy: 1) Cycle irregularity, 2) Late ovulation, 3) Accuracy of LMP recall, 4) Timing of ultrasound, 5) Multiple pregnancies. First trimester ultrasound provides the most accurate dating, while calculations based on LMP assume a regular 28-day cycle.'
    }
  ],
  references: [
    'American College of Obstetricians and Gynecologists (ACOG) Guidelines',
    'World Health Organization (WHO) Pregnancy Guidelines',
    'National Institutes of Health (NIH) Pregnancy Research',
    'Royal College of Obstetricians and Gynaecologists (RCOG) Standards',
    'Society for Maternal-Fetal Medicine Guidelines',
    'Naegele\'s Rule for Pregnancy Dating',
    'Ultrasound in Obstetrics & Gynecology Journal',
    'Maternal-Fetal Medicine Studies'
  ],
  keywords: [
    'pregnancy calculator',
    'due date calculator',
    'pregnancy due date',
    'gestational age calculator',
    'pregnancy week calculator',
    'conception date calculator',
    'pregnancy timeline',
    'free pregnancy calculator',
    'ultrasound dating calculator',
    'pregnancy milestone tracker'
  ],
  features: [
    'Multiple calculation methods (LMP, Conception, Ultrasound)',
    'Weekly development tracking',
    'Trimester breakdown',
    'Important milestone alerts',
    'Pregnancy calendar',
    'Development visualization',
    'Print-ready reports',
    'Mobile-friendly interface',
    'Medical term glossary',
    'Free pregnancy resources'
  ],
  itemListElement: [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Health Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/health-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Pregnancy Tools",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/pregnancy-tools"
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Pregnancy Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/pregnancy-calculator"
      }
    }
  ]
};
