import { Calculator } from 'lucide-react';
import { CalculatorInfo } from './types';

export const roundingCalculator: CalculatorInfo = {
  id: 'rounding-calculator',
  title: 'Rounding Calculator | Round Numbers & Decimals Online',
  description: 'Free online rounding calculator for numbers and decimals. Round to any decimal place, significant figures, or nearest whole number. Perfect for math, science, and engineering calculations.',
  icon: Calculator,
  category: 'Arithmetic',
  schema: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Rounding Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Arithmetic",
    "operatingSystem": "Any",
    "description": "Advanced rounding calculator supporting decimal places, significant figures, and various rounding methods. Features step-by-step explanations and multiple rounding options.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "1850",
      "reviewCount": "1520",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Decimal Place Rounding",
      "Significant Figures",
      "Multiple Rounding Methods",
      "Step-by-step Solutions",
      "Scientific Notation",
      "Engineering Notation",
      "Mobile Friendly Interface",
      "Free to Use",
      "Export Results",
      "Detailed Explanations"
    ],
    "mainEntity": {
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "What is rounding?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Rounding is the process of reducing the number of digits in a number while keeping its value close to the original. It's used to make numbers easier to work with or to match a required level of precision. Common methods include rounding to decimal places, significant figures, or nearest whole numbers."
          }
        },
        {
          "@type": "Question",
          "name": "What are significant figures?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Significant figures (sig figs) are the digits in a number that carry meaningful value. They include all certain digits and the first uncertain digit. For example, 123.45 has 5 significant figures. Leading zeros are not significant, but trailing zeros after a decimal point are."
          }
        },
        {
          "@type": "Question",
          "name": "How do you round to decimal places?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "To round to decimal places: 1) Identify the desired decimal place, 2) Look at the next digit to the right, 3) If it's 5 or greater, round up; if less than 5, round down. For example, 3.14159 rounded to 2 decimal places is 3.14."
          }
        },
        {
          "@type": "Question",
          "name": "What is scientific notation?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Scientific notation expresses numbers in the form a × 10^n, where 1 ≤ |a| < 10 and n is an integer. For example, 1234 becomes 1.234 × 10^3. It's useful for very large or very small numbers and maintaining significant figures."
          }
        },
        {
          "@type": "Question",
          "name": "What is engineering notation?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Engineering notation is similar to scientific notation but uses powers of 10 that are multiples of 3. This aligns with common prefixes like kilo (10^3), mega (10^6), milli (10^-3). For example, 1234 becomes 1.234 × 10^3 in both notations."
          }
        }
      ]
    }
  },
  keywords: [
    'rounding calculator',
    'decimal rounding',
    'significant figures calculator',
    'round numbers online',
    'decimal place calculator',
    'scientific notation',
    'engineering notation',
    'number rounding tool',
    'free rounding calculator',
    'round to decimal places',
    'round to sig figs',
    'math rounding tool',
    'precision calculator',
    'round up calculator',
    'round down calculator'
  ],
  formulas: [
    {
      expression: 'round(x, n)',
      explanation: 'Round number x to n decimal places',
      variables: [
        { name: 'x', description: 'Number to round' },
        { name: 'n', description: 'Decimal places' }
      ]
    },
    {
      expression: 'sigfig(x, n)',
      explanation: 'Round number x to n significant figures',
      variables: [
        { name: 'x', description: 'Number to round' },
        { name: 'n', description: 'Significant figures' }
      ]
    },
    {
      expression: 'ceil(x)',
      explanation: 'Round up to nearest integer',
      variables: [
        { name: 'x', description: 'Number to round up' }
      ]
    }
  ],
  features: [
    'Decimal place rounding',
    'Significant figure rounding',
    'Multiple rounding methods',
    'Scientific notation support',
    'Engineering notation',
    'Step-by-step explanations',
    'Custom precision options',
    'Mobile-friendly interface',
    'Export functionality',
    'Detailed examples'
  ],
  faqs: [
    {
      question: 'What is rounding?',
      answer: 'Rounding is the process of reducing the number of digits in a number while keeping its value close to the original. It\'s used to make numbers easier to work with or to match a required level of precision. Common methods include rounding to decimal places, significant figures, or nearest whole numbers.'
    },
    {
      question: 'What are significant figures?',
      answer: 'Significant figures (sig figs) are the digits in a number that carry meaningful value. They include all certain digits and the first uncertain digit. For example, 123.45 has 5 significant figures. Leading zeros are not significant, but trailing zeros after a decimal point are.'
    },
    {
      question: 'How do you round to decimal places?',
      answer: 'To round to decimal places: 1) Identify the desired decimal place, 2) Look at the next digit to the right, 3) If it\'s 5 or greater, round up; if less than 5, round down. For example, 3.14159 rounded to 2 decimal places is 3.14.'
    },
    {
      question: 'What is scientific notation?',
      answer: 'Scientific notation expresses numbers in the form a × 10^n, where 1 ≤ |a| < 10 and n is an integer. For example, 1234 becomes 1.234 × 10^3. It\'s useful for very large or very small numbers and maintaining significant figures.'
    },
    {
      question: 'What is engineering notation?',
      answer: 'Engineering notation is similar to scientific notation but uses powers of 10 that are multiples of 3. This aligns with common prefixes like kilo (10^3), mega (10^6), milli (10^-3). For example, 1234 becomes 1.234 × 10^3 in both notations.'
    }
  ],
  references: [
    {
      title: 'Rounding Numbers',
      url: 'https://en.wikipedia.org/wiki/Rounding',
      description: 'Comprehensive guide to number rounding'
    },
    {
      title: 'Significant Figures',
      url: 'https://en.wikipedia.org/wiki/Significant_figures',
      description: 'Understanding significant digits in calculations'
    },
    {
      title: 'Scientific Notation',
      url: 'https://en.wikipedia.org/wiki/Scientific_notation',
      description: 'Explanation of scientific notation format'
    },
    {
      title: 'Engineering Notation',
      url: 'https://en.wikipedia.org/wiki/Engineering_notation',
      description: 'Understanding engineering notation and prefixes'
    }
  ]
};
