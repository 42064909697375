import { Brain } from 'lucide-react';
import { CalculatorInfo } from './types';

export const aiFitnessCalculator: CalculatorInfo = {
  id: 'ai-fitness-calculator',
  title: 'AI Fitness Calculator | Smart Health & Fitness Planner',
  description: 'Free AI-powered fitness calculator for personalized health analysis. Get smart workout plans, custom meal recommendations, and comprehensive health metrics using advanced artificial intelligence. Includes BMI, TDEE, and macro calculations.',
  icon: Brain,
  category: 'Health',
  schema: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "AI Fitness Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "HealthAndFitness",
    "operatingSystem": "Any",
    "description": "Advanced AI-powered fitness calculator providing personalized health analysis, workout plans, and nutrition recommendations. Features comprehensive health metrics and customized fitness planning.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/ai-fitness-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "2760",
      "reviewCount": "1840",
      "bestRating": "5",
      "worstRating": "1"
    }
  },
  formulas: [
    {
      expression: 'BMI = weight(kg) / (height(m))²',
      explanation: 'Calculate Body Mass Index using weight and height',
      variables: [
        { name: 'weight', description: 'Body weight in kilograms' },
        { name: 'height', description: 'Height in meters' }
      ]
    },
    {
      expression: 'BMR = 10 × weight(kg) + 6.25 × height(cm) - 5 × age ± s',
      explanation: 'Calculate Basal Metabolic Rate using Mifflin-St Jeor equation',
      variables: [
        { name: 'weight', description: 'Body weight in kilograms' },
        { name: 'height', description: 'Height in centimeters' },
        { name: 'age', description: 'Age in years' },
        { name: 's', description: '+5 for males, -161 for females' }
      ]
    },
    {
      expression: 'TDEE = BMR × Activity Factor',
      explanation: 'Calculate Total Daily Energy Expenditure',
      variables: [
        { name: 'BMR', description: 'Basal Metabolic Rate' },
        { name: 'Activity Factor', description: 'Multiplier based on activity level (1.2-1.9)' }
      ]
    },
    {
      expression: 'Body Fat % (Men) = 86.010 × log10(waist - neck) - 70.041 × log10(height) + 36.76',
      explanation: 'Calculate body fat percentage for men using Navy method',
      variables: [
        { name: 'waist', description: 'Waist circumference in cm' },
        { name: 'neck', description: 'Neck circumference in cm' },
        { name: 'height', description: 'Height in cm' }
      ]
    },
    {
      expression: 'Macros (g) = Daily Calories × Macro % / calories per gram',
      explanation: 'Calculate macronutrient requirements',
      variables: [
        { name: 'Daily Calories', description: 'Total daily calorie target' },
        { name: 'Macro %', description: 'Percentage of each macro (P:30%, C:40%, F:30%)' },
        { name: 'calories per gram', description: 'P:4, C:4, F:9 calories/gram' }
      ]
    }
  ],
  faqs: [
    {
      question: "How does the AI fitness calculator work?",
      answer: "The AI fitness calculator uses advanced machine learning algorithms to analyze your personal data including age, gender, weight, height, activity level, and fitness goals. It processes this information through multiple validated fitness models to generate personalized recommendations. The calculator combines principles from sports science, nutrition, and exercise physiology to create comprehensive health analysis, custom workout plans, and tailored meal recommendations."
    },
    {
      question: "How accurate are the AI-generated fitness recommendations?",
      answer: "The recommendations are highly accurate as they are based on established scientific principles and guidelines from organizations like WHO and ACSM. The AI system uses validated formulas for BMI, TDEE, and macro calculations, while incorporating modern machine learning techniques to personalize recommendations. However, they should be used as general guidance and can be adjusted based on individual response and professional medical advice."
    },
    {
      question: "What types of workout plans does it generate?",
      answer: "The calculator generates various types of workout plans based on your goals, fitness level, and available equipment. This includes strength training, cardiovascular exercise, flexibility work, and high-intensity interval training (HIIT). Each plan is structured with specific exercises, sets, repetitions, and progression schemes, following established exercise science principles for optimal results."
    },
    {
      question: "How are the meal plans customized?",
      answer: "Meal plans are customized based on several factors: your calculated daily caloric needs, macronutrient requirements, dietary preferences (such as vegetarian or balanced), food allergies or restrictions, and your specific fitness goals. The AI considers nutritional guidelines from sports nutrition research to ensure balanced, sustainable meal recommendations."
    },
    {
      question: "How often should I update my fitness plan?",
      answer: "It's recommended to update your plan every 4-6 weeks, which aligns with typical adaptation periods in exercise science. You should also update when you experience significant changes in weight (±5%), fitness level, goals, or lifestyle factors. Regular updates help optimize your progress and ensure the recommendations remain aligned with your current needs."
    },
    {
      question: "Can I track my progress with the calculator?",
      answer: "Yes, the calculator includes progress tracking features that monitor key metrics like weight changes, strength improvements, and body composition changes. You can generate detailed progress reports, compare results over time, and receive AI-driven insights about your fitness journey. This data helps in making informed adjustments to your workout and nutrition plans."
    },
    {
      question: "Is the calculator suitable for beginners?",
      answer: "Yes, the calculator is designed for users of all fitness levels, including beginners. It takes into account your current fitness level and experience when generating recommendations. For beginners, it provides more detailed explanations, focuses on proper form and technique, and implements appropriate progression schemes to prevent overtraining and injury."
    }
  ],
  references: [
    "World Health Organization (WHO) - Physical activity guidelines",
    "American College of Sports Medicine (ACSM) - Exercise testing and prescription",
    "National Academy of Sports Medicine (NASM) - Optimum performance training model",
    "Journal of Sports Science & Medicine - AI applications in fitness and health",
    "International Journal of Environmental Research and Public Health - Digital health interventions",
    "Medicine & Science in Sports & Exercise - Exercise prescription guidelines",
    "Journal of the International Society of Sports Nutrition - Nutrition recommendations"
  ],
  keywords: [
    "AI fitness calculator",
    "smart workout planner",
    "personalized meal plan",
    "health analysis calculator",
    "BMI calculator",
    "TDEE calculator",
    "macro calculator",
    "fitness goal planner",
    "AI health assistant",
    "workout generator",
    "diet planner",
    "body composition analyzer",
    "fitness tracking tool",
    "health metrics calculator",
    "AI nutrition planner"
  ],
  features: [
    "AI-powered health analysis and recommendations",
    "Personalized workout plan generation",
    "Custom meal planning based on goals",
    "BMI and body composition analysis",
    "TDEE (Total Daily Energy Expenditure) calculation",
    "Macro-nutrient breakdown and recommendations",
    "Activity level assessment",
    "Goal-based fitness planning",
    "Dietary preference consideration",
    "PDF report generation",
    "Progress tracking capabilities",
    "Real-time health insights"
  ],
  itemListElement: [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Health Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/health-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "AI Fitness Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/ai-fitness-calculator"
      }
    }
  ]
};
