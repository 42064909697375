import { Calculator } from 'lucide-react';
import { CalculatorInfo } from './types';

export const derivativeCalculator: CalculatorInfo = {
  id: 'derivative-calculator',
  title: 'Derivative Calculator | Free Online Differentiation Tool',
  description: 'Free online derivative calculator with step-by-step solutions. Handles power rule, chain rule, product rule, and quotient rule. Perfect for calculus students and mathematicians.',
  icon: Calculator,
  category: 'Calculus',
  schema: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Derivative Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Calculus",
    "operatingSystem": "Any",
    "description": "Advanced derivative calculator supporting multiple differentiation rules, higher-order derivatives, and step-by-step solutions. Features symbolic differentiation and detailed explanations.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "3150",
      "reviewCount": "2780",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Step-by-step Solutions",
      "Multiple Differentiation Rules",
      "Higher-order Derivatives",
      "Symbolic Differentiation",
      "Common Functions Support",
      "Interactive Step Visualization",
      "Export Solutions",
      "Mobile Friendly Interface",
      "Free to Use",
      "Export Results"
    ],
    "mainEntity": {
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "What is a derivative?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "A derivative measures the rate of change of a function at any given point. It represents the slope of the tangent line to the function's graph at that point. Derivatives are fundamental in calculus and have applications in physics, engineering, and economics."
          }
        },
        {
          "@type": "Question",
          "name": "What are the basic rules of differentiation?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "The basic rules include: 1) Power Rule: d/dx(x^n) = nx^(n-1), 2) Product Rule: d/dx(uv) = u'v + uv', 3) Chain Rule: d/dx(f(g(x))) = f'(g(x))g'(x), 4) Quotient Rule: d/dx(u/v) = (u'v - uv')/v^2."
          }
        },
        {
          "@type": "Question",
          "name": "How do you find higher-order derivatives?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Higher-order derivatives are found by repeatedly applying differentiation rules. The second derivative is the derivative of the first derivative, the third is the derivative of the second, and so on. Our calculator can compute derivatives up to any order."
          }
        },
        {
          "@type": "Question",
          "name": "What is the chain rule?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "The chain rule is used to differentiate composite functions. If y = f(u) where u = g(x), then dy/dx = f'(u) × du/dx. For example, d/dx(sin(x²)) = 2x × cos(x²) using the chain rule."
          }
        },
        {
          "@type": "Question",
          "name": "How do you interpret the derivative result?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "The derivative f'(x) represents: 1) The instantaneous rate of change of f(x), 2) The slope of the tangent line at any point, 3) The velocity when f(x) represents position. The value can be positive (increasing), negative (decreasing), or zero (local max/min)."
          }
        }
      ]
    }
  },
  keywords: [
    'derivative calculator',
    'calculus calculator',
    'differentiation calculator',
    'find derivative online',
    'derivative solver',
    'chain rule calculator',
    'product rule calculator',
    'quotient rule calculator',
    'power rule calculator',
    'free derivative calculator',
    'step by step derivative',
    'online calculus tool',
    'symbolic differentiation',
    'higher order derivatives',
    'math derivative solver'
  ],
  formulas: [
    {
      expression: 'd/dx(x^n) = nx^(n-1)',
      explanation: 'Power Rule for derivatives',
      variables: [
        { name: 'n', description: 'Power of x' },
        { name: 'x', description: 'Variable' }
      ]
    },
    {
      expression: 'd/dx(uv) = u(dv/dx) + v(du/dx)',
      explanation: 'Product Rule for derivatives',
      variables: [
        { name: 'u,v', description: 'Functions' },
        { name: 'du/dx,dv/dx', description: 'Derivatives' }
      ]
    },
    {
      expression: 'd/dx(u/v) = (v(du/dx) - u(dv/dx))/v²',
      explanation: 'Quotient Rule for derivatives',
      variables: [
        { name: 'u,v', description: 'Functions' },
        { name: 'du/dx,dv/dx', description: 'Derivatives' }
      ]
    }
  ],
  features: [
    'Step-by-step derivative solutions',
    'Multiple differentiation rules support',
    'Higher-order derivatives calculation',
    'Symbolic differentiation',
    'Common functions support',
    'Interactive step visualization',
    'Solution export functionality',
    'Mobile-friendly interface',
    'Detailed explanations',
    'Error handling'
  ],
  faqs: [
    {
      question: 'What is a derivative?',
      answer: 'A derivative measures the rate of change of a function at any given point. It represents the slope of the tangent line to the function\'s graph at that point. Derivatives are fundamental in calculus and have applications in physics, engineering, and economics.'
    },
    {
      question: 'What are the basic rules of differentiation?',
      answer: 'The basic rules include: 1) Power Rule: d/dx(x^n) = nx^(n-1), 2) Product Rule: d/dx(uv) = u\'v + uv\', 3) Chain Rule: d/dx(f(g(x))) = f\'(g(x))g\'(x), 4) Quotient Rule: d/dx(u/v) = (u\'v - uv\')/v^2.'
    },
    {
      question: 'How do you find higher-order derivatives?',
      answer: 'Higher-order derivatives are found by repeatedly applying differentiation rules. The second derivative is the derivative of the first derivative, the third is the derivative of the second, and so on. Our calculator can compute derivatives up to any order.'
    },
    {
      question: 'What is the chain rule?',
      answer: 'The chain rule is used to differentiate composite functions. If y = f(u) where u = g(x), then dy/dx = f\'(u) × du/dx. For example, d/dx(sin(x²)) = 2x × cos(x²) using the chain rule.'
    },
    {
      question: 'How do you interpret the derivative result?',
      answer: 'The derivative f\'(x) represents: 1) The instantaneous rate of change of f(x), 2) The slope of the tangent line at any point, 3) The velocity when f(x) represents position. The value can be positive (increasing), negative (decreasing), or zero (local max/min).'
    }
  ],
  references: [
    {
      title: 'Derivative Rules',
      url: 'https://en.wikipedia.org/wiki/Differentiation_rules',
      description: 'Comprehensive guide to differentiation rules'
    },
    {
      title: 'Chain Rule',
      url: 'https://en.wikipedia.org/wiki/Chain_rule',
      description: 'Detailed explanation of the chain rule'
    },
    {
      title: 'Product Rule',
      url: 'https://en.wikipedia.org/wiki/Product_rule',
      description: 'Understanding the product rule'
    },
    {
      title: 'Higher Derivatives',
      url: 'https://en.wikipedia.org/wiki/Higher-order_derivative',
      description: 'Explanation of higher-order derivatives'
    }
  ]
};
