import { Home } from 'lucide-react';
import { CalculatorInfo } from './types';

export const mortgageCalculator: CalculatorInfo = {
  id: 'mortgage-calculator',
  title: 'Mortgage Calculator | Free Home Loan Payment Calculator',
  description: 'Free mortgage calculator with comprehensive payment analysis. Calculate monthly payments, compare loan terms, analyze amortization schedules, and estimate total costs including property tax, insurance, and PMI. Essential for home buyers and refinancing.',
  icon: Home,
  category: 'Financial',
  schema: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Mortgage Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Finance",
    "operatingSystem": "Any",
    "description": "Professional mortgage calculator with comprehensive payment analysis, amortization schedules, and total cost breakdowns. Essential for home buying decisions and refinancing analysis.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/mortgage-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "3240",
      "reviewCount": "2180",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Monthly Payment Calculator",
      "Amortization Schedule",
      "Total Cost Analysis",
      "Property Tax Integration",
      "PMI Calculator",
      "Insurance Cost Estimator",
      "Loan Comparison Tools",
      "Payment Breakdown Charts",
      "Mobile Friendly Design",
      "Free Forever"
    ]
  },
  formulas: [
    {
      expression: 'M = P[r(1 + r)^n]/[(1 + r)^n - 1]',
      explanation: 'Calculate monthly mortgage payment including principal and interest',
      variables: [
        { name: 'M', description: 'Monthly payment amount' },
        { name: 'P', description: 'Principal loan amount' },
        { name: 'r', description: 'Monthly interest rate (annual rate ÷ 12)' },
        { name: 'n', description: 'Total number of months (years × 12)' }
      ]
    },
    {
      expression: 'Total Interest = (M × n) - P',
      explanation: 'Calculate the total interest paid over the life of the loan',
      variables: [
        { name: 'M', description: 'Monthly payment' },
        { name: 'n', description: 'Total number of payments' },
        { name: 'P', description: 'Principal loan amount' }
      ]
    },
    {
      expression: 'PMI = (Loan Amount × Annual PMI Rate) / 12',
      explanation: 'Calculate monthly Private Mortgage Insurance premium',
      variables: [
        { name: 'Loan Amount', description: 'Current principal balance' },
        { name: 'Annual PMI Rate', description: 'Annual PMI rate (typically 0.5% to 1%)' }
      ]
    },
    {
      expression: 'Total Monthly Payment = P&I + Tax + Insurance + PMI',
      explanation: 'Calculate total monthly payment including all costs',
      variables: [
        { name: 'P&I', description: 'Principal and interest payment' },
        { name: 'Tax', description: 'Monthly property tax' },
        { name: 'Insurance', description: 'Monthly homeowners insurance' },
        { name: 'PMI', description: 'Private Mortgage Insurance (if applicable)' }
      ]
    },
    {
      expression: 'LTV = (Loan Amount / Home Value) × 100',
      explanation: 'Calculate Loan-to-Value ratio to determine PMI requirement',
      variables: [
        { name: 'Loan Amount', description: 'Mortgage amount' },
        { name: 'Home Value', description: 'Appraised home value' }
      ]
    }
  ],
  faqs: [
    {
      question: 'What factors affect my mortgage payment?',
      answer: 'Your mortgage payment is primarily affected by the loan amount (principal), interest rate, loan term, and whether you include property taxes and insurance (PITI). Additional factors include PMI if your down payment is less than 20%, HOA fees, and any points or mortgage insurance.'
    },
    {
      question: 'Should I include property tax and insurance?',
      answer: 'Yes, it\'s recommended to include property tax and insurance in your calculations for a more accurate monthly payment estimate. These are often collected by the lender as part of your monthly payment and held in an escrow account.'
    },
    {
      question: 'What is amortization?',
      answer: 'Amortization is the process of paying off your mortgage through regular payments. Early in the loan, most of your payment goes to interest. As time goes on, more goes to principal. Our amortization schedule shows this breakdown month by month.'
    },
    {
      question: 'When is PMI required?',
      answer: 'Private Mortgage Insurance (PMI) is typically required when your down payment is less than 20% of the home\'s value. PMI can be removed once you reach 20% equity in your home through payments or appreciation.'
    },
    {
      question: 'How do extra payments affect my mortgage?',
      answer: 'Making extra payments reduces your principal balance faster, which decreases the total interest paid and shortens your loan term. Our calculator can show you the impact of additional monthly or annual payments.'
    }
  ],
  references: [
    'Federal Reserve Mortgage Calculator Guidelines',
    'Consumer Financial Protection Bureau Mortgage Resources',
    'FHA Loan Requirements',
    'PMI Guidelines and Regulations',
    'Property Tax Assessment Standards'
  ],
  keywords: [
    'mortgage calculator',
    'home loan calculator',
    'house payment calculator',
    'mortgage payment calculator',
    'amortization calculator',
    'pmi calculator',
    'property tax calculator',
    'mortgage comparison calculator',
    'refinance calculator',
    'free mortgage calculator'
  ],
  features: [
    'Monthly payment calculation',
    'Amortization schedule',
    'Total cost analysis',
    'Property tax integration',
    'PMI calculator',
    'Insurance cost estimator',
    'Loan comparison tools',
    'Payment breakdown charts',
    'Mobile-responsive design',
    'High-precision calculations'
  ],
  itemListElement: [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Financial Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/financial-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Loan Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://www.calculato.co/loan-calculator"
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Loan Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://www.calculato.co/en/loan-calculator"
      }
    }
  ]
};
