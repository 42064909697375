import { Calculator } from 'lucide-react';
import { CalculatorInfo } from './types';

export const amortizationCalculator: CalculatorInfo = {
  id: 'amortization-calculator',
  title: 'Amortization Calculator | Free Loan Payment Schedule Calculator',
  description: 'Free amortization calculator with detailed payment schedules. Analyze loan payments, track principal and interest breakdown, and visualize your loan amortization schedule with interactive charts and downloadable reports.',
  icon: Calculator,
  category: 'Financial',
  schema: {
    "@type": "SoftwareApplication",
    "name": "Amortization Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Finance",
    "operatingSystem": "Any",
    "description": "Professional amortization schedule calculator with payment breakdowns, principal and interest analysis, and visual payment tracking. Essential for understanding loan repayment structure and planning.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/amortization-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "2180",
      "reviewCount": "1320",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Detailed Payment Schedule",
      "Principal vs Interest Breakdown",
      "Interactive Payment Charts",
      "Downloadable Reports",
      "Early Payment Analysis",
      "Total Interest Calculator",
      "Remaining Balance Tracking",
      "Payment Distribution Charts",
      "Mobile Friendly Design",
      "Free Forever"
    ]
  },
  formulas: [
    {
      expression: 'PMT = (P × r × (1 + r)^n) / ((1 + r)^n - 1)',
      explanation: 'Calculate monthly payment where P is principal, r is monthly interest rate, and n is total number of payments',
      variables: [
        { name: 'P', description: 'Loan principal amount' },
        { name: 'r', description: 'Monthly interest rate (annual rate / 12)' },
        { name: 'n', description: 'Total number of payments' },
        { name: 'PMT', description: 'Monthly payment amount' }
      ]
    },
    {
      expression: 'Interest_t = B_t × r',
      explanation: 'Calculate interest portion of payment where B_t is remaining balance at time t',
      variables: [
        { name: 'B_t', description: 'Remaining balance at payment t' },
        { name: 'r', description: 'Monthly interest rate' }
      ]
    },
    {
      expression: 'Principal_t = PMT - Interest_t',
      explanation: 'Calculate principal portion of payment',
      variables: [
        { name: 'PMT', description: 'Monthly payment amount' },
        { name: 'Interest_t', description: 'Interest portion of payment' }
      ]
    },
    {
      expression: 'Balance_t = Balance_(t-1) - Principal_t',
      explanation: 'Calculate remaining balance after each payment',
      variables: [
        { name: 'Balance_t', description: 'Remaining balance after payment t' },
        { name: 'Principal_t', description: 'Principal portion of payment t' }
      ]
    }
  ],
  faqs: [
    {
      question: 'What is loan amortization?',
      answer: 'Loan amortization is the process of paying off a loan with regular payments, where each payment includes both principal and interest. Our calculator shows you exactly how each payment is split between principal and interest over the life of the loan.'
    },
    {
      question: 'How do I read an amortization schedule?',
      answer: 'The amortization schedule shows your monthly payment broken down into principal and interest portions. It also tracks your remaining balance and total interest paid. Our interactive charts help visualize this breakdown over time.'
    },
    {
      question: 'Why does more interest get paid at the start?',
      answer: 'In the early stages of a loan, more of each payment goes to interest because the outstanding balance is higher. As you continue making payments, more goes to principal because the balance (and thus the interest) decreases.'
    },
    {
      question: 'Can I see how extra payments affect my loan?',
      answer: 'Yes, you can analyze how making extra payments or paying more than the minimum affects your loan term and total interest paid. The calculator will show you the updated amortization schedule.'
    },
    {
      question: 'Can I download my amortization schedule?',
      answer: 'Yes, you can download a detailed amortization schedule showing all payments, including principal and interest breakdowns, remaining balance, and cumulative interest paid.'
    }
  ],
  references: [
    'Loan Amortization Principles',
    'Financial Mathematics Guidelines',
    'Interest Rate Calculations',
    'Mortgage Payment Standards',
    'Consumer Financial Protection Guidelines'
  ],
  keywords: [
    'amortization calculator',
    'loan payment calculator',
    'loan amortization schedule',
    'payment breakdown calculator',
    'principal interest calculator',
    'mortgage amortization',
    'loan payoff calculator',
    'payment schedule calculator',
    'loan interest calculator',
    'free amortization calculator'
  ],
  features: [
    'Detailed payment schedules',
    'Principal vs interest breakdown',
    'Interactive payment charts',
    'Downloadable reports',
    'Early payment analysis',
    'Total interest calculator',
    'Balance tracking',
    'Payment distribution charts',
    'Mobile-responsive design',
    'High-precision calculations'
  ],
  itemListElement: [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Financial Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/financial-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Loan Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/loan-calculator"
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Amortization Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/amortization-calculator"
      }
    }
  ]
};
