import { Activity } from 'lucide-react';
import { CalculatorInfo } from './types';

export const bmrCalculator: CalculatorInfo = {
  id: 'bmr-calculator',
  title: 'BMR Calculator | Free Basal Metabolic Rate Calculator',
  description: 'Free BMR (Basal Metabolic Rate) calculator using multiple scientific formulas including Mifflin-St Jeor, Harris-Benedict, Katch-McArdle, and Cunningham equations. Calculate your resting calorie burn and get personalized nutrition recommendations.',
  icon: Activity,
  category: 'Health',
  schema: {
    "@type": "SoftwareApplication",
    "name": "BMR Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "HealthAndFitness",
    "operatingSystem": "Any",
    "description": "Calculate your Basal Metabolic Rate (BMR) using multiple scientific formulas. Features comprehensive analysis, activity level adjustments, and macronutrient recommendations.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/bmr-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "1850",
      "reviewCount": "1420",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Multiple BMR Formulas",
      "Scientific Calculations",
      "Personalized Analysis",
      "Activity Level Adjustment",
      "Nutrition Recommendations",
      "Mobile Friendly",
      "Free Forever"
    ]
  },
  keywords: [
    'bmr calculator',
    'basal metabolic rate',
    'resting metabolic rate',
    'calorie calculator',
    'metabolism calculator',
    'mifflin st jeor calculator',
    'harris benedict calculator',
    'katch mcardle calculator',
    'resting energy expenditure',
    'metabolic rate calculator'
  ],
  features: [
    'Multiple formula options',
    'Scientific BMR calculation',
    'Activity level adjustment',
    'Personalized recommendations',
    'Detailed metabolic analysis',
    'Mobile-friendly interface',
    'No registration required',
    'Free to use'
  ],
  formulas: [
    {
      expression: 'BMR (Mifflin-St Jeor) = 10 × weight + 6.25 × height - 5 × age + s',
      explanation: 'Modern and accurate BMR formula, where s is +5 for males and -161 for females',
      variables: [
        { name: 'weight', description: 'Weight in kilograms' },
        { name: 'height', description: 'Height in centimeters' },
        { name: 'age', description: 'Age in years' },
        { name: 's', description: 'Sex constant (+5 for males, -161 for females)' }
      ]
    },
    {
      expression: 'BMR (Harris-Benedict) = 66.47 + 13.75W + 5.003H - 6.755A (male)',
      explanation: 'Traditional BMR formula for males',
      variables: [
        { name: 'W', description: 'Weight in kilograms' },
        { name: 'H', description: 'Height in centimeters' },
        { name: 'A', description: 'Age in years' }
      ]
    },
    {
      expression: 'BMR (Harris-Benedict) = 655.1 + 9.563W + 1.85H - 4.676A (female)',
      explanation: 'Traditional BMR formula for females',
      variables: [
        { name: 'W', description: 'Weight in kilograms' },
        { name: 'H', description: 'Height in centimeters' },
        { name: 'A', description: 'Age in years' }
      ]
    },
    {
      expression: 'BMR (Katch-McArdle) = 370 + (21.6 × LBM)',
      explanation: 'BMR formula using lean body mass',
      variables: [
        { name: 'LBM', description: 'Lean Body Mass in kilograms' }
      ]
    },
    {
      expression: 'BMR (Cunningham) = 500 + (22 × LBM)',
      explanation: 'Athletic-focused BMR formula using lean body mass',
      variables: [
        { name: 'LBM', description: 'Lean Body Mass in kilograms' }
      ]
    }
  ],
  faqs: [
    {
      question: 'What is BMR?',
      answer: 'BMR (Basal Metabolic Rate) is the number of calories your body burns at complete rest to maintain basic life functions like breathing, circulation, and cell production. It represents your minimum caloric needs and typically accounts for 60-75% of your total daily calorie expenditure.'
    },
    {
      question: 'Which BMR formula is most accurate?',
      answer: 'The Mifflin-St Jeor equation is considered the most accurate for the general population. However, Katch-McArdle and Cunningham formulas may be more accurate for athletic individuals as they account for lean body mass. Our calculator provides all formulas for comprehensive analysis.'
    },
    {
      question: 'How does BMR differ from TDEE?',
      answer: 'BMR represents calories burned at complete rest, while TDEE (Total Daily Energy Expenditure) includes BMR plus calories burned through daily activities, exercise, and food digestion. TDEE is calculated by multiplying BMR by an activity factor.'
    },
    {
      question: 'Why do the different formulas give different results?',
      answer: 'Each formula was developed using different research populations and methodologies. Mifflin-St Jeor uses modern data, Harris-Benedict is traditional, while Katch-McArdle and Cunningham focus on lean body mass. Individual results may vary based on body composition and metabolism.'
    },
    {
      question: 'How can I use BMR for weight management?',
      answer: 'Your BMR helps determine your total daily calorie needs. For weight loss, create a deficit from your TDEE (BMR × activity factor). For maintenance, eat at TDEE level. For weight gain, create a surplus. Never eat below your BMR as it can slow metabolism.'
    }
  ],
  references: [
    'Mifflin-St Jeor Original Research Paper',
    'Harris-Benedict Equation Studies',
    'Katch-McArdle Formula Research',
    'Cunningham Equation for Athletes',
    'Journal of the Academy of Nutrition and Dietetics',
    'International Journal of Sport Nutrition',
    'Medicine & Science in Sports & Exercise Research'
  ],
  itemListElement: [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Health Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/health-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Calorie Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/calorie-calculator"
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "BMR Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/bmr-calculator"
      }
    }
  ]
};
