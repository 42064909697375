// Import all calculator metadata
import { retirementCalculator } from './calculatorInfo/retirementCalculator';
import { graphingCalculator } from './calculatorInfo/graphingCalculator';
import { concreteCalculator } from './calculatorInfo/concreteCalculator';
import { autoLoanCalculator } from './calculatorInfo/autoLoanCalculator';
import { loanCalculator } from './calculatorInfo/loanCalculator';
import { compoundInterestCalculator } from './calculatorInfo/compoundInterestCalculator';
import { taxCalculator } from './calculatorInfo/taxCalculator';
import { savingsCalculator } from './calculatorInfo/savingsCalculator';
import { amortizationCalculator } from './calculatorInfo/amortizationCalculator';
import { paycheckCalculator } from './calculatorInfo/paycheckCalculator';
import { investmentCalculator } from './calculatorInfo/investmentCalculator';
import { basicCalculator } from './calculatorInfo/basicCalculator';
import { roundingCalculator } from './calculatorInfo/roundingCalculator';
import { gradeCalculator } from './calculatorInfo/gradeCalculator';
import { scientificCalculator } from './calculatorInfo/scientificCalculator';
import { algebraCalculator } from './calculatorInfo/algebraCalculator';
import { fractionCalculator } from './calculatorInfo/fractionCalculator';
import { standardDeviationCalculator } from './calculatorInfo/standardDeviationCalculator';
import { integralCalculator } from './calculatorInfo/integralCalculator';
import { quadraticCalculator } from './calculatorInfo/quadraticCalculator';
import { matrixCalculator } from './calculatorInfo/matrixCalculator';
import { timeCalculator } from './calculatorInfo/timeCalculator';
import { tipCalculator } from './calculatorInfo/tipCalculator';
import { sleepCalculator } from './calculatorInfo/sleepCalculator';
import { squareFootageCalculator } from './calculatorInfo/squareFootageCalculator';
import { percentageCalculator } from './calculatorInfo/percentageCalculator';
import { ageCalculator } from './calculatorInfo/ageCalculator';
import { bmiCalculator } from './calculatorInfo/bmi';
import { calorieCalculator } from './calculatorInfo/calorie';
import { tdeeCalculator } from './calculatorInfo/tdee';
import { bmrCalculator } from './calculatorInfo/bmrCalculator';
import { bodyFatCalculator } from './calculatorInfo/body-fat';
import { ovulationCalculator } from './calculatorInfo/ovulationCalculator';
import { calorieDeficitCalculator } from './calculatorInfo/calorieDeficitCalculator';
import { proteinCalculator } from './calculatorInfo/proteinCalculator';
import { paceCalculator } from './calculatorInfo/paceCalculator';
import { derivativeCalculator } from './calculatorInfo/derivativeCalculator';
import { aiFitnessCalculator } from './calculatorInfo/aiFitnessCalculator';
import { mortgageCalculator } from './calculatorInfo/mortgageCalculator';
import { gpaCalculator } from './calculatorInfo/gpaCalculator';
import { dateCalculator } from './calculatorInfo/dateCalculator';
import { pregnancyCalculator } from './calculatorInfo/pregnancyCalculator';
import { lifeExpectancyCalculator } from './calculatorInfo/LifeExpectancyCalculator';

// Export a map of all calculators
export const calculatorRegistry = {
    'retirement-calculator': retirementCalculator,
    'graphing-calculator': graphingCalculator,
    'concrete-calculator': concreteCalculator,
    'auto-loan-calculator': autoLoanCalculator,
    'loan-calculator': loanCalculator,
    'compound-interest-calculator': compoundInterestCalculator,
    'tax-calculator': taxCalculator,
    'savings-calculator': savingsCalculator,
    'amortization-calculator': amortizationCalculator,
    'paycheck-calculator': paycheckCalculator,
    'investment-calculator': investmentCalculator,
    'basic-calculator': basicCalculator,
    'rounding-calculator': roundingCalculator,
    'grade-calculator': gradeCalculator,
    'scientific-calculator': scientificCalculator,
    'algebra-calculator': algebraCalculator,
    'fraction-calculator': fractionCalculator,
    'standard-deviation-calculator': standardDeviationCalculator,
    'integral-calculator': integralCalculator,
    'quadratic-calculator': quadraticCalculator,
    'matrix-calculator': matrixCalculator,
    'time-calculator': timeCalculator,
    'tip-calculator': tipCalculator,
    'sleep-calculator': sleepCalculator,
    'square-footage-calculator': squareFootageCalculator,
    'percentage-calculator': percentageCalculator,
    'age-calculator': ageCalculator,
    'bmi-calculator': bmiCalculator,
    'calorie-calculator': calorieCalculator,
    'tdee-calculator': tdeeCalculator,
    'bmr-calculator': bmrCalculator,
    'body-fat-calculator': bodyFatCalculator,
    'ovulation-calculator': ovulationCalculator,
    'calorie-deficit-calculator': calorieDeficitCalculator,
    'protein-calculator': proteinCalculator,
    'pace-calculator': paceCalculator,
    'derivative-calculator': derivativeCalculator,
    'ai-fitness-calculator': aiFitnessCalculator,
    'mortgage-calculator': mortgageCalculator,
    'gpa-calculator': gpaCalculator,
    'date-calculator': dateCalculator,
    'pregnancy-calculator': pregnancyCalculator,
    'life-expectancy-calculator': lifeExpectancyCalculator,
} as const;

// Type guard to check if an object is a valid CalculatorInfo
function isValidCalculatorInfo(obj: any): obj is CalculatorInfo {
    return (
        obj &&
        typeof obj === 'object' &&
        typeof obj.id === 'string' &&
        typeof obj.title === 'string' &&
        typeof obj.description === 'string' &&
        Array.isArray(obj.keywords) &&
        obj.schema &&
        typeof obj.schema === 'object'
    );
}

// Helper function to get calculator metadata by path
export function getCalculatorMetadata(path: string) {
    if (!path || typeof path !== 'string') {
        console.warn('Invalid path provided to getCalculatorMetadata:', path);
        return null;
    }

    // Remove leading slash and language prefix if present
    const cleanPath = path.replace(/^\//, '').replace(/^(en|es|pt-br)\//, '');
    
    // Extract the calculator name from the path
    const calculatorName = cleanPath.split('/')[0];
    
    // Find exact match in registry
    const calculatorKey = Object.keys(calculatorRegistry).find(key => 
        key === calculatorName
    );
    
    if (!calculatorKey) {
        console.warn('No calculator found for path:', cleanPath);
        return null;
    }

    const calculator = calculatorRegistry[calculatorKey as keyof typeof calculatorRegistry];
    
    // Validate calculator metadata
    if (!isValidCalculatorInfo(calculator)) {
        console.error('Invalid calculator metadata for key:', calculatorKey);
        return null;
    }

    return calculator;
}

// Export types
export type CalculatorKey = keyof typeof calculatorRegistry;
export type Calculator = typeof calculatorRegistry[CalculatorKey];
