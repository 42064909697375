import { Receipt } from 'lucide-react';
import { CalculatorInfo } from './types';

export const taxCalculator: CalculatorInfo = {
  id: 'tax-calculator',
  title: 'Tax Calculator | Free Income Tax Calculator 2024',
  description: 'Free 2024 income tax calculator with federal, state, and FICA tax calculations. Includes standard and itemized deductions, tax credits, multiple filing statuses, and detailed tax breakdowns. Perfect for tax planning and estimating take-home pay.',
  icon: Receipt,
  category: 'Financial',
  schema: {
    "@type": "SoftwareApplication",
    "name": "Tax Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Finance",
    "operatingSystem": "Any",
    "description": "Professional tax calculator with 2024 tax brackets, multiple filing statuses, deductions, credits, and detailed tax breakdowns. Essential for income tax planning and paycheck estimation.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/tax-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "3250",
      "reviewCount": "1980",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "2024 Tax Brackets",
      "Multiple Filing Statuses",
      "State Tax Integration",
      "Itemized Deductions",
      "Tax Credits Calculator",
      "FICA Tax Calculation",
      "Interactive Tax Charts",
      "PDF Report Generation",
      "Mobile Friendly Design",
      "Free Forever"
    ]
  },
  formulas: [
    {
      expression: 'Taxable Income = Gross Income - Deductions',
      explanation: 'Calculate taxable income by subtracting either standard or itemized deductions from gross income',
      variables: [
        { name: 'Gross Income', description: 'Total income before taxes and deductions' },
        { name: 'Deductions', description: 'Standard deduction or sum of itemized deductions' }
      ]
    },
    {
      expression: 'Federal Tax = Sum(Bracket Income × Bracket Rate)',
      explanation: 'Calculate federal tax using progressive tax brackets',
      variables: [
        { name: 'Bracket Income', description: 'Amount of income in each tax bracket' },
        { name: 'Bracket Rate', description: 'Tax rate for each bracket' }
      ]
    },
    {
      expression: 'FICA Tax = (Social Security Rate × Income up to Cap) + (Medicare Rate × All Income)',
      explanation: 'Calculate FICA taxes including Social Security and Medicare',
      variables: [
        { name: 'Social Security Rate', description: '6.2% up to income cap' },
        { name: 'Medicare Rate', description: '1.45% on all income' },
        { name: 'Income', description: 'Earned income subject to FICA' }
      ]
    }
  ],
  faqs: [
    {
      question: 'Are the tax brackets up to date?',
      answer: 'Yes, the calculator uses the latest 2024 federal tax brackets and standard deduction amounts.'
    },
    {
      question: 'What deductions are supported?',
      answer: 'The calculator supports both standard deductions and itemized deductions including mortgage interest, property tax, charitable donations, and medical expenses.'
    },
    {
      question: 'Does it calculate state taxes?',
      answer: 'Yes, the calculator includes state tax calculations for different states with their specific tax rates and brackets.'
    },
    {
      question: 'What tax credits are included?',
      answer: 'The calculator supports various tax credits including Child Tax Credit, Earned Income Credit, and Education Credits.'
    },
    {
      question: 'Can I see my take-home pay for different periods?',
      answer: 'Yes, you can view your estimated take-home pay on an annual, monthly, bi-weekly, or weekly basis.'
    }
  ],
  references: [
    'IRS Tax Brackets 2024',
    'State Tax Guidelines',
    'FICA Tax Regulations',
    'Tax Deduction Rules',
    'Tax Credit Eligibility Requirements'
  ],
  keywords: [
    'tax calculator 2024',
    'income tax calculator',
    'take home pay calculator',
    'federal tax calculator',
    'state tax calculator',
    'fica tax calculator',
    'tax bracket calculator',
    'paycheck calculator',
    'tax deduction calculator',
    'free tax calculator'
  ],
  features: [
    'Updated 2024 tax brackets',
    'Multiple filing status options',
    'State tax calculations',
    'Standard and itemized deductions',
    'Tax credit calculations',
    'FICA tax computation',
    'Interactive tax visualizations',
    'PDF report generation',
    'Mobile-responsive design',
    'High-precision calculations'
  ],
  itemListElement: [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Financial Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/financial-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Tax Tools",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/tax-tools"
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Tax Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/tax-calculator"
      }
    }
  ]
};
