import { TrendingUp } from 'lucide-react';
import { CalculatorInfo } from './types';

export const compoundInterestCalculator: CalculatorInfo = {
  id: 'compound-interest-calculator',
  title: 'Compound Interest Calculator | Free Investment Growth Calculator',
  description: 'Free compound interest calculator with inflation adjustment and multiple compounding frequencies. Calculate investment growth, savings goals, and see detailed year-by-year breakdowns with interactive charts. Perfect for retirement planning, investment analysis, and wealth building.',
  icon: TrendingUp,
  category: 'Financial',
  schema: {
    "@type": "SoftwareApplication",
    "name": "Compound Interest Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Finance",
    "operatingSystem": "Any",
    "description": "Professional compound interest calculator with multiple compounding frequencies, inflation adjustment, and visual growth projections. Essential for investment planning and wealth management.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/compound-interest-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "1850",
      "reviewCount": "1120",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Multiple Compounding Frequencies",
      "Inflation Adjustment",
      "Interactive Growth Charts",
      "Regular Contribution Options",
      "Year-by-Year Breakdown",
      "Total Interest Calculation",
      "Real vs. Nominal Growth",
      "Investment Timeline Analysis",
      "Mobile Friendly Design",
      "Free Forever"
    ]
  },
  formulas: [
    {
      expression: 'A = P(1 + r/n)^(nt) + PMT × (((1 + r/n)^(nt) - 1) / (r/n))',
      explanation: 'Compound interest with regular contributions where A is final amount, P is principal, r is annual interest rate, n is number of times interest is compounded per year, t is time in years, and PMT is regular payment amount',
      variables: [
        { name: 'P', description: 'Principal (initial investment)' },
        { name: 'r', description: 'Annual interest rate (as decimal)' },
        { name: 'n', description: 'Compounding frequency per year' },
        { name: 't', description: 'Time period in years' },
        { name: 'PMT', description: 'Regular contribution amount' }
      ]
    },
    {
      expression: 'Real Rate = ((1 + Nominal Rate) / (1 + Inflation Rate)) - 1',
      explanation: 'Calculate real rate of return adjusted for inflation',
      variables: [
        { name: 'Nominal Rate', description: 'Unadjusted interest rate' },
        { name: 'Inflation Rate', description: 'Annual inflation rate' }
      ]
    }
  ],
  faqs: [
    {
      question: 'What compounding frequencies are supported?',
      answer: 'The calculator supports multiple compounding frequencies including daily, weekly, monthly, quarterly, semi-annually, and annually.'
    },
    {
      question: 'Can I account for inflation in the calculations?',
      answer: 'Yes, you can input an expected inflation rate to see both nominal and real (inflation-adjusted) returns.'
    },
    {
      question: 'Can I add regular contributions?',
      answer: 'Yes, you can specify regular contributions on an annual basis to simulate systematic investment plans.'
    },
    {
      question: 'How are the results displayed?',
      answer: 'Results are shown through interactive charts and detailed yearly breakdowns, including principal, contributions, and interest earned.'
    },
    {
      question: 'Is this calculator suitable for retirement planning?',
      answer: 'Yes, it\'s perfect for retirement planning as it shows long-term growth projections and accounts for regular contributions and inflation.'
    }
  ],
  references: [
    'Time Value of Money Principles',
    'Compound Interest Formulas',
    'Investment Mathematics',
    'Financial Planning Standards',
    'Inflation Adjustment Methods'
  ],
  keywords: [
    'compound interest calculator',
    'investment calculator',
    'savings calculator',
    'retirement calculator',
    'interest growth calculator',
    'investment growth calculator',
    'wealth building calculator',
    'financial planning calculator',
    'inflation adjusted calculator',
    'free compound interest calculator'
  ],
  features: [
    'Multiple compounding frequencies',
    'Inflation adjustment',
    'Regular contribution options',
    'Interactive growth charts',
    'Year-by-year breakdown',
    'Real vs nominal returns',
    'Investment timeline analysis',
    'Total interest calculation',
    'Mobile-responsive design',
    'High-precision calculations'
  ],
  itemListElement: [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Financial Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/financial-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Investment Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/investment-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Compound Interest Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/compound-interest-calculator"
      }
    }
  ]
};
