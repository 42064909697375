import { Ruler } from 'lucide-react';
import { CalculatorInfo } from '../types/CalculatorInfo';

export const squareFootageCalculator: CalculatorInfo = {
    title: "Square Footage Calculator - Area & Paint Coverage Calculator",
    description: "Free online square footage calculator for room dimensions, flooring estimates, and paint coverage. Features multiple shape support, material cost estimation, and detailed PDF reports for home improvement and real estate projects.",
    category: "Construction & Real Estate",
    icon: Ruler,
    
    keywords: [
        "square footage calculator",
        "area calculator",
        "room size calculator",
        "paint coverage calculator",
        "flooring calculator",
        "home measurement tool",
        "room dimensions calculator",
        "material estimator",
        "construction calculator",
        "real estate area calculator",
        "paint estimator",
        "floor plan calculator"
    ],

    schema: {
        "@type": "SoftwareApplication",
        "name": "Square Footage Calculator",
        "applicationCategory": "ConstructionApplication",
        "operatingSystem": "Any",
        "offers": {
            "@type": "Offer",
            "price": "0",
            "priceCurrency": "USD"
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.8",
            "ratingCount": "1950"
        },
        "featureList": [
            "Multiple Shape Calculations",
            "Paint Coverage Estimation",
            "Flooring Cost Calculator",
            "Room Presets",
            "Material Cost Estimation",
            "PDF Report Generation",
            "Interactive Area Visualization",
            "Unit Conversion"
        ]
    },

    features: [
        "Calculate area for multiple room shapes",
        "Estimate paint coverage and costs",
        "Compute flooring material requirements",
        "Generate detailed PDF reports",
        "Preset room dimensions",
        "Support for metric and imperial units",
        "Material cost estimation",
        "Wall area calculations",
        "Door and window deductions",
        "Interactive shape visualization"
    ],

    mathFormulas: [
        {
            name: "Rectangle Area",
            formula: "Area = Length × Width",
            explanation: "Calculates the area of rectangular rooms"
        },
        {
            name: "Circle Area",
            formula: "Area = π × Radius²",
            explanation: "Calculates the area of circular spaces"
        },
        {
            name: "Triangle Area",
            formula: "Area = (Base × Height) ÷ 2",
            explanation: "Calculates the area of triangular spaces"
        },
        {
            name: "Paint Coverage",
            formula: "Wall Area = Perimeter × Height - (Doors + Windows)",
            explanation: "Calculates total wall area accounting for openings"
        },
        {
            name: "Material Cost",
            formula: "Total Cost = Area × (Price per Unit ÷ Coverage per Unit)",
            explanation: "Estimates total material costs based on area and unit pricing"
        }
    ],

    faqs: [
        {
            question: "How do I measure square footage accurately?",
            answer: "Measure the length and width of each room in feet, then multiply these numbers together. For irregular shapes, break the space into rectangles and add their areas. Don't forget to account for closets and other recessed areas."
        },
        {
            question: "How much paint do I need for a room?",
            answer: "Calculate the wall area by multiplying the room's perimeter by ceiling height, then subtract for windows and doors. One gallon typically covers 350-400 square feet. Remember to account for multiple coats and surface texture."
        },
        {
            question: "How do I estimate flooring materials?",
            answer: "Calculate the total square footage and add 10-15% for waste and cuts. Different flooring materials have different waste factors: tile typically needs 10%, hardwood 15%, and carpet may require pattern matching."
        },
        {
            question: "What's the difference between gross and net square footage?",
            answer: "Gross square footage includes the entire space measured to the exterior walls. Net square footage excludes walls, columns, and other non-usable spaces, focusing on the actual livable area."
        },
        {
            question: "How do I calculate irregular room shapes?",
            answer: "Break irregular shapes into basic geometric forms (rectangles, triangles, circles). Calculate each section separately and sum the results. For very complex shapes, consider using the room division method for more accurate results."
        }
    ],

    references: [
        {
            title: "Square Footage Standards",
            author: "American National Standards Institute (ANSI)",
            link: "https://www.ansi.org",
            description: "Official standards for measuring residential square footage"
        },
        {
            title: "Paint Coverage Guidelines",
            author: "Paint & Coatings Industry",
            link: "https://www.pcimag.com",
            description: "Industry standards for paint coverage calculations"
        },
        {
            title: "Flooring Estimation Guide",
            author: "National Wood Flooring Association",
            link: "https://www.nwfa.org",
            description: "Professional guidelines for flooring calculations"
        },
        {
            title: "Construction Measurement Standards",
            author: "Building Owners and Managers Association",
            link: "https://www.boma.org",
            description: "Commercial space measurement standards"
        }
    ],

    itemListElement: [
        {
            "@type": "ListItem",
            "position": 1,
            "name": "Construction Tools",
            "item": {
                "@id": "/calculators/construction"
            }
        },
        {
            "@type": "ListItem",
            "position": 2,
            "name": "Square Footage Calculator",
            "item": {
                "@id": "/calculators/square-footage"
            }
        }
    ]
};
