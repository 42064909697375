import { Calculator } from 'lucide-react';
import { CalculatorInfo } from './types';

export const gpaCalculator: CalculatorInfo = {
  id: 'gpa-calculator',
  title: 'GPA Calculator | College & High School Grade Point Average Calculator',
  description: 'Free online GPA calculator for college and high school students. Calculate your semester, cumulative, and weighted GPA instantly. Supports different grading scales and credit hours.',
  icon: Calculator,
  category: 'Education',
  schema: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "GPA Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Education",
    "operatingSystem": "Any",
    "description": "Professional GPA calculator for computing semester and cumulative grade point averages. Supports various grading scales, weighted grades, and credit hour calculations for both college and high school students.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/gpa-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "2850",
      "reviewCount": "2420",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Semester GPA Calculation",
      "Cumulative GPA Tracking",
      "Multiple Grading Scales",
      "Credit Hour Weighting",
      "Grade Point Conversion",
      "Weighted GPA Support",
      "Custom Grade Scale Options",
      "Real-time Calculations",
      "Academic Progress Tracking",
      "Easy-to-use Interface"
    ]
  },
  formulas: [
    {
      expression: 'GPA = ∑(Grade Points × Credit Hours) / ∑(Credit Hours)',
      explanation: 'Basic GPA calculation formula that multiplies each course grade point by its credit hours, sums all products, and divides by total credit hours',
      variables: [
        { name: 'Grade Points', description: 'Numerical value assigned to letter grades (e.g., A=4.0, B=3.0)' },
        { name: 'Credit Hours', description: 'Number of credit hours for each course' }
      ]
    },
    {
      expression: 'Cumulative GPA = (Previous GPA × Previous Credits + Current GPA × Current Credits) / (Previous Credits + Current Credits)',
      explanation: 'Formula to calculate cumulative GPA combining previous academic performance with current semester',
      variables: [
        { name: 'Previous GPA', description: 'GPA from all previous semesters' },
        { name: 'Previous Credits', description: 'Total credits from previous semesters' },
        { name: 'Current GPA', description: 'GPA for current semester' },
        { name: 'Current Credits', description: 'Credits for current semester' }
      ]
    },
    {
      expression: 'Weighted Grade Points = Base Grade Points + Course Weight',
      explanation: 'Formula for calculating weighted grade points for honors/AP/IB courses',
      variables: [
        { name: 'Base Grade Points', description: 'Standard grade points (e.g., A=4.0)' },
        { name: 'Course Weight', description: 'Additional points for advanced courses (typically +0.5 or +1.0)' }
      ]
    }
  ],
  faqs: [
    {
      question: 'How do I calculate my semester GPA?',
      answer: 'To calculate your semester GPA: 1) Enter each course grade and credit hours, 2) The calculator automatically converts letter grades to grade points, 3) Multiplies grade points by credit hours, 4) Sums all quality points, 5) Divides by total credit hours. The result is your semester GPA.'
    },
    {
      question: 'What is the difference between weighted and unweighted GPA?',
      answer: 'Weighted GPA assigns extra points for honors, AP, or IB courses (typically +0.5 or +1.0), while unweighted GPA uses a standard 4.0 scale for all courses. For example, an A in an AP course might be worth 5.0 instead of 4.0 points. Our calculator supports both systems.'
    },
    {
      question: 'How do I calculate my cumulative GPA?',
      answer: 'For cumulative GPA: 1) Enter your previous GPA and total credit hours, 2) Add current semester courses and grades, 3) The calculator combines previous and current performance for your overall GPA. This shows your academic performance across all semesters.'
    },
    {
      question: 'What grading scales are supported?',
      answer: 'The calculator supports multiple grading scales including: 1) Standard 4.0 scale (A=4.0, B=3.0, etc.), 2) Plus/minus scale (A+=4.3, A=4.0, A-=3.7, etc.), 3) Percentage-based scales, 4) Custom scales. Select your school\'s scale for accurate calculations.'
    },
    {
      question: 'How do credit hours affect GPA calculation?',
      answer: 'Credit hours weight each course\'s impact on your GPA. A 4-credit course has twice the impact of a 2-credit course. The formula is: (Grade Points × Credit Hours) for each course, summed and divided by total credit hours. More credits means more impact on GPA.'
    }
  ],
  references: [
    'Grade Point Average (GPA) Standards',
    'Academic Credit Hour Systems',
    'Weighted vs Unweighted GPA Calculations',
    'College Admission GPA Requirements',
    'Grade Scale Conversion Methods',
    'Academic Performance Metrics',
    'Semester vs Cumulative GPA',
    'Honor Roll and Dean\'s List Standards',
    'Transfer Credit GPA Policies',
    'Academic Standing Guidelines'
  ],
  keywords: [
    'gpa calculator',
    'college gpa calculator',
    'high school gpa calculator',
    'cumulative gpa calculator',
    'weighted gpa calculator',
    'semester gpa calculator',
    'grade point average',
    'academic calculator',
    'student gpa tool',
    'grade calculator'
  ],
  features: [
    'Semester and cumulative GPA calculation',
    'Multiple grading scale support (4.0, 5.0, percentage)',
    'Credit hour weighting system',
    'Weighted GPA for honors/AP/IB courses',
    'GPA trend visualization',
    'Semester-by-semester breakdown',
    'Course grade management',
    'GPA projection calculator',
    'Export functionality for records',
    'Real-time GPA updates'
  ]
};
