import { Timer } from 'lucide-react';
import { CalculatorInfo } from './types';

export const paceCalculator: CalculatorInfo = {
  id: 'pace-calculator',
  title: 'Running Pace Calculator | Race Time Predictor | Training Pace Calculator',
  description: 'Free running pace calculator for runners to calculate pace, predict race times, and get personalized training paces. Supports 5K, 10K, half marathon, marathon distances with km/mile conversion and detailed pace charts.',
  icon: Timer,
  category: 'Fitness',
  schema: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Running Pace Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "FitnessAndTraining",
    "operatingSystem": "Any",
    "description": "Calculate running pace, predict race times, and get personalized training paces with our comprehensive running calculator. Features include pace conversions, split times, race predictions, and training zone calculations.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "2150",
      "reviewCount": "1680",
      "bestRating": "5",
      "worstRating": "1"
    }
  },
  features: [
    "Calculate running pace from distance and time",
    "Convert between km/h, mph, and minutes per km/mile",
    "Generate detailed split times for races",
    "Predict race times for common distances (5K to Marathon)",
    "Calculate training paces for different intensity zones",
    "Support for both metric and imperial units",
    "Interactive pace charts and visualizations",
    "Personalized training zone calculations"
  ],
  keywords: [
    "running pace calculator",
    "race time predictor",
    "training pace calculator",
    "running speed converter",
    "race split calculator",
    "marathon pace calculator",
    "half marathon pace",
    "5k pace calculator",
    "running tempo calculator",
    "km to mile pace",
    "running training zones",
    "race time estimation",
    "running splits calculator",
    "running speed calculator",
    "running conversion tool",
    "race pace planner"
  ],
  references: [
    "Jack Daniels' Running Formula (Third Edition)",
    "Pete Pfitzinger's Advanced Marathoning",
    "International Association of Athletics Federations (IAAF) Standards",
    "McMillan Running Calculator Methodology",
    "Journal of Strength and Conditioning Research: Running Performance Studies"
  ],
  faqs: [
    {
      question: 'How do I calculate my running pace?',
      answer: 'To calculate running pace: 1) Enter your distance (in km or miles), 2) Enter your time (hours:minutes:seconds), 3) The calculator will show your pace per km/mile and speed in km/h or mph. For example, running 5km in 25 minutes equals a 5:00 min/km pace or 12 km/h.'
    },
    {
      question: 'What is a good running pace for beginners?',
      answer: 'A good beginner running pace is typically between 12-15 minutes per mile (7:30-9:20 per km). Start with a comfortable pace where you can hold a conversation. As fitness improves, most beginners can work toward a 10-minute mile (6:12 per km) pace for shorter distances.'
    },
    {
      question: 'How can I improve my running pace?',
      answer: 'To improve running pace: 1) Include interval training and tempo runs, 2) Build endurance with long, slow runs, 3) Practice proper form and breathing, 4) Gradually increase weekly mileage, 5) Include strength training, 6) Allow adequate recovery between hard workouts. Consistency and gradual progression are key.'
    },
    {
      question: 'How do training paces differ from race pace?',
      answer: 'Training paces vary by workout type: 1) Easy runs: 60-75% of race pace, 2) Long runs: 65-80% of race pace, 3) Tempo runs: 85-90% of race pace, 4) Interval training: 95-105% of race pace. Different paces target specific physiological adaptations and help prevent burnout.'
    },
    {
      question: 'How accurate are race time predictions?',
      answer: 'Race time predictions are estimates based on current fitness level and assume proper training and race conditions. Accuracy depends on: 1) Recent race performance, 2) Specific training for the distance, 3) Course conditions, 4) Weather, 5) Race day execution. They work best for predicting times at similar distances.'
    }
  ]
};
