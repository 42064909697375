import { Car } from 'lucide-react';
import { CalculatorInfo } from './types';

export const autoLoanCalculator: CalculatorInfo = {
  id: 'auto-loan-calculator',
  title: 'Auto Loan Calculator | Free Car Payment & Cost Calculator',
  description: 'Free auto loan calculator with complete cost breakdown. Calculate car payments, including sales tax, trade-in value, fees, and amortization schedule. Perfect for new and used car purchases, refinancing, and comparing different loan terms.',
  icon: Car,
  category: 'Financial',
  schema: {
    "@type": "SoftwareApplication",
    "name": "Auto Loan Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Finance",
    "operatingSystem": "Any",
    "description": "Professional auto loan calculator with comprehensive cost analysis, including trade-in value, sales tax, fees, and payment schedules. Essential for car buying decisions and loan comparisons.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/auto-loan-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "2250",
      "reviewCount": "1380",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Complete Cost Breakdown",
      "Trade-in Value Calculation",
      "Sales Tax Integration",
      "Detailed Amortization Schedule",
      "Extra Payment Analysis",
      "Interactive Payment Charts",
      "Total Cost Visualization",
      "Fee Calculator",
      "Mobile Friendly Design",
      "Free Forever"
    ]
  },
  formulas: [
    {
      expression: 'Loan Amount = (Vehicle Price + Fees + Sales Tax) - (Trade-in Value + Down Payment)',
      explanation: 'Calculate the actual loan amount needed after considering all costs and credits',
      variables: [
        { name: 'Vehicle Price', description: 'Price of the vehicle' },
        { name: 'Fees', description: 'Registration, documentation, and other fees' },
        { name: 'Sales Tax', description: 'State and local sales tax' },
        { name: 'Trade-in Value', description: 'Value of trade-in vehicle' },
        { name: 'Down Payment', description: 'Initial payment amount' }
      ]
    },
    {
      expression: 'Monthly Payment = (P × r × (1 + r)^n) / ((1 + r)^n - 1)',
      explanation: 'Calculate monthly payment where P is principal, r is monthly interest rate, and n is number of months',
      variables: [
        { name: 'P', description: 'Principal loan amount' },
        { name: 'r', description: 'Monthly interest rate (annual rate / 12)' },
        { name: 'n', description: 'Loan term in months' }
      ]
    },
    {
      expression: 'Total Cost = Monthly Payment × Term + Down Payment + Trade-in Value',
      explanation: 'Calculate total cost of vehicle ownership including all payments',
      variables: [
        { name: 'Monthly Payment', description: 'Calculated monthly payment' },
        { name: 'Term', description: 'Loan term in months' },
        { name: 'Down Payment', description: 'Initial down payment' },
        { name: 'Trade-in Value', description: 'Value of trade-in vehicle' }
      ]
    }
  ],
  faqs: [
    {
      question: 'What costs does this calculator include?',
      answer: 'The calculator includes vehicle price, sales tax, registration fees, trade-in value, down payment, and loan terms to give you a complete picture of your auto loan.'
    },
    {
      question: 'Can I see how extra payments affect my loan?',
      answer: 'Yes, you can input additional monthly payments to see how they reduce your loan term and total interest paid.'
    },
    {
      question: 'Does it calculate sales tax correctly?',
      answer: 'Yes, the calculator applies sales tax to the vehicle price after subtracting trade-in value, following most state regulations.'
    },
    {
      question: 'Can I see the full payment schedule?',
      answer: 'Yes, the calculator provides a detailed amortization schedule showing principal, interest, and remaining balance for each payment.'
    },
    {
      question: 'Is this suitable for used car loans?',
      answer: 'Yes, the calculator works for both new and used car loans, allowing you to adjust interest rates and terms accordingly.'
    }
  ],
  references: [
    'Auto Loan Amortization Methods',
    'Vehicle Sales Tax Regulations',
    'Car Financing Guidelines',
    'Consumer Auto Lending Standards',
    'Trade-in Value Assessment Methods'
  ],
  keywords: [
    'auto loan calculator',
    'car payment calculator',
    'vehicle loan calculator',
    'car loan payment calculator',
    'auto finance calculator',
    'car loan amortization calculator',
    'vehicle payment calculator',
    'car buying calculator',
    'auto loan amortization calculator',
    'free car loan calculator'
  ],
  features: [
    'Complete cost breakdown',
    'Trade-in value integration',
    'Sales tax calculation',
    'Registration fee estimation',
    'Amortization schedule',
    'Extra payment analysis',
    'Interactive payment charts',
    'Total cost visualization',
    'Mobile-responsive design',
    'High-precision calculations'
  ],
  itemListElement: [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Financial Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/financial-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Loan Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/loan-calculator"
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Auto Loan Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/auto-loan-calculator"
      }
    }
  ]
};
