import { GraduationCap } from 'lucide-react';
import { CalculatorInfo } from './types';

export const gradeCalculator: CalculatorInfo = {
  id: 'grade-calculator',
  title: 'Grade Calculator | Final Grade Calculator | Course Grade Calculator',
  description: 'Free grade calculator for students to calculate weighted grades, predict final grades, and track academic performance. Features include grade categories, drop lowest scores, grade scaling, and detailed performance analysis.',
  icon: GraduationCap,
  category: 'Academic',
  schema: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Grade Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "EducationAndAcademics",
    "operatingSystem": "Any",
    "description": "Comprehensive grade calculator with weighted categories, grade predictions, and performance tracking. Features include customizable grade scales, grade curves, target grade calculations, and detailed breakdowns.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/grade-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "2450",
      "reviewCount": "1890",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Weighted Grade Calculation",
      "Grade Category Management",
      "Drop Lowest Score Option",
      "Grade Scale Customization",
      "Grade Curve Adjustment",
      "Target Grade Analysis",
      "Performance Tracking",
      "Grade Prediction Tools",
      "Print-Ready Reports",
      "Free Forever"
    ]
  },
  formulas: [
    {
      expression: 'Category Grade = Σ(Score ÷ Max Score × 100) ÷ Number of Assignments',
      explanation: 'Calculate average grade for each category, optionally dropping lowest scores',
      variables: [
        { name: 'Score', description: 'Points earned on assignment' },
        { name: 'Max Score', description: 'Maximum possible points' },
        { name: 'Number of Assignments', description: 'Total assignments in category (minus dropped scores)' }
      ]
    },
    {
      expression: 'Weighted Average = Σ(Category Grade × Category Weight)',
      explanation: 'Calculate overall grade by combining weighted category averages',
      variables: [
        { name: 'Category Grade', description: 'Average grade for each category' },
        { name: 'Category Weight', description: 'Weight percentage for each category' }
      ]
    },
    {
      expression: 'Needed Score = (Target Grade × Total Weight - Current Points) ÷ Remaining Weight',
      explanation: 'Calculate required score on remaining work to achieve target grade',
      variables: [
        { name: 'Target Grade', description: 'Desired final grade percentage' },
        { name: 'Total Weight', description: 'Sum of all category weights' },
        { name: 'Current Points', description: 'Current weighted points' },
        { name: 'Remaining Weight', description: 'Sum of remaining assignment weights' }
      ]
    },
    {
      expression: 'Curved Grade = Raw Grade + Curve Points',
      explanation: 'Adjust grades using curve points to normalize class performance',
      variables: [
        { name: 'Raw Grade', description: 'Original calculated grade' },
        { name: 'Curve Points', description: 'Additional points added to all grades' }
      ]
    }
  ],
  faqs: [
    {
      question: 'How are weighted grades calculated?',
      answer: 'Weighted grades are calculated through: 1) Calculate average for each category (assignments, tests, etc.), 2) Multiply category averages by their weights (e.g., Tests 40%, Homework 30%), 3) Sum all weighted categories for final grade. For example, 85% in Tests (40% weight) contributes 34 points to final grade. The calculator handles all these calculations automatically.'
    },
    {
      question: 'How does dropping lowest scores work?',
      answer: 'Dropping lowest scores: 1) Enable "Drop Lowest" for specific categories, 2) System identifies lowest scoring assignments, 3) Removes these scores from category average, 4) Recalculates category grade without dropped scores, 5) Updates overall weighted average. This helps minimize impact of occasional poor performance.'
    },
    {
      question: 'What grade do I need on the final?',
      answer: 'Calculate needed final exam grade: 1) Enter current grades and weights, 2) Set target final grade, 3) Calculator determines required score on remaining work. For example, if you have 85% currently (worth 70% of grade) and want 90% overall, calculator shows needed final exam score (worth 30%). Consider realistic goals based on remaining assignments.'
    },
    {
      question: 'How does grade curving work?',
      answer: 'Grade curving adjusts raw scores through: 1) Add curve points to all grades, 2) Adjust grade scale boundaries, 3) Normalize class performance, 4) Maintain relative student standings, 5) Apply institutional policies. For example, adding 5 curve points moves an 87% to 92%. Check your instructor\'s specific curving policies.'
    },
    {
      question: 'How can I improve my grade?',
      answer: 'Improve your grade by: 1) Focus on heavily weighted categories, 2) Complete all assignments, even low-weight ones, 3) Calculate minimum needed scores using target grade feature, 4) Track progress regularly, 5) Identify improvement opportunities in each category, 6) Consider extra credit if available, 7) Use grade predictions to plan study strategy.'
    }
  ],
  references: [
    'Educational Assessment Standards',
    'Academic Grading Guidelines',
    'Grade Calculation Methods',
    'Statistical Grade Analysis',
    'Educational Measurement Principles',
    'Academic Performance Metrics',
    'Grade Normalization Methods',
    'Student Assessment Research'
  ],
  keywords: [
    'grade calculator',
    'weighted grade calculator',
    'final grade calculator',
    'course grade calculator',
    'grade average calculator',
    'grade predictor',
    'assignment grade calculator',
    'grade percentage calculator',
    'grade tracking tool',
    'free grade calculator'
  ],
  features: [
    'Weighted grade calculations',
    'Category management',
    'Drop lowest scores',
    'Grade scale customization',
    'Grade predictions',
    'Performance tracking',
    'Target grade analysis',
    'Grade curves',
    'Detailed reports',
    'Educational resources'
  ]
};
