import { Ruler } from 'lucide-react';
import { CalculatorInfo } from './types';

export const bodyFatCalculator: CalculatorInfo = {
  id: 'body-fat-calculator',
  title: 'Body Fat Calculator | Free Body Fat Percentage Calculator',
  description: 'Free body fat calculator using multiple scientific methods including U.S. Navy formula, Jackson-Pollock skinfold measurements, and YMCA formula. Get accurate body composition analysis with detailed health recommendations and fitness insights.',
  icon: Ruler,
  category: 'Health',
  schema: {
    "@type": "SoftwareApplication",
    "name": "Body Fat Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "HealthAndFitness",
    "operatingSystem": "Any",
    "description": "Calculate your body fat percentage using multiple scientific methods. Features Navy formula, skinfold measurements, and comprehensive body composition analysis with personalized recommendations.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/body-fat-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "2150",
      "reviewCount": "1680",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Multiple Calculation Methods",
      "Navy Formula Support",
      "Skinfold Measurements",
      "Body Composition Analysis",
      "Health Recommendations",
      "Fitness Insights",
      "Mobile Friendly",
      "Free Forever"
    ]
  },
  keywords: [
    'body fat calculator',
    'body fat percentage calculator',
    'body composition calculator',
    'navy body fat calculator',
    'skinfold calculator',
    'body fat measurement',
    'lean mass calculator',
    'fat mass calculator',
    'body composition analysis',
    'fitness calculator'
  ],
  features: [
    'Multiple calculation methods',
    'U.S. Navy formula support',
    'Skinfold measurement calculator',
    'YMCA formula calculator',
    'Body composition analysis',
    'Health recommendations',
    'Visual progress tracking',
    'Mobile-friendly interface'
  ],
  formulas: [
    {
      expression: 'Navy Method (Men) = 86.010 × log10(waist - neck) - 70.041 × log10(height) + 36.76',
      explanation: 'U.S. Navy method for men using circumference measurements, known for good accuracy and ease of measurement',
      variables: [
        { name: 'waist', description: 'Waist circumference at naval (cm)' },
        { name: 'neck', description: 'Neck circumference (cm)' },
        { name: 'height', description: 'Height (cm)' }
      ]
    },
    {
      expression: 'Navy Method (Women) = 163.205 × log10(waist + hip - neck) - 97.684 × log10(height) - 78.387',
      explanation: 'U.S. Navy method for women using circumference measurements, includes hip measurement for improved accuracy',
      variables: [
        { name: 'waist', description: 'Waist circumference at naval (cm)' },
        { name: 'hip', description: 'Hip circumference (cm)' },
        { name: 'neck', description: 'Neck circumference (cm)' },
        { name: 'height', description: 'Height (cm)' }
      ]
    },
    {
      expression: 'Jackson-Pollock = 495 / (1.0324 - 0.19077 × log10(sum) + 0.15456 × log10(height)) - 450',
      explanation: 'Body fat percentage using 3-site skinfold measurements, highly accurate when performed correctly',
      variables: [
        { name: 'sum', description: 'Sum of skinfold measurements (mm)' },
        { name: 'height', description: 'Height (cm)' }
      ]
    },
    {
      expression: 'YMCA = (-98.42 + 4.15W - 0.082A) / (W × 100)',
      explanation: 'YMCA formula using waist circumference and weight',
      variables: [
        { name: 'W', description: 'Waist circumference (cm)' },
        { name: 'A', description: 'Age in years' }
      ]
    },
    {
      expression: 'Lean Body Mass = Weight × (100 - Body Fat %) / 100',
      explanation: 'Calculate lean body mass from total weight and body fat percentage',
      variables: [
        { name: 'Weight', description: 'Total body weight (kg)' },
        { name: 'Body Fat %', description: 'Body fat percentage' }
      ]
    }
  ],
  faqs: [
    {
      question: 'What is a healthy body fat percentage?',
      answer: 'Healthy ranges vary by gender and age. For men: Essential fat (2-5%), Athletes (6-13%), Fitness (14-17%), Average (18-24%), Obese (25%+). For women: Essential fat (10-13%), Athletes (14-20%), Fitness (21-24%), Average (25-31%), Obese (32%+). Age can affect these ranges.'
    },
    {
      question: 'Which measurement method is most accurate?',
      answer: 'DEXA scans are most accurate (±1-2%) but expensive. Hydrostatic weighing and air displacement (Bod Pod) are also very accurate (±2-3%). For at-home methods, skinfold measurements by a trained professional (±3-4%) are more accurate than the Navy method (±3-5%), which is more accurate than bioelectrical impedance (±4-6%).'
    },
    {
      question: 'How often should I measure body fat?',
      answer: 'Monthly measurements are typically sufficient to track changes. Body fat percentage can fluctuate daily due to hydration, so measure under consistent conditions: same time of day, similar hydration status, and before eating or exercising. Women may want to account for menstrual cycle variations.'
    },
    {
      question: 'Why do different methods give different results?',
      answer: 'Each method uses different assumptions and measurements. The Navy method assumes typical fat distribution patterns, skinfold methods sample specific sites, and bioelectrical impedance depends on hydration. Use the same method consistently for tracking progress.'
    },
    {
      question: 'How can I reduce body fat percentage?',
      answer: 'To reduce body fat: 1) Create a moderate caloric deficit (500-750 calories/day), 2) Maintain adequate protein intake (1.6-2.2g/kg), 3) Perform resistance training to preserve muscle mass, 4) Include cardiovascular exercise, 5) Get adequate sleep (7-9 hours), 6) Manage stress levels. Aim for 0.5-1% reduction per week.'
    }
  ],
  references: [
    'American Council on Exercise Body Fat Guidelines',
    'U.S. Navy Body Composition Assessment Program',
    'Jackson-Pollock Skinfold Measurement Protocol',
    'National Institute of Health Body Composition Studies',
    'International Journal of Obesity Standards',
    'Journal of Sports Science & Medicine Research',
    'ACSM\'s Guidelines for Exercise Testing and Prescription',
    'European Journal of Clinical Nutrition Studies'
  ],
  itemListElement: [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Health Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/health-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "BMI Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/bmi-calculator"
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Body Fat Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/body-fat-calculator"
      }
    }
  ]
};
