import { Wallet } from 'lucide-react';
import { CalculatorInfo } from './types';

export const paycheckCalculator: CalculatorInfo = {
  id: 'paycheck-calculator',
  title: 'Paycheck Calculator | Free Salary & Take Home Pay Calculator',
  description: 'Free paycheck calculator with detailed tax and deduction breakdowns. Calculate your take-home pay, estimate taxes, and analyze deductions including 401(k), insurance, and other withholdings for all 50 states.',
  icon: Wallet,
  category: 'Financial',
  schema: {
    "@type": "SoftwareApplication",
    "name": "Paycheck Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Finance",
    "operatingSystem": "Any",
    "description": "Professional paycheck calculator with comprehensive tax calculations, deduction analysis, and multiple pay frequency support. Essential for understanding your take-home pay and planning your finances.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/paycheck-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "2560",
      "reviewCount": "1780",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Federal Tax Calculation",
      "State Tax Analysis",
      "Multiple Pay Frequencies",
      "Deduction Breakdown",
      "401(k) Calculator",
      "Insurance Premium Analysis",
      "Visual Payment Charts",
      "Downloadable Reports",
      "Mobile Friendly Design",
      "Free Forever"
    ]
  },
  formulas: [
    {
      expression: 'Gross Pay = Annual Salary / Pay Periods',
      explanation: 'Calculate gross pay per period based on annual salary and pay frequency',
      variables: [
        { name: 'Annual Salary', description: 'Total yearly salary before taxes' },
        { name: 'Pay Periods', description: 'Number of pay periods per year (e.g., 26 for bi-weekly)' }
      ]
    },
    {
      expression: 'Federal Tax = Taxable Income × Federal Tax Rate',
      explanation: 'Calculate federal income tax based on tax brackets and filing status',
      variables: [
        { name: 'Taxable Income', description: 'Income subject to federal tax' },
        { name: 'Federal Tax Rate', description: 'Tax rate based on income bracket' }
      ]
    },
    {
      expression: 'Social Security = Min(Gross Pay × 0.062, Annual Limit)',
      explanation: 'Calculate Social Security withholding with annual limit',
      variables: [
        { name: 'Gross Pay', description: 'Pay before deductions' },
        { name: 'Annual Limit', description: 'Social Security contribution limit' }
      ]
    },
    {
      expression: 'Medicare = Gross Pay × 0.0145',
      explanation: 'Calculate Medicare withholding',
      variables: [
        { name: 'Gross Pay', description: 'Pay before deductions' }
      ]
    },
    {
      expression: 'Net Pay = Gross Pay - Total Deductions',
      explanation: 'Calculate take-home pay after all deductions',
      variables: [
        { name: 'Gross Pay', description: 'Pay before deductions' },
        { name: 'Total Deductions', description: 'Sum of all taxes and withholdings' }
      ]
    }
  ],
  faqs: [
    {
      question: 'How do I calculate my take-home pay?',
      answer: 'Enter your annual salary, pay frequency, filing status, and state. The calculator will automatically compute your gross pay, subtract applicable taxes (federal, state, Social Security, Medicare), and account for other deductions like 401(k) and insurance to show your net take-home pay.'
    },
    {
      question: 'What deductions are included in the calculation?',
      answer: 'The calculator includes federal income tax, state tax, Social Security, Medicare, 401(k) contributions, health insurance premiums, and other customizable deductions. You can adjust these to match your specific situation.'
    },
    {
      question: 'How are state taxes calculated?',
      answer: 'State taxes are calculated based on your selected state\'s tax rates and brackets. Some states like Texas and Florida have no state income tax, while others like California and New York have progressive tax systems.'
    },
    {
      question: 'What pay frequencies are supported?',
      answer: 'The calculator supports weekly (52 paychecks/year), bi-weekly (26 paychecks/year), semi-monthly (24 paychecks/year), and monthly (12 paychecks/year) pay frequencies.'
    },
    {
      question: 'Can I see how my 401(k) contributions affect my take-home pay?',
      answer: 'Yes, you can enter your 401(k) contribution percentage, and the calculator will show how it affects your taxable income and take-home pay. It also displays the tax advantages of pre-tax contributions.'
    }
  ],
  references: [
    'IRS Tax Brackets and Rates',
    'State Tax Guidelines',
    'Social Security Administration',
    'Medicare Tax Guidelines',
    'Payroll Tax Regulations'
  ],
  keywords: [
    'paycheck calculator',
    'salary calculator',
    'take home pay calculator',
    'net pay calculator',
    'tax calculator',
    '401k calculator',
    'payroll calculator',
    'income tax calculator',
    'salary deduction calculator',
    'free paycheck calculator'
  ],
  features: [
    'Federal tax calculation',
    'State tax analysis',
    'Multiple pay frequencies',
    'Deduction breakdown',
    '401(k) calculator',
    'Insurance premium analysis',
    'Visual payment charts',
    'Downloadable reports',
    'Mobile-responsive design',
    'High-precision calculations'
  ],
  itemListElement: [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Financial Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/financial-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Income Tools",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/income-tools"
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Paycheck Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/paycheck-calculator"
      }
    }
  ]
};
