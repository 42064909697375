import { Scale } from 'lucide-react';
import { CalculatorInfo } from './types';

export const bmiCalculator: CalculatorInfo = {
  id: 'bmi-calculator',
  title: 'BMI Calculator | Free Body Mass Index Calculator',
  description: 'Free BMI (Body Mass Index) calculator with instant results. Calculate your BMI using metric or imperial units, understand your weight category, and get personalized health insights. Includes BMI charts, weight ranges, and health recommendations.',
  icon: Scale,
  category: 'Health',
  
  schema: {
    "@type": "SoftwareApplication",
    "name": "BMI Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "HealthAndFitness",
    "operatingSystem": "Any",
    "description": "Calculate your Body Mass Index (BMI) instantly and understand your weight category. Features both metric and imperial units, BMI charts, and personalized health recommendations.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/bmi-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "3250",
      "reviewCount": "2180",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Instant BMI Calculation",
      "Metric & Imperial Units",
      "BMI Categories",
      "Weight Range Calculator",
      "Health Recommendations",
      "Mobile Friendly Design",
      "No Registration Required",
      "Free Forever",
      "Privacy Focused",
      "High Precision Results"
    ]
  },

  keywords: [
    'bmi calculator',
    'body mass index calculator',
    'weight calculator',
    'healthy weight calculator',
    'bmi chart',
    'free bmi calculator',
    'metric bmi calculator',
    'imperial bmi calculator',
    'adult bmi calculator',
    'health calculator'
  ],

  formulas: [
    {
      expression: 'BMI = weight (kg) / (height (m))²',
      explanation: 'BMI is calculated by dividing your weight in kilograms by your height in meters squared.',
      variables: [
        { name: 'weight', description: 'Your weight in kilograms (kg)' },
        { name: 'height', description: 'Your height in meters (m)' }
      ]
    },
    {
      expression: 'BMI (imperial) = 703 × weight (lb) / (height (inches))²',
      explanation: 'BMI using imperial units, with 703 as the conversion factor',
      variables: [
        { name: 'weight', description: 'Your weight in pounds (lb)' },
        { name: 'height', description: 'Your height in inches' },
        { name: '703', description: 'Conversion factor for imperial units' }
      ]
    },
    {
      expression: 'Healthy Weight Range = 18.5 × (height (m))² to 24.9 × (height (m))²',
      explanation: 'Calculate healthy weight range based on height',
      variables: [
        { name: 'height', description: 'Your height in meters' },
        { name: '18.5', description: 'Lower BMI healthy range limit' },
        { name: '24.9', description: 'Upper BMI healthy range limit' }
      ]
    }
  ],
  faqs: [
    {
      question: 'What is a healthy BMI range?',
      answer: 'A BMI between 18.5 and 24.9 is considered healthy for most adults. Below 18.5 is underweight, 25-29.9 is overweight, and 30 or above is obese. These ranges apply to adults aged 20 and older.'
    },
    {
      question: 'Is BMI accurate for everyone?',
      answer: 'BMI is not perfect and may not be accurate for athletes, elderly people, pregnant women, or those with high muscle mass. It doesn\'t account for muscle mass, bone density, age, gender, ethnicity, or overall body composition.'
    },
    {
      question: 'How often should I check my BMI?',
      answer: 'It\'s recommended to check your BMI whenever there are significant changes in your weight or height, typically every few months for adults. Regular monitoring can help track weight trends and health status.'
    },
    {
      question: 'What are the BMI categories?',
      answer: 'BMI categories are: Underweight (< 18.5), Normal weight (18.5-24.9), Overweight (25-29.9), Obesity Class I (30-34.9), Obesity Class II (35-39.9), and Obesity Class III (≥ 40).'
    },
    {
      question: 'Can children use this BMI calculator?',
      answer: 'This calculator is designed for adults. Children and teens should use age and gender-specific BMI calculators, as their healthy ranges vary with age and development.'
    }
  ],
  references: [
    'World Health Organization (WHO) BMI Classification',
    'National Institutes of Health (NIH) BMI Guidelines',
    'Centers for Disease Control and Prevention (CDC) BMI Interpretation',
    'National Health Service (NHS) BMI Healthy Weight Calculator',
    'American Heart Association BMI Guidelines'
  ],
  features: [
    'Instant BMI calculation',
    'Support for metric and imperial units',
    'BMI category classification',
    'Healthy weight range calculator',
    'Detailed BMI interpretation',
    'Mobile-friendly interface',
    'High-precision calculations',
    'Privacy-focused design',
    'No registration required',
    'Free to use'
  ],
  itemListElement: [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Health Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/health-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Body Fat Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/body-fat-calculator"
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "BMI Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/bmi-calculator"
      }
    }
  ]
};
