import { Moon } from 'lucide-react';
import { CalculatorInfo } from '../types/CalculatorInfo';

export const sleepCalculator: CalculatorInfo = {
    title: "Sleep Calculator - Sleep Cycle & Bedtime Calculator",
    description: "Free online sleep calculator for optimal bedtime and wake-up times based on sleep cycles. Features age-specific recommendations, lifestyle adjustments, sleep quality tracking, and comprehensive sleep analysis tools.",
    category: "Health & Wellness",
    icon: Moon,
    
    keywords: [
        "sleep calculator",
        "bedtime calculator",
        "sleep cycle calculator",
        "optimal wake time calculator",
        "sleep duration calculator",
        "sleep schedule planner",
        "REM sleep calculator",
        "sleep quality calculator",
        "circadian rhythm calculator",
        "sleep recommendations",
        "sleep hygiene",
        "sleep pattern analysis"
    ],

    schema: {
        "@type": "SoftwareApplication",
        "name": "Sleep Calculator",
        "applicationCategory": "HealthApplication",
        "operatingSystem": "Any",
        "offers": {
            "@type": "Offer",
            "price": "0",
            "priceCurrency": "USD"
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.9",
            "ratingCount": "1850"
        },
        "featureList": [
            "Sleep Cycle Calculation",
            "Age-Based Recommendations",
            "Lifestyle Adjustments",
            "Sleep Quality Analysis",
            "Optimal Wake Time Suggestions",
            "Sleep Pattern Visualization",
            "Sleep Score Assessment",
            "Personalized Recommendations"
        ]
    },

    features: [
        "Calculate optimal bedtime and wake times",
        "Track sleep cycles and duration",
        "Age-specific sleep recommendations",
        "Sleep quality assessment",
        "Lifestyle-based adjustments",
        "Visual sleep cycle analysis",
        "Sleep score calculation",
        "Personalized sleep recommendations",
        "Export sleep analysis reports",
        "Interactive sleep pattern visualization"
    ],

    mathFormulas: [
        {
            name: "Sleep Cycle Duration",
            formula: "1 Sleep Cycle = 90 minutes",
            explanation: "Each complete sleep cycle consists of light sleep, deep sleep, and REM sleep phases"
        },
        {
            name: "Total Sleep Duration",
            formula: "Sleep Duration = Wake Time - Bedtime",
            explanation: "Calculates total sleep time accounting for overnight sleep periods"
        },
        {
            name: "Sleep Cycles Count",
            formula: "Number of Cycles = Sleep Duration ÷ 90 minutes",
            explanation: "Calculates the number of complete sleep cycles in a sleep period"
        },
        {
            name: "Sleep Score",
            formula: "Score = (Actual Sleep ÷ Recommended Sleep) × 100",
            explanation: "Evaluates sleep duration against age-based recommendations"
        }
    ],

    faqs: [
        {
            question: "How many hours of sleep do I need?",
            answer: "Sleep needs vary by age: newborns (14-17 hours), infants (12-15 hours), toddlers (11-14 hours), preschoolers (10-13 hours), school-age children (9-11 hours), teens (8-10 hours), adults (7-9 hours), and older adults (7-8 hours)."
        },
        {
            question: "What is a sleep cycle?",
            answer: "A sleep cycle is a 90-minute period consisting of three main stages: light sleep (initial relaxation), deep sleep (physical restoration), and REM sleep (mental restoration and dreams)."
        },
        {
            question: "How does the sleep calculator work?",
            answer: "The calculator uses your desired wake time or bedtime to determine optimal sleep and wake times based on 90-minute sleep cycles. It aims to wake you between cycles when you're in lighter sleep, making it easier to feel refreshed."
        },
        {
            question: "What affects sleep quality?",
            answer: "Sleep quality is affected by factors including consistent sleep schedule, caffeine intake, exercise timing, room environment, screen exposure, stress levels, and pre-bed routine. The calculator considers these factors in its recommendations."
        },
        {
            question: "How can I improve my sleep score?",
            answer: "Improve your sleep score by maintaining a consistent sleep schedule, avoiding caffeine before bed, exercising regularly (but not too close to bedtime), creating a relaxing bedtime routine, and ensuring your sleeping environment is dark, quiet, and cool."
        }
    ],

    references: [
        {
            title: "Sleep Cycles and Stages",
            author: "National Sleep Foundation",
            link: "https://www.sleepfoundation.org/how-sleep-works/stages-of-sleep",
            description: "Scientific explanation of sleep cycles and stages"
        },
        {
            title: "Sleep Duration Recommendations",
            author: "American Academy of Sleep Medicine",
            link: "https://aasm.org/clinical-resources/practice-standards/practice-guidelines",
            description: "Official sleep duration recommendations by age"
        },
        {
            title: "Sleep Hygiene Guidelines",
            author: "Centers for Disease Control and Prevention",
            link: "https://www.cdc.gov/sleep/about_sleep/sleep_hygiene.html",
            description: "Guidelines for healthy sleep habits"
        },
        {
            title: "Circadian Rhythms and Sleep",
            author: "National Institute of Health",
            link: "https://www.nigms.nih.gov/education/fact-sheets/Pages/circadian-rhythms.aspx",
            description: "Research on circadian rhythms and sleep patterns"
        }
    ],

    itemListElement: [
        {
            "@type": "ListItem",
            "position": 1,
            "name": "Health Tools",
            "item": {
                "@id": "/calculators/health"
            }
        },
        {
            "@type": "ListItem",
            "position": 2,
            "name": "Sleep Calculator",
            "item": {
                "@id": "/calculators/sleep"
            }
        }
    ]
};
