import { Clock } from 'lucide-react';
import { CalculatorInfo } from '../types/CalculatorInfo';

export const timeCalculator: CalculatorInfo = {
    title: "Time Calculator - Duration, Work Hours & Time Zone Converter",
    description: "Free online time calculator for computing time differences, work hours, and time zone conversions. Features include break time adjustment, speed-time calculations, and cross-timezone planning. Perfect for professionals, project managers, and remote teams.",
    category: "Productivity & Time Management",
    icon: Clock,
    
    keywords: [
        "time calculator",
        "duration calculator",
        "work hours calculator",
        "time zone converter",
        "break time calculator",
        "speed time calculator",
        "elapsed time calculator",
        "time difference calculator",
        "working hours calculator",
        "timezone conversion tool",
        "time interval calculator",
        "work break calculator"
    ],

    schema: {
        "@type": "SoftwareApplication",
        "name": "Time Calculator",
        "applicationCategory": "ProductivityApplication",
        "operatingSystem": "Any",
        "offers": {
            "@type": "Offer",
            "price": "0",
            "priceCurrency": "USD"
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.8",
            "ratingCount": "1420"
        },
        "featureList": [
            "Time Duration Calculation",
            "Work Hours Computation",
            "Time Zone Conversion",
            "Break Time Adjustment",
            "Speed-Time Calculations",
            "Multiple Time Formats",
            "Cross-timezone Planning",
            "Export Functionality"
        ]
    },

    features: [
        "Calculate time duration between two points",
        "Compute work hours with break time adjustments",
        "Convert times across different time zones",
        "Calculate travel time based on speed and distance",
        "Support for multiple time formats (12/24 hour)",
        "Automatic handling of time zone differences",
        "Break time consideration in work hours",
        "Export calculations in formatted text",
        "Real-time calculation updates",
        "International time zone support"
    ],

    mathFormulas: [
        {
            name: "Time Duration",
            formula: "Duration = End Time - Start Time",
            explanation: "Calculates the time difference between two time points"
        },
        {
            name: "Work Hours",
            formula: "Work Hours = (End Time - Start Time) - Break Duration",
            explanation: "Calculates effective work hours by subtracting break time from total duration"
        },
        {
            name: "Travel Time",
            formula: "Time = Distance / Speed",
            explanation: "Calculates time needed to travel a distance at a given speed"
        },
        {
            name: "Time Zone Conversion",
            formula: "Target Time = Source Time + (Target Offset - Source Offset)",
            explanation: "Converts time between different time zones using UTC offsets"
        }
    ],

    faqs: [
        {
            question: "How does the work hours calculator handle breaks?",
            answer: "The work hours calculator automatically subtracts break duration from the total time. Simply enter your start time, end time, and break duration in minutes, and the calculator will compute your effective working hours."
        },
        {
            question: "Can I calculate time differences across time zones?",
            answer: "Yes, the time zone converter allows you to select source and target time zones to calculate the time difference. It supports major time zones and automatically handles daylight saving time adjustments."
        },
        {
            question: "How does the speed-time calculator work?",
            answer: "Enter the distance and speed, and the calculator will determine the travel time. It's useful for trip planning and understanding travel durations at different speeds."
        },
        {
            question: "What time formats are supported?",
            answer: "The calculator supports both 12-hour and 24-hour time formats. Times can be entered in either format, and results will be displayed according to your preference."
        },
        {
            question: "How accurate are the time zone conversions?",
            answer: "Time zone conversions are based on standard UTC offsets and include consideration for daylight saving time where applicable. The calculator is regularly updated to reflect the latest time zone changes."
        }
    ],

    references: [
        {
            title: "International Time Standards",
            author: "International Bureau of Weights and Measures",
            link: "https://www.bipm.org/en/time",
            description: "Official standards for time measurement and coordination"
        },
        {
            title: "Time Zone Database",
            author: "IANA",
            link: "https://www.iana.org/time-zones",
            description: "Authoritative source for time zone data"
        },
        {
            title: "Working Time Regulations",
            author: "International Labour Organization",
            link: "https://www.ilo.org/global/standards",
            description: "International standards for working hours and breaks"
        },
        {
            title: "Speed-Time-Distance Relationships",
            author: "National Institute of Standards and Technology",
            link: "https://www.nist.gov/time-and-frequency",
            description: "Standards for time and frequency measurements"
        }
    ],

    itemListElement: [
        {
            "@type": "ListItem",
            "position": 1,
            "name": "Time Management Tools",
            "item": {
                "@id": "/calculators/time"
            }
        },
        {
            "@type": "ListItem",
            "position": 2,
            "name": "Time Calculator",
            "item": {
                "@id": "/calculators/time#calculator"
            }
        }
    ]
};
