import { TrendingUp } from 'lucide-react';
import { CalculatorInfo } from './types';

export const investmentCalculator: CalculatorInfo = {
  id: 'investment-calculator',
  title: 'Investment Calculator | Free Investment Return Calculator',
  description: 'Free investment calculator with comprehensive portfolio analysis. Calculate returns across multiple investment types including stocks, bonds, real estate, and crypto. Features inflation adjustment, risk analysis, and detailed investment projections.',
  icon: TrendingUp,
  category: 'Financial',
  schema: {
    "@type": "SoftwareApplication",
    "name": "Investment Calculator",
    "applicationCategory": "CalculatorApplication",
    "applicationSubCategory": "Finance",
    "operatingSystem": "Any",
    "description": "Professional investment calculator with multi-asset portfolio analysis, ROI projections, and risk assessment tools. Essential for investment planning and wealth management across various asset classes.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "url": "https://calculato.co/investment-calculator"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "ratingCount": "2890",
      "reviewCount": "1950",
      "bestRating": "5",
      "worstRating": "1"
    },
    "featureList": [
      "Multi-Asset Portfolio Analysis",
      "Historical Return Data",
      "Risk Assessment Tools",
      "Inflation Adjustment",
      "Compound Interest Calculator",
      "Investment Timeline Charts",
      "PDF Investment Reports",
      "Portfolio Diversification",
      "Mobile Friendly Design",
      "Free Forever"
    ]
  },
  formulas: [
    {
      expression: 'FV = P(1 + r)^t + PMT × (((1 + r)^t - 1) / r)',
      explanation: 'Calculate future investment value with periodic contributions',
      variables: [
        { name: 'P', description: 'Initial investment amount' },
        { name: 'r', description: 'Annual return rate (as decimal)' },
        { name: 't', description: 'Time period in years' },
        { name: 'PMT', description: 'Regular contribution amount' }
      ]
    },
    {
      expression: 'Real Return = ((1 + Nominal Return) / (1 + Inflation)) - 1',
      explanation: 'Calculate inflation-adjusted return rate',
      variables: [
        { name: 'Nominal Return', description: 'Investment return before inflation' },
        { name: 'Inflation', description: 'Annual inflation rate' }
      ]
    },
    {
      expression: 'ROI = ((Final Value - Initial Investment) / Initial Investment) × 100',
      explanation: 'Calculate percentage return on investment',
      variables: [
        { name: 'Final Value', description: 'Investment value at end of period' },
        { name: 'Initial Investment', description: 'Starting investment amount' }
      ]
    },
    {
      expression: 'Risk-Adjusted Return = Return / Standard Deviation',
      explanation: 'Calculate risk-adjusted return (Sharpe ratio without risk-free rate)',
      variables: [
        { name: 'Return', description: 'Average annual return' },
        { name: 'Standard Deviation', description: 'Volatility of returns' }
      ]
    },
    {
      expression: 'Portfolio Return = Σ(Weight_i × Return_i)',
      explanation: 'Calculate weighted average return for a portfolio',
      variables: [
        { name: 'Weight_i', description: 'Percentage allocated to investment i' },
        { name: 'Return_i', description: 'Return of investment i' }
      ]
    }
  ],
  faqs: [
    {
      question: 'How do I calculate my potential investment returns?',
      answer: 'Enter your initial investment, regular contributions, investment timeframe, and expected return rates for different asset types (stocks, bonds, real estate, crypto). The calculator will show projected returns, including compound interest and inflation-adjusted values.'
    },
    {
      question: 'What investment types are supported?',
      answer: 'The calculator supports multiple investment types including stocks (S&P 500), bonds, real estate, and cryptocurrency. Each type comes with historical return data and risk assessments to help with portfolio planning.'
    },
    {
      question: 'How is risk calculated?',
      answer: 'Risk is assessed based on historical volatility and standard deviation of returns for each investment type. Investments are categorized as Low, Medium, or High risk to help with portfolio diversification.'
    },
    {
      question: 'Does it account for inflation?',
      answer: 'Yes, the calculator includes inflation adjustment options to show both nominal and real (inflation-adjusted) future values of your investments.'
    },
    {
      question: 'Can I download detailed investment reports?',
      answer: 'Yes, you can generate and download comprehensive PDF reports showing projected returns, portfolio allocation, risk analysis, and year-by-year breakdowns of your investments.'
    }
  ],
  references: [
    'S&P 500 Historical Returns',
    'Bond Market Analysis',
    'Real Estate Investment Metrics',
    'Cryptocurrency Market Data',
    'Modern Portfolio Theory'
  ],
  keywords: [
    'investment calculator',
    'portfolio calculator',
    'ROI calculator',
    'stock return calculator',
    'compound interest calculator',
    'investment return calculator',
    'wealth calculator',
    'portfolio return calculator',
    'investment growth calculator',
    'free investment calculator'
  ],
  features: [
    'Multi-asset portfolio analysis',
    'Historical return data',
    'Risk assessment tools',
    'Inflation adjustment',
    'Compound interest calculator',
    'Investment timeline charts',
    'PDF investment reports',
    'Portfolio diversification',
    'Mobile-responsive design',
    'High-precision calculations'
  ],
  itemListElement: [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Financial Calculators",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/financial-calculators"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Compound Interest Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://www.calculato.co/en/compound-interest-calculator"
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Investment Calculator",
      "item": {
        "@type": "Thing",
        "id": "https://calculato.co/investment-calculator"
      }
    }
  ]
};
